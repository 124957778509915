import React, { useState, useEffect, useCallback } from 'react';
import Button from 'erpcore/components/Button';
import './TimeTrackingWidgetDropdown.scss';
import { getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';
import { useSelector } from 'react-redux';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import TimeTracking from 'erpcore/components/TimeTracking';

const TimeTrackingWidgetDropdown = () => {
    const [dropdownRef, setDropdownRef] = useState(null);
    const [open, setOpen] = useState(false);
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);

    const handleClickOutside = useCallback(
        event => {
            if (
                dropdownRef &&
                !dropdownRef.contains(event.target) &&
                !event.path.find(DOMElement =>
                    DOMElement?.classList?.contains('time-tracking-widget-dropdown')
                ) &&
                !event?.target?.classList.contains('react-tooltip-lite') &&
                !event?.target?.classList.contains('react-select__indicator') &&
                !(event?.target instanceof SVGElement)
            ) {
                setOpen(false);
            }
        },
        [dropdownRef]
    );

    useEffect(() => {
        if (dropdownRef && open) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside, open]);

    if (!hasPermissionsAccessForEntities.timeLogs) {
        return null;
    }

    return (
        <div
            ref={ref => ref && setDropdownRef(ref)}
            className={`time-tracking-widget-dropdown ${
                open ? ' time-tracking-widget-dropdown--active' : ''
            }`}
        >
            <Button
                iconName="stopwatch"
                variation="tertiary"
                labelOnlyAria
                onClick={() => setOpen(!open)}
            />
            <div className="time-tracking-widget-dropdown__content">
                <TimeTracking />
            </div>
        </div>
    );
};

TimeTrackingWidgetDropdown.defaultProps = {};

TimeTrackingWidgetDropdown.propTypes = {};

export default TimeTrackingWidgetDropdown;
