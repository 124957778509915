import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - DeleteEntity Notifications
 * @return {Node} Returns Notification component
 */
const DeleteEntityNotifications = ({ identifier, code, detail }) => {
    switch (code) {
        case 'entities.queuedForDeletion':
            return (
                <Notification
                    identifier={identifier}
                    title="In progress"
                    text={`Deleting ${detail?.entityName} is in progress, please refresh the page in 30 seconds!`}
                    type="info"
                    expire="3500"
                />
            );
        case 'contract.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text={`${detail?.entityName} successfully deleted!`}
                    type="success"
                    expire="3500"
                />
            );
        case 'entities.queuedForArchiving':
            return (
                <Notification
                    identifier={identifier}
                    title="In progress"
                    text={`${detail?.entityName} archiving is in progress, please refresh the page in 30 seconds!`}
                    type="info"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

DeleteEntityNotifications.defaultProps = {
    identifier: null,
    code: null,
    detail: null
};

DeleteEntityNotifications.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default DeleteEntityNotifications;
