import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { NavLink, Link } from 'react-router-dom';
import Button from 'erpcore/components/Button';
import Image from 'erpcore/components/Image';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';

import LayoutManager from 'erpcore/utils/LayoutManager';
import { getMeData, getIsImpersonateActive } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getSidebarShowDeck } from 'erpcore/utils/LayoutManager/LayoutManager.selectors';
import { actions as layoutManagerActions } from 'erpcore/utils/LayoutManager/LayoutManager.reducer';
import { getBrandingSettings } from 'erpcore/utils/BrandingManager/BrandingManager.selectors';
import ImpersonatedUserCard from 'erpcore/components/ImpersonatedUserCard';
import './Sidebar.scss';

// eslint-disable-next-line react/prop-types
const ProfilePhoto = ({ imageIri = null, isImpersonateActive = false }) => {
    const icon = isImpersonateActive ? 'userMirrorIcon' : 'profile';
    return (
        <Image
            iri={isImpersonateActive ? null : imageIri}
            version="small"
            width={32}
            height={32}
            alt="Profile"
            fallback={<Svg icon={icon} />}
        />
    );
};

const Sidebar = ({ className, layout }) => {
    const branding = useSelector(getBrandingSettings);
    const dispatch = useDispatch();
    const user = useSelector(getMeData);
    const isImpersonateActive = useSelector(getIsImpersonateActive);
    const showDeck = useSelector(getSidebarShowDeck);
    const [showProfileMenu, setShowProfileMenu] = useState(false);

    /**
     * Used in multiple functions to update Redux state value
     * @param {boolean} value
     */
    const updateSidebarDeckState = value => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: layoutManagerActions.UPDATE_SIDEBAR_DECK_STATE,
                response: value
            })
        ).catch(error => {
            return console.error(error);
        });
    };

    /**
     * Effect used only on component mount
     */
    useEffect(() => {
        // Set Deck to hidden/colsed on mobile
        const isMobile = window && window.innerWidth < 1030;
        if (isMobile) {
            updateSidebarDeckState(false);
        }
    }, []);

    /**
     * Used for the click on the overlay
     */
    const hideAll = () => {
        setShowProfileMenu(false);
        return updateSidebarDeckState(false);
    };

    /**
     * Used by the hamburger button
     */
    const handleDeck = () => {
        setShowProfileMenu(false);
        return updateSidebarDeckState(!showDeck);
    };

    /**
     * Used only in mobile view for Profile Menu
     */
    const handleMobileProfileMenu = () => {
        setShowProfileMenu(!showProfileMenu);
        return updateSidebarDeckState(false);
    };

    return (
        <aside
            className={`sidebar ${showDeck ? 'sidebar--show-deck' : ''} ${
                showProfileMenu ? 'sidebar--profile-opened' : ''
            } ${className || ''}`}
        >
            <button
                type="button"
                className="sidebar__mobile-overlay"
                onClick={() => hideAll()}
                onKeyPress={() => hideAll()}
            />
            <div className="sidebar__content">
                <div className="sidebar__bolt">
                    <ul className="sidebar__bolt-top">
                        <li className="sidebar__bolt-top-hamburger">
                            <button
                                type="button"
                                className="sidebar__button sidebar__button--hamburger"
                                onClick={() => handleDeck()}
                            >
                                <Svg icon="menu" />
                            </button>
                        </li>
                        <li className="sidebar__bolt-top-logo">
                            <Link to="/" className="sidebar__logo">
                                {branding?.settings?.sidebar_logo ? (
                                    <Image
                                        iri={branding?.settings?.sidebar_logo}
                                        version="medium"
                                    />
                                ) : (
                                    <Svg icon="myZoneLogo" />
                                )}
                            </Link>
                        </li>
                        <li className="sidebar__bolt-top-profile">
                            <button
                                type="button"
                                className={`sidebar__profile ${
                                    isImpersonateActive ? 'sidebar__profile--transparent' : ''
                                }`}
                                onClick={() => handleMobileProfileMenu()}
                            >
                                <ProfilePhoto
                                    imageIri={user?.image?.iri}
                                    isImpersonateActive={isImpersonateActive}
                                />
                            </button>
                        </li>
                    </ul>
                    <ul className="sidebar__bolt-bottom">
                        <li className="sidebar__bolt-bottom-item">
                            <Tooltip content="Sign out" direction="right">
                                <Button
                                    label=""
                                    iconName="signOut"
                                    href="/sign-out"
                                    className="sidebar__button"
                                />
                            </Tooltip>
                        </li>
                        <li className="sidebar__bolt-bottom-item">
                            <Tooltip content="Submit feedback" direction="right">
                                <Button
                                    label=""
                                    iconName="bulb"
                                    href="/feedback"
                                    className="sidebar__button"
                                />
                            </Tooltip>
                        </li>
                        <li className="sidebar__bolt-bottom-item">
                            <Tooltip
                                lightTheme={!!isImpersonateActive}
                                tipContentHover={!!isImpersonateActive}
                                content={
                                    <>
                                        {!!isImpersonateActive && (
                                            <ImpersonatedUserCard isTooltip />
                                        )}
                                        {!isImpersonateActive && (
                                            <>
                                                {user?.first_name || user?.last_name
                                                    ? `${user.first_name || ''} ${user.last_name ||
                                                          ''}`
                                                    : ''}
                                            </>
                                        )}
                                    </>
                                }
                                direction="right"
                            >
                                <NavLink
                                    to="/my-account"
                                    className={`sidebar__profile ${
                                        isImpersonateActive ? 'sidebar__profile--transparent' : ''
                                    }`}
                                >
                                    <ProfilePhoto
                                        imageIri={user?.image?.iri}
                                        isImpersonateActive={isImpersonateActive}
                                    />
                                </NavLink>
                            </Tooltip>
                        </li>
                    </ul>
                </div>
                <div className="sidebar__deck">
                    {!!isImpersonateActive && <ImpersonatedUserCard />}
                    <LayoutManager layout={layout} />
                </div>
                <div className="sidebar__mobile-profile">
                    {!!isImpersonateActive && <ImpersonatedUserCard />}
                    <ul className="sidebar__profile-list">
                        <li className="sidebar__profile-list-item">
                            <NavLink to="/my-account" className="sidebar__profile-list-item-link">
                                My account
                            </NavLink>
                        </li>
                        <li className="sidebar__profile-list-item">
                            <NavLink to="/feedback" className="sidebar__profile-list-item-link">
                                Submit feedback
                            </NavLink>
                        </li>
                        <li className="sidebar__profile-list-item">
                            <NavLink to="/sign-out" className="sidebar__profile-list-item-link">
                                Sign out
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </aside>
    );
};

Sidebar.defaultProps = {
    layout: null,
    className: ''
};

Sidebar.propTypes = {
    layout: PropTypes.oneOfType([PropTypes.object]),
    className: PropTypes.string
};

export default Sidebar;
