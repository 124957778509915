import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { createPortal } from 'react-dom';
import Input from '../Input';
import './DateTime.scss';

const PopperContainer = ({ children }) => createPortal(children, document.body);

//  Passing custom input gives plugin console error. This is a 3rd party issue
const CustomInput = ({ fieldAttr, onClick, value }) => {
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <input
            type="button"
            className="input__field"
            autoComplete="off"
            onClick={onClick}
            value={value}
            readOnly
            aria-label="open date picker"
            {...fieldAttr}
        />
    );
};
CustomInput.defaultProps = {
    fieldAttr: {},
    onClick: () => {},
    value: {}
};
CustomInput.propTypes = {
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    onClick: PropTypes.func,
    value: PropTypes.string
};

/**
 *
 * @param input
 * @param meta
 * @param fieldProps
 * @param fieldAttr
 * @param field
 * @param outputNoTimeZone {boolean} if true; value is saved as formatted string,
 *                                   otherwise value is saved as Date string with timezone
 * @param alwaysOpen {boolean}
 * @return {*}
 * @constructor
 */
const DateTime = ({ input, meta, fieldProps, fieldAttr, field, outputNoTimeZone, alwaysOpen }) => {
    //  Preparing specific date format
    let dateFormat = 'M/d/yyyy'; // react-datepicker formatting
    let dateFormatMoment = outputNoTimeZone ? 'M/D/YYYY' : ''; // moment formatting
    if (fieldProps?.showTimeSelect) {
        dateFormat = 'M/d/yyyy   h:mm aa'; // react-datepicker formatting
        dateFormatMoment = outputNoTimeZone ? 'M/D/YYYY   h:mm a' : ''; // moment formatting

        if (fieldProps?.showTimeSelectOnly) {
            dateFormat = 'h:mm aa'; // react-datepicker formatting
            dateFormatMoment = outputNoTimeZone ? 'h:mm a' : ''; // moment formatting
        }
    }

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
            className="input--datepicker"
        >
            <DatePicker
                {...(alwaysOpen ? { open: true } : null)}
                className="input__field"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                popperModifiers={{
                    preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: 'viewport'
                    }
                }}
                {...fieldAttr}
                {...fieldProps}
                onBlur={() => input.onBlur()}
                dateFormat={dateFormat}
                selected={
                    input.value &&
                    Date.parse(input.value) &&
                    Number.isNaN(Date.parse(input.value)) === false
                        ? new Date(input.value)
                        : ''
                }
                disabledKeyboardNavigation
                // if outputNoTimeZone === true -> value is saved as formatted string, otherwise as Date string with timezone
                onChange={(value) => input.onChange(moment(value).format(dateFormatMoment))}
                customInput={<CustomInput fieldAttr={fieldAttr} />}
                popperContainer={PopperContainer}
            />
        </Input>
    );
};

DateTime.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {},
    outputNoTimeZone: true,
    alwaysOpen: false
};

DateTime.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    outputNoTimeZone: PropTypes.bool,
    alwaysOpen: PropTypes.bool
};

export default DateTime;
