import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { actions as approvalsActions } from './Approvals.reducer';

const approvalsListingDefaultInclude = 'include=project,contract,createdBy';

/**
 * Delete Single Approval
 * @param  {Object} id ID of approval
 * @return {Object} Response from API
 */
export function* deleteSingleApproval({ promise, iri }) {
    try {
        const deleteSingleApprovalAPI = yield restClient.delete(iri);
        yield put({
            type: approvalsActions.DELETE_APPROVAL_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleApprovalAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'approvals' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'APPROVALS',
            name: 'approvals',
            endpoint: `api/approvals?${approvalsListingDefaultInclude}`
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: approvalsActions.DELETE_APPROVAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Approval
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createApproval({ promise, formData }) {
    try {
        const createApprovalAPI = yield restClient.post(`/api/approvals`, formData);
        yield put({
            type: approvalsActions.CREATE_APPROVAL_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createApprovalAPI?.data
        });
        yield call(promise.resolve, createApprovalAPI?.data);
    } catch (error) {
        yield put({
            type: approvalsActions.CREATE_APPROVAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch approval
 * @param  {Object} promise
 * @param {string} approval Iri
 * @return {Object} response from API
 */
export function* fetchSingleApproval({ promise, iri, params }) {
    try {
        const fetchSingleApprovalAPI = yield restClient.get(iri, { params });
        yield put({
            type: approvalsActions.FETCH_APPROVAL_SUCCESSFUL
        });
        yield put({
            type: approvalsActions.STORE_APPROVAL_DATA,
            iri,
            response: dto(fetchSingleApprovalAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: approvalsActions.FETCH_APPROVAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Single approval data
 * @param  {Object} id ID of Approval
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* updateSingleApproval({ promise, formData, iri }) {
    try {
        const updateSingleApprovalAPI = yield restClient.put(iri, formData);
        yield put({
            type: approvalsActions.UPDATE_APPROVAL_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleApprovalAPI?.data
        });
        yield put({
            type: approvalsActions.STORE_APPROVAL_DATA,
            iri,
            response: dto(updateSingleApprovalAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: approvalsActions.UPDATE_APPROVAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const approvalsSaga = [
    takeLatest(approvalsActions.START_DELETE_APPROVAL, deleteSingleApproval),
    takeLatest(approvalsActions.START_CREATE_APPROVAL, createApproval),
    takeLatest(approvalsActions.START_FETCHING_APPROVAL, fetchSingleApproval),
    takeLatest(approvalsActions.START_UPDATE_APPROVAL, updateSingleApproval)
];
