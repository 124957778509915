import React, { memo, useCallback, useState, useEffect, useLayoutEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, FormSpy } from 'react-final-form';
import Form, {
    Text,
    Autocomplete,
    Textarea,
    Timelog,
    DateTime,
    Select
} from 'erpcore/components/Form';
import styles from 'erpcore/components/TimeTracking/TimeTracking.module.scss';
import Dropdown from 'erpcore/components/TimeTracking/components/Dropdown';
import Button from 'erpcore/components/Button';
import { useDropdown } from 'erpcore/components/TimeTracking/TimeTrackingHooks';
import moment from 'moment-timezone';
import Svg from 'erpcore/components/Svg';
import { formatHours, insertIf } from 'erpcore/utils/utils';
import ElementLoader from 'erpcore/components/ElementLoader';
import Timer from 'react-compound-timer';
import ButtonDropdown from 'erpcore/components/ButtonDropdown';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import nextId from 'react-id-generator';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

const Title = ({ isLocked, projectName, contractName }) => {
    const topRowTitleComponentClasses = classnames({
        [styles['time-tracking__draft-list__single-info__top-contract']]: true,
        [styles['time-tracking__draft-list__single-info__top-contract--disabled']]: isLocked
    });
    return (
        <h4 className={topRowTitleComponentClasses}>
            {isLocked && (
                <Tooltip content="Time entry locked" direction="right">
                    <Svg icon="lock" iconColor="silver-grey" />
                </Tooltip>
            )}
            {projectName} - {contractName}
        </h4>
    );
};

Title.propTypes = {
    isLocked: PropTypes.bool.isRequired,
    projectName: PropTypes.string.isRequired,
    contractName: PropTypes.string.isRequired
};

const WorkTypeSelection = ({
    toggleDropdown,
    dropdownOpenStatus,
    workTypeName,
    isLocked,
    iri,
    workTypes
}) => {
    const tooltipContent = `Work type: ${workTypeName}`;
    const dropdownId = `${iri}__work-type`;
    const isDropdownOpened = !!dropdownOpenStatus?.[dropdownId];
    const areThereWorkTypesAvailable = workTypes?.length;

    const dropdownOptions = useMemo(() => {
        const workTypesEndpointData = {
            endpoint: '/api/work-types?filters[visible][equals]=true',
            mapData: {
                label: 'name',
                value: 'iri'
            }
        };

        const getAvailableWorkTypes = (availableWorkTypes) => {
            return availableWorkTypes?.map((workType) => ({
                label: workType?.data?.attributes?.name,
                value: workType?.data?.id
            }));
        };

        return areThereWorkTypesAvailable
            ? getAvailableWorkTypes(workTypes)
            : workTypesEndpointData;
    }, [workTypes]);

    return (
        <div className={styles['time-tracking__draft-list__single-info__top-work-type']}>
            <Tooltip direction="up" content={tooltipContent}>
                <Button
                    disabled={isLocked}
                    className={styles['time-tracking__create-button']}
                    label={`${workTypeName || 'Work type'}`}
                    variation="tertiary"
                    onClick={() => {
                        toggleDropdown(dropdownId);
                    }}
                />
            </Tooltip>

            <Dropdown id={dropdownId} opened={isDropdownOpened} renderOnOpen>
                <Field
                    name="work_type"
                    fieldProps={{
                        menuIsOpen: isDropdownOpened,
                        options: dropdownOptions,
                        clearable: false
                    }}
                    fieldAttr={{
                        id: `${iri}-work_type`,
                        clearable: false,
                        disabled: isLocked
                    }}
                    component={areThereWorkTypesAvailable ? Select : Autocomplete}
                />
            </Dropdown>
        </div>
    );
};

WorkTypeSelection.defaultProps = {
    iri: '',
    workTypes: []
};
WorkTypeSelection.propTypes = {
    dropdownOpenStatus: PropTypes.object.isRequired,
    toggleDropdown: PropTypes.func.isRequired,
    workTypeName: PropTypes.string.isRequired,
    isLocked: PropTypes.bool.isRequired,
    iri: PropTypes.string,
    workTypes: PropTypes.array
};

const StagesSelection = ({
    stageName,
    iri,
    dropdownOpenStatus,
    projectContractStages,
    toggleDropdown
}) => {
    const tooltipContent = stageName ? `Project stage: ${stageName}` : 'No stage selected';
    const dropdownId = `${iri}__stage`;
    const isDropdownOpened = !!dropdownOpenStatus?.[dropdownId];

    const dropdownOptions = useMemo(() => {
        return projectContractStages
            ?.filter((stage) => !stage.is_completed)
            .map((stage) => ({
                label: stage.name,
                value: stage.iri
            }));
    }, [projectContractStages]);

    return (
        <div className={styles['time-tracking__draft-list__single-info__top-stage']}>
            <Tooltip direction="up" content={tooltipContent}>
                <Button
                    className={styles['time-tracking__create-button']}
                    label={`${stageName || 'No stage selected'}`}
                    variation="tertiary"
                    onClick={() => {
                        toggleDropdown(dropdownId);
                    }}
                />
            </Tooltip>
            <Dropdown id={dropdownId} opened={isDropdownOpened} renderOnOpen>
                <Field
                    name="stage"
                    fieldProps={{
                        menuIsOpen: isDropdownOpened,
                        options: dropdownOptions,
                        clearable: false
                    }}
                    fieldAttr={{
                        id: `${iri}-stage`,
                        clearable: false
                    }}
                    component={Select}
                />
            </Dropdown>
        </div>
    );
};

StagesSelection.defaultProps = {
    stageName: '',
    iri: '',
    projectContractStages: []
};

StagesSelection.propTypes = {
    stageName: PropTypes.string,
    iri: PropTypes.string,
    projectContractStages: PropTypes.array,
    dropdownOpenStatus: PropTypes.object.isRequired,
    toggleDropdown: PropTypes.func.isRequired
};

const TaskUrlInput = ({ isLocked, taskUrl, iri, toggleDropdown, dropdownOpenStatus }) => {
    const buttonLabel = taskUrl || 'Task URL';

    const dropdownId = `${iri}__task_url`;

    const isDropdownOpened = dropdownOpenStatus?.[dropdownId];

    return (
        <div className={styles['time-tracking__draft-list__single-info__bottom-task']}>
            <Button
                disabled={isLocked}
                className={styles['time-tracking__create-button']}
                label={buttonLabel}
                iconName="link"
                variation="tertiary"
                onClick={() => {
                    toggleDropdown(dropdownId);
                }}
            />

            <Dropdown id={dropdownId} opened={isDropdownOpened}>
                <Field
                    name="task_url"
                    fieldProps={{
                        label: 'Task URL'
                    }}
                    fieldAttr={{
                        id: `${iri}-task_url`,
                        clearable: true,
                        disabled: isLocked
                    }}
                    component={Text}
                />
            </Dropdown>
        </div>
    );
};

TaskUrlInput.defaultProps = {
    taskUrl: 'Task URL',
    iri: ''
};

TaskUrlInput.propTypes = {
    isLocked: PropTypes.bool.isRequired,
    taskUrl: PropTypes.string,
    iri: PropTypes.string,
    dropdownOpenStatus: PropTypes.object.isRequired,
    toggleDropdown: PropTypes.func.isRequired
};

const Description = ({ dropdownOpenStatus, toggleDropdown, isLocked, comment, iri }) => {
    const buttonLabel = comment || 'Description';

    const dropdownId = `${iri}__comment`;

    const isDropdownOpened = dropdownOpenStatus?.[dropdownId];

    return (
        <div className={styles['time-tracking__draft-list__single-info__bottom-description']}>
            <Button
                disabled={isLocked}
                className={styles['time-tracking__create-button']}
                label={buttonLabel}
                iconName="editSquare"
                variation="tertiary"
                onClick={() => {
                    toggleDropdown(dropdownId);
                }}
            />

            <Dropdown id={dropdownId} opened={isDropdownOpened}>
                <Field
                    name="comment"
                    fieldProps={{
                        label: 'Description'
                    }}
                    fieldAttr={{
                        id: `${iri}-comment`,
                        clearable: true
                    }}
                    component={Textarea}
                />
            </Dropdown>
        </div>
    );
};

Description.defaultProps = {
    iri: '',
    comment: ''
};

Description.propTypes = {
    isLocked: PropTypes.bool.isRequired,
    iri: PropTypes.string,
    comment: PropTypes.string,
    dropdownOpenStatus: PropTypes.object.isRequired,
    toggleDropdown: PropTypes.func.isRequired
};

const AvailableTimeNotification = ({ timeRemainingClass, timeAvailable }) => {
    const outOfScopeRemainingTimeClasses = classnames({
        [styles['time-tracking__draft-list__single-info__bottom-remaining']]: true,
        [timeRemainingClass]: true
    });

    return (
        <div className={outOfScopeRemainingTimeClasses}>
            <Svg icon="timeRemaining" iconColor="mid-grey" />
            {formatHours(Math.abs(timeAvailable))}{' '}
            {timeAvailable < 0 ? 'out of scope' : 'remaining'}
        </div>
    );
};

AvailableTimeNotification.defaultProps = {
    timeAvailable: 0
};

AvailableTimeNotification.propTypes = {
    timeAvailable: PropTypes.number,
    timeRemainingClass: PropTypes.string.isRequired
};

const BillableIcon = ({
    handleBillableHours,
    timelogTimeSpentBillable,
    formValueTimeSpent,
    isPaused
}) => {
    const tooltipContent = timelogTimeSpentBillable
        ? `Billable ${formatHours(timelogTimeSpentBillable)}`
        : 'Not billable';

    const buttonClassnames = classnames({
        [styles['time-tracking__draft-list__single__actions-billable-button']]: true,
        [styles['time-tracking__draft-list__single__actions-billable-button--disabled']]:
            !timelogTimeSpentBillable
    });

    return (
        <div className={`${styles['time-tracking__draft-list__single__actions-billable']}`}>
            <Tooltip content={tooltipContent}>
                {isPaused ? (
                    <Button
                        onClick={() => handleBillableHours(formValueTimeSpent)}
                        className={buttonClassnames}
                        iconName="dollar"
                        variation="tertiary"
                        labelOnlyAria
                    />
                ) : (
                    <Svg
                        icon="dollar"
                        iconColor={timelogTimeSpentBillable ? 'sky' : 'silver-grey'}
                    />
                )}
            </Tooltip>
        </div>
    );
};

BillableIcon.defaultProps = {
    timelogTimeSpentBillable: 0,
    formValueTimeSpent: 0
};

BillableIcon.propTypes = {
    timelogTimeSpentBillable: PropTypes.number,
    formValueTimeSpent: PropTypes.number,
    isPaused: PropTypes.bool.isRequired,
    handleBillableHours: PropTypes.func.isRequired
};

const DateSelection = ({ toggleDropdown, dropdownOpenStatus, isLocked, iri, date }) => {
    const selectedDate = moment(date);

    const isSelectedDateToday = selectedDate.isSame(new Date(), 'day');

    const buttonClasses = classnames({
        [styles['time-tracking__draft-list__single__actions-date-button']]: true,
        [styles['time-tracking__create-button']]: true,
        [styles['time-tracking__draft-list__single__actions-date-button--today']]:
            isSelectedDateToday
    });

    const buttonLabel = isSelectedDateToday ? '' : selectedDate.format('MMM DD');

    const dropdownId = `${iri}__date`;

    const isDropdownOpened = dropdownOpenStatus?.[dropdownId];

    return (
        <div className={`${styles['time-tracking__draft-list__single__actions-date']}`}>
            <Button
                iconName="date"
                variation="tertiary"
                disabled={isLocked}
                label={buttonLabel}
                onClick={() => toggleDropdown(dropdownId)}
                className={buttonClasses}
            />

            <Dropdown id={dropdownId} opened={isDropdownOpened}>
                <Field
                    name="date"
                    fieldProps={{
                        label: '',
                        inline: true
                    }}
                    fieldAttr={{
                        id: `${iri}-date`,
                        clearable: true
                    }}
                    component={DateTime}
                />
            </Dropdown>
        </div>
    );
};

DateSelection.defaultProps = {
    iri: ''
};
DateSelection.propTypes = {
    isLocked: PropTypes.bool.isRequired,
    iri: PropTypes.string,
    date: PropTypes.string.isRequired,
    toggleDropdown: PropTypes.func.isRequired,
    dropdownOpenStatus: PropTypes.object.isRequired
};

const TimerIcon = ({ isTimerRunning, onClick, isTimelogBeingEdited }) => {
    const buttonClass = isTimerRunning
        ? styles['time-tracking__draft-list__single__actions-time-pause']
        : styles['time-tracking__draft-list__single__actions-time-play'];

    const buttonIcon = isTimerRunning ? 'pause' : 'play';

    return (
        <Button
            className={buttonClass}
            iconName={buttonIcon}
            variation="tertiary"
            label=""
            onClick={onClick}
            disabled={isTimelogBeingEdited}
        />
    );
};

TimerIcon.propTypes = {
    isTimerRunning: PropTypes.bool.isRequired,
    isTimelogBeingEdited: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};

const TimerComponent = ({
    isLocked,
    /*
    "status" can be "draft", "paused" and "completed".
    draft - means that the time log is a draft timelog AND that the timer is running
    paused - means that the time log is a draft timelog AND that the timer is paused
    completed - means that the time log is NOT a draft (therefore it should not even be possible to start/pause timer)
    */
    status,
    formValueTimeSpent,
    formValueStartedAtDate,
    notEnoughAvailableHours,
    isTimelogFieldEditable,
    setTimelogEditableField,
    handleBlur,
    timeInputId,
    renderTimer,
    timePassedFromStartInMiliseconds,
    handleStart,
    isDisabledStartTimerButton,
    handlePause,
    formChange
}) => {
    const timerButtonClasses = classnames({
        [styles['time-tracking__draft-list__single__actions-time-button']]: true,
        [styles['time-tracking__draft-list__single__actions-time-button--error']]:
            notEnoughAvailableHours
    });

    const buttonLabel = formatHours(formValueTimeSpent || 0, 'hh:mm');

    const initialTimerSeconds = useMemo(() => {
        const startedAtDate =
            formValueStartedAtDate || moment().utc().format('YYYY-MM-DDTHH:mm:ss+00:00');

        const timeSpentInMiliseconds = (formValueTimeSpent || 0) * 1000;

        return timePassedFromStartInMiliseconds(startedAtDate) + timeSpentInMiliseconds;
    }, [formValueStartedAtDate, formValueTimeSpent]);

    const isTimerRunning = status === 'draft';

    return (
        <div className={`${styles['time-tracking__draft-list__single__actions-timer']}`}>
            {!isTimelogFieldEditable && !isTimerRunning && (
                <Button
                    disabled={isLocked}
                    className={timerButtonClasses}
                    variation="tertiary"
                    label={buttonLabel}
                    onClick={() => setTimelogEditableField((prevState) => !prevState)}
                />
            )}

            <TimeInput
                // The important thing here is that the input field will not be clickable if the timer is running
                isVisible={isTimelogFieldEditable && !isTimerRunning}
                formChange={formChange}
                handleBlur={handleBlur}
                id={timeInputId}
            />

            {status !== 'completed' && (
                <>
                    {isTimerRunning && <>{renderTimer(initialTimerSeconds, true)}</>}

                    <TimerIcon
                        isTimerRunning={isTimerRunning}
                        onClick={
                            isTimerRunning
                                ? () => handlePause(formValueTimeSpent, formValueStartedAtDate)
                                : handleStart
                        }
                        isDisabled={isDisabledStartTimerButton}
                    />
                </>
            )}
        </div>
    );
};

TimerComponent.defaultProps = {
    formValueTimeSpent: 0
};

TimerComponent.propTypes = {
    isLocked: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    formValueTimeSpent: PropTypes.number,
    formValueStartedAtDate: PropTypes.string.isRequired,
    notEnoughAvailableHours: PropTypes.bool.isRequired,
    isTimelogFieldEditable: PropTypes.bool.isRequired,
    setTimelogEditableField: PropTypes.func.isRequired,
    formChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    timeInputId: PropTypes.string.isRequired,
    renderTimer: PropTypes.func.isRequired,
    timePassedFromStartInMiliseconds: PropTypes.func.isRequired,
    handleStart: PropTypes.func.isRequired,
    isDisabledStartTimerButton: PropTypes.bool.isRequired,
    handlePause: PropTypes.func.isRequired
};

const SideButtons = ({
    isAlreadySubmitted,
    noTimeSpent,
    errorsExist,
    isLocked,
    doesTimelogStageExist,
    notEnoughAvailableHours,
    allowedTimeTrackingOverflow,
    status,
    formValueTimeSpent,
    formValueStartedAtDate,
    timeAvailable,
    stageRequiredButNotSelected,
    submitForm,
    handlePause,
    setIsUpdatingTimelog,
    onDuplicateTimelog,
    iri,
    handleModal
}) => {
    const classes = classnames({
        [styles['time-tracking__draft-list__single__actions-options']]: true,
        [styles['time-tracking__submitted']]: isAlreadySubmitted,
        [styles['time-tracking__submitted-options']]: isAlreadySubmitted
    });

    const canTimelogsOverflow = (isOverflowAllowed, stageExists) => {
        if (!stageExists) {
            return true;
        }

        if (isOverflowAllowed) {
            return true;
        }

        return false;
    };

    const canOverflow = canTimelogsOverflow(allowedTimeTrackingOverflow, doesTimelogStageExist);

    const isSubmitButtonDisabled =
        !isAlreadySubmitted &&
        (noTimeSpent ||
            errorsExist ||
            notEnoughAvailableHours ||
            (!canOverflow && timeAvailable <= 0) ||
            stageRequiredButNotSelected);

    return (
        <div className={classes}>
            {!isAlreadySubmitted && (
                <Button
                    disabled={isSubmitButtonDisabled}
                    variation="tertiary"
                    onClick={async () => {
                        if (status !== 'paused') {
                            await handlePause(
                                formValueTimeSpent,
                                formValueStartedAtDate,
                                'INSTANT'
                            );
                        }

                        submitForm();
                    }}
                    label="Submit"
                />
            )}

            <ButtonDropdown
                icon="action"
                iconOnly
                triggerActionOnOptionSelection
                options={[
                    {
                        id: 'duplicate_timelog',
                        label: 'Duplicate Timelog',
                        onClick: async () => {
                            setIsUpdatingTimelog(true);
                            await onDuplicateTimelog(iri);
                            setIsUpdatingTimelog(false);
                        }
                    },
                    ...insertIf(!isLocked, [
                        {
                            id: 'delete',
                            label: 'Delete Timelog',
                            onClick: () => handleModal()
                        }
                    ])
                ]}
            />
        </div>
    );
};

SideButtons.defaultProps = {
    iri: '',
    formValueTimeSpent: 0,
    formValueStartedAtDate: '',
    timeAvailable: 0
};

SideButtons.propTypes = {
    isAlreadySubmitted: PropTypes.bool.isRequired,
    noTimeSpent: PropTypes.bool.isRequired,
    errorsExist: PropTypes.bool.isRequired,
    isLocked: PropTypes.bool.isRequired,
    doesTimelogStageExist: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    iri: PropTypes.string,
    formValueTimeSpent: PropTypes.number,
    formValueStartedAtDate: PropTypes.string,
    notEnoughAvailableHours: PropTypes.bool.isRequired,
    allowedTimeTrackingOverflow: PropTypes.bool.isRequired,
    timeAvailable: PropTypes.number,
    stageRequiredButNotSelected: PropTypes.bool.isRequired,
    submitForm: PropTypes.func.isRequired,
    handlePause: PropTypes.func.isRequired,
    setIsUpdatingTimelog: PropTypes.func.isRequired,
    onDuplicateTimelog: PropTypes.func.isRequired,
    handleModal: PropTypes.func.isRequired
};

const ErrorMessage = ({ text }) => {
    return (
        <div className={styles['time-tracking__draft-warning']}>
            <Svg icon="info" iconColor="tomato" />
            <span>{text}</span>
        </div>
    );
};

ErrorMessage.propTypes = {
    text: PropTypes.string.isRequired
};

const TimeInput = ({ isVisible, id, formChange, handleBlur }) => {
    useLayoutEffect(() => {
        const input = document.getElementById(id);

        if (input) {
            if (isVisible) {
                if (input.value === '0s') {
                    formChange('time_spent', '');
                }
                input.focus();
            } else {
                input.blur();
            }
        }
    }, [isVisible]);

    useLayoutEffect(() => {
        return () => {
            const input = document.getElementById(id);
            if (input) {
                input.blur();
            }
        };
    }, []);

    return (
        <Field
            name="time_spent"
            fieldProps={{
                label: '',
                hidden: !isVisible
            }}
            fieldAttr={{
                id,
                clearable: true
            }}
            onBlur={handleBlur}
            component={Timelog}
        />
    );
};
TimeInput.defaultProps = {
    isVisible: false,
    id: null,
    formChange: () => {},
    handleBlur: () => {}
};
TimeInput.propTypes = {
    isVisible: PropTypes.bool,
    id: PropTypes.string,
    formChange: PropTypes.func,
    handleBlur: PropTypes.func
};

const TimeTrackingForm = ({
    data,
    onTimelogUpdate,
    onStartTimer,
    onPauseTimer,
    onDeleteTimelog,
    handleSubmit,
    values,
    form,
    getCurrentTime,
    type,
    onDuplicateTimelog,
    onTimelogChange,
    submitting,
    errors,
    onTimelogBillableHoursUpdate,
    submitErrors,
    isDisabledStartTimerButton
}) => {
    const [singleTimeTrackingData, setSingleTimeTrackingData] = useState({});
    const [timelogEditableField, setTimelogEditableField] = useState(false);
    const [isUpdatingTimelog, setIsUpdatingTimelog] = useState(false);
    const [notEnoughtAvailableHours, setNotEnoughtAvailableHours] = useState(false);
    const { toggleDropdown, dropdownOpenStatus } = useDropdown();
    const projectContractStages = useMemo(
        () => data?.project_contract?.stages?.filter((stage) => !stage?.is_completed),
        [data?.project_contract?.stages]
    );
    const [opened, setOpened] = useState(false);
    const [submittingDeleteModal, setSubmittingDeleteModal] = useState(false);
    const dispatch = useDispatch();
    const allowedTimeTrackingOverflow = useMemo(() => {
        return data?.project_contract?.meta?.allow_time_tracking_overflow !== undefined
            ? data?.project_contract?.meta?.allow_time_tracking_overflow
            : true;
    }, [data?.project_contract?.meta?.allow_time_tracking_overflow]);

    const timeInputId = useMemo(() => {
        return nextId(`${data?.iri}-time_spent-`);
    }, [data]);

    const handleModal = () => {
        setOpened(!opened);
    };

    const areThereEnoughtAvailableHours = useCallback(
        (timeSpent = null) => {
            if (timeSpent) {
                return (
                    !!singleTimeTrackingData?.time_available &&
                    !!timeSpent &&
                    singleTimeTrackingData?.time_available + singleTimeTrackingData?.time_spent <
                        timeSpent
                );
            }

            return (
                !!singleTimeTrackingData?.time_available &&
                !!values?.time_spent &&
                singleTimeTrackingData?.time_available + singleTimeTrackingData?.time_spent <
                    values?.time_spent
            );
        },
        [singleTimeTrackingData?.time_available, values?.time_spent]
    );

    const handleBlur = useCallback(
        async (timeSpent) => {
            if (singleTimeTrackingData?.time_spent === timeSpent) {
                setTimelogEditableField(false);

                form.change('time_spent', timeSpent);
                return null;
            }

            setIsUpdatingTimelog(true);
            try {
                const response = await onTimelogUpdate(
                    singleTimeTrackingData?.iri,
                    timeSpent,
                    data?.time_spent_billable > 0 || data?.time_spent === 0 ? timeSpent : 0
                );

                setTimelogEditableField(false);
                setNotEnoughtAvailableHours(false);
                setSingleTimeTrackingData(response);

                if (response?.time_spent && form) {
                    form.change('time_spent', response?.time_spent);
                }
            } catch (e) {
                const notEnoughtAvailableTimeError = e?.errors?.find(
                    (error) => error?.code === 'timelog.timeAllocated.invalidTimeLog'
                );

                if (notEnoughtAvailableTimeError) {
                    setSingleTimeTrackingData({
                        ...singleTimeTrackingData,
                        time_available:
                            notEnoughtAvailableTimeError?.cause?.timeAvailable ||
                            singleTimeTrackingData?.time_available
                    });

                    setNotEnoughtAvailableHours(true);
                }
            } finally {
                setIsUpdatingTimelog(false);
            }

            return true;
        },
        [singleTimeTrackingData, onTimelogUpdate, areThereEnoughtAvailableHours]
    );

    const handleBillableHours = useCallback(
        async (timeSpent) => {
            const isTimeSpentBillable = data?.time_spent_billable > 0;
            try {
                setIsUpdatingTimelog(true);
                await onTimelogBillableHoursUpdate(data?.iri, timeSpent, !isTimeSpentBillable);
            } catch (error) {
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: error?.response?.data || error
                });
            } finally {
                setIsUpdatingTimelog(false);
            }

            return true;
        },
        [data]
    );

    const handleClick = useCallback(
        (e) => {
            if (
                !e.target.classList.contains(
                    styles['time-tracking__draft-list__single__actions-time-button']
                ) &&
                e.target.getAttribute('id') !== timeInputId &&
                !form.getFieldState('time_spent')?.modified
            ) {
                setTimelogEditableField(false);
            }
        },
        [timeInputId]
    );

    const timePassedFromStartInMiliseconds = useCallback((startedAt) => {
        const difference = moment.duration(moment(new Date()).diff(moment(startedAt)));

        return difference.asMilliseconds();
    }, []);

    const renderTimer = useCallback(
        (initialSeconds, startImmediately = true) => {
            return (
                <div className={styles['time-tracking__draft-form-timer']}>
                    <Timer
                        initialTime={initialSeconds}
                        startImmediately={startImmediately}
                        lastUnit="h"
                        direction="forward"
                    >
                        {({ getTime }) => {
                            if (getCurrentTime) getCurrentTime(getTime());

                            return (
                                <>
                                    <Timer.Hours
                                        formatValue={(value) => `${value > 0 ? `${value}:` : ''}`}
                                    />
                                    <Timer.Minutes
                                        formatValue={(value) => {
                                            return `${value > 9 ? value : `0${value}`}`;
                                        }}
                                    />
                                    :
                                    <Timer.Seconds
                                        formatValue={(value) =>
                                            `${value > 9 ? value : `0${value}`}`
                                        }
                                    />
                                </>
                            );
                        }}
                    </Timer>
                </div>
            );
        },
        [getCurrentTime]
    );

    const handleStart = useCallback(async () => {
        if (onStartTimer) {
            setIsUpdatingTimelog(true);

            const response = await onStartTimer(singleTimeTrackingData?.iri);

            if (response) {
                form.change('status', response?.status);
                form.change('started_at', response?.started_at);
            }
            setIsUpdatingTimelog(false);
        }
    }, [onStartTimer, singleTimeTrackingData?.iri]);

    const handlePause = useCallback(
        async (timeSpent, startedAt, method) => {
            if (onPauseTimer) {
                setIsUpdatingTimelog(true);

                const timeSpentSum = Math.round(
                    (timePassedFromStartInMiliseconds(startedAt) + timeSpent * 1000) / 1000
                );

                const response = await onPauseTimer(
                    singleTimeTrackingData?.iri,
                    timeSpentSum,
                    method
                );

                if (response) {
                    form.change('status', response?.status);
                    form.change('time_spent', response?.time_spent);
                }
                setIsUpdatingTimelog(false);
            }
        },
        [onPauseTimer, singleTimeTrackingData?.iri, timePassedFromStartInMiliseconds]
    );

    const handleKeyPress = useCallback((e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            setTimelogEditableField(false);
        }
    }, []);

    const onSubmitDeleteTimeLog = async () => {
        try {
            setIsUpdatingTimelog(true);
            setSubmittingDeleteModal(true);
            await onDeleteTimelog(data?.iri);
        } catch (error) {
            dispatch({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: error?.response?.data || error
            });
        } finally {
            setIsUpdatingTimelog(false);
            setSubmittingDeleteModal(false);
        }
    };

    useEffect(() => {
        // This is used because when time is clicked, time_spent field is registered, but blur will not occur if user doesnt change anything or focus the field
        // That means that if he clicks somewhere outside the field, it will not go back to original button
        // He would need to click the button and then click the field so its on focus and after that blur would trigger of of that field
        // This eventListener is to cover the case when user clicked to change  the timelog, but changed his mind, so it would disapair
        if (timelogEditableField) {
            window.addEventListener('mousedown', handleClick);
        } else {
            window.removeEventListener('mousedown', handleClick);
        }

        return () => {
            window.removeEventListener('mousedown', handleClick);
        };
    }, [timelogEditableField]);

    useEffect(() => {
        if (submitErrors?.time_spent) {
            setSingleTimeTrackingData({
                ...singleTimeTrackingData,
                time_available:
                    submitErrors?.time_spent?.cause?.timeAvailable ||
                    singleTimeTrackingData?.time_available
            });

            setNotEnoughtAvailableHours(true);
        } else {
            setNotEnoughtAvailableHours(false);
        }
    }, [submitErrors]);

    useEffect(() => {
        setSingleTimeTrackingData(data);
    }, [data]);

    useEffect(() => {
        if (timelogEditableField) {
            window.addEventListener('keypress', handleKeyPress);
        } else {
            window.removeEventListener('keypress', handleKeyPress);
        }
    }, [timelogEditableField]);

    const timeSpentElement = document.getElementById(`${data?.iri}-time_spent`);

    useEffect(() => {
        if (timeSpentElement) {
            if (timeSpentElement.value === '0s') {
                form.change('time_spent', '');
            }

            timeSpentElement.focus();
        }
    }, [timeSpentElement]);

    const timeAvailable = singleTimeTrackingData?.time_available;

    const timeRemainingClass = useMemo(() => {
        let result = '';

        if (timeAvailable < 28800 && timeAvailable > 3600) {
            result = styles['time-tracking__draft-list__single-info__bottom-remaining--orange'];
        }

        if (timeAvailable <= 3600) {
            result = styles['time-tracking__draft-list__single-info__bottom-remaining--red'];
        }

        return result;
    }, [timeAvailable]);

    const formClassnames = classnames({
        [styles['time-tracking__draft-form']]: true,
        [styles['time-tracking__draft-form--active']]: values?.status === 'draft',
        [styles['time-tracking__draft--disabled']]: singleTimeTrackingData?.locked
    });

    const displayExceededStageHoursAllocationError =
        notEnoughtAvailableHours ||
        (data?.status !== 'completed' &&
            timeAvailable < 0 &&
            data?.project_contract?.meta !== null &&
            !data?.project_contract?.meta?.allow_time_tracking_overflow &&
            !errors.stage);

    const isBackgroundRed =
        data?.status !== 'completed' &&
        (Object.keys(errors).length > 0 ||
            (!allowedTimeTrackingOverflow &&
                typeof timeAvailable === 'number' &&
                timeAvailable <= 0));

    const contentWrapperClasses = classnames({
        [styles['time-tracking__draft-list__single']]: true,
        [styles['time-tracking__draft-list__single-warning']]: isBackgroundRed
    });

    const infoComponentClasses = classnames({
        [styles['time-tracking__draft-list__single-info']]: true,
        [styles['time-tracking__draft-list__single-info--submitted']]: type === 'submitted_logs'
    });

    const actionButtonsClasses = classnames({
        [styles['time-tracking__draft-list__single__actions']]: true,
        [styles['time-tracking__draft-list__single__actions--submitted']]: type === 'submitted_logs'
    });

    return (
        <Form className={formClassnames} onSubmit={handleSubmit}>
            {Object.keys(errors).map((key) => (
                <ErrorMessage key={key} text={errors[key]} />
            ))}

            {displayExceededStageHoursAllocationError && (
                <ErrorMessage
                    text="Attention! You are exceeding the allocated hours for the project stage. To
                        submit this time log, please update remaining hours on stage."
                />
            )}

            {(isUpdatingTimelog || submitting) && <ElementLoader overlay />}
            <div className={contentWrapperClasses}>
                <div className={infoComponentClasses}>
                    {/* Top row section */}
                    <div className={styles['time-tracking__draft-list__single-info__top']}>
                        <Title
                            singleTimeTrackingData={singleTimeTrackingData}
                            isLocked={!!singleTimeTrackingData?.locked}
                            projectName={
                                singleTimeTrackingData?.project_contract?.project_name || ''
                            }
                            contractName={singleTimeTrackingData?.project_contract?.name || ''}
                        />

                        {singleTimeTrackingData?.iri && (
                            <WorkTypeSelection
                                workTypeName={singleTimeTrackingData?.work_type?.name || ''}
                                isLocked={!!singleTimeTrackingData?.locked}
                                iri={singleTimeTrackingData?.iri}
                                workTypes={
                                    singleTimeTrackingData?.project_contract?.available_work_types
                                }
                                dropdownOpenStatus={dropdownOpenStatus}
                                toggleDropdown={toggleDropdown}
                            />
                        )}

                        {!!projectContractStages?.some((stage) => !stage.is_completed) &&
                            singleTimeTrackingData?.iri && (
                                <StagesSelection
                                    stageName={singleTimeTrackingData?.stage?.name}
                                    iri={singleTimeTrackingData?.iri}
                                    dropdownOpenStatus={dropdownOpenStatus}
                                    projectContractStages={projectContractStages}
                                    toggleDropdown={toggleDropdown}
                                />
                            )}
                    </div>

                    {/* Bottom row section */}
                    <div className={styles['time-tracking__draft-list__single-info__bottom']}>
                        {singleTimeTrackingData?.iri && (
                            <>
                                <TaskUrlInput
                                    isLocked={!!singleTimeTrackingData?.locked}
                                    taskUrl={values?.task_url}
                                    iri={singleTimeTrackingData?.iri}
                                    dropdownOpenStatus={dropdownOpenStatus}
                                    toggleDropdown={toggleDropdown}
                                />

                                <Description
                                    isLocked={singleTimeTrackingData?.locked}
                                    comment={values?.comment}
                                    iri={singleTimeTrackingData?.iri}
                                    dropdownOpenStatus={dropdownOpenStatus}
                                    toggleDropdown={toggleDropdown}
                                />
                            </>
                        )}

                        {type !== 'submitted_logs' &&
                            projectContractStages?.length > 0 &&
                            !!singleTimeTrackingData?.stage && (
                                <AvailableTimeNotification
                                    timeAvailable={
                                        singleTimeTrackingData?.time_available !== null
                                            ? singleTimeTrackingData?.time_available
                                            : singleTimeTrackingData?.time_available_on_creation
                                    }
                                    singleTimeTrackingData={singleTimeTrackingData}
                                    timeRemainingClass={timeRemainingClass}
                                />
                            )}
                    </div>
                </div>

                {/* Action buttons section */}
                <div className={actionButtonsClasses}>
                    <div className={styles['time-tracking__draft-list__single__actions-time']}>
                        {!!singleTimeTrackingData?.time_spent && (
                            <BillableIcon
                                timelogTimeSpentBillable={
                                    singleTimeTrackingData?.time_spent_billable
                                }
                                formValueTimeSpent={values?.time_spent}
                                isPaused={values?.status === 'paused'}
                                handleBillableHours={handleBillableHours}
                            />
                        )}

                        {singleTimeTrackingData?.iri && (
                            <DateSelection
                                isLocked={!!singleTimeTrackingData?.locked}
                                iri={singleTimeTrackingData?.iri}
                                date={values.date}
                                toggleDropdown={toggleDropdown}
                                dropdownOpenStatus={dropdownOpenStatus}
                            />
                        )}
                        {singleTimeTrackingData?.iri && (
                            <TimerComponent
                                isLocked={singleTimeTrackingData?.locked}
                                status={values?.status}
                                formValueTimeSpent={values?.time_spent}
                                formValueStartedAtDate={values?.started_at}
                                formChange={form.change}
                                handleBlur={handleBlur}
                                handlePause={handlePause}
                                handleStart={handleStart}
                                isDisabledStartTimerButton={isDisabledStartTimerButton}
                                notEnoughAvailableHours={notEnoughtAvailableHours}
                                renderTimer={renderTimer}
                                setTimelogEditableField={setTimelogEditableField}
                                singleTimeTrackingData={singleTimeTrackingData}
                                timeInputId={timeInputId}
                                timePassedFromStartInMiliseconds={timePassedFromStartInMiliseconds}
                                form={form}
                                isTimelogFieldEditable={timelogEditableField}
                            />
                        )}
                    </div>

                    <FormSpy
                        subscription={{
                            modified: true,
                            active: true,
                            values: true,
                            initialValues: true,
                            valid: true
                        }}
                        onChange={async ({
                            modified,
                            active,
                            values: formValues,
                            initialValues
                        }) => {
                            if (
                                (!!modified.work_type ||
                                    (!!modified.comment && !active) ||
                                    (!!modified.task_url && !active) ||
                                    (!!modified.date && !active) ||
                                    (!!modified.stage && !active)) &&
                                (initialValues?.comment !== formValues?.comment ||
                                    initialValues?.task_url !== formValues?.task_url ||
                                    initialValues?.work_type !== formValues?.work_type ||
                                    initialValues?.date !== formValues?.date ||
                                    initialValues?.stage !== formValues?.stage)
                            ) {
                                const valueChangedFor =
                                    Object.keys(modified).filter((item) => !!modified[item])?.[0] ||
                                    null;

                                if (valueChangedFor) {
                                    setIsUpdatingTimelog(true);
                                    form.resetFieldState(valueChangedFor);
                                    await onTimelogChange(
                                        valueChangedFor,
                                        formValues[valueChangedFor],
                                        singleTimeTrackingData?.iri
                                    );
                                    toggleDropdown(null);
                                    setIsUpdatingTimelog(false);
                                }
                            }
                        }}
                    />

                    {singleTimeTrackingData?.iri && (
                        <SideButtons
                            isAlreadySubmitted={type === 'submitted_logs'}
                            noTimeSpent={values?.time_spent <= 0}
                            errorsExist={
                                Object.keys(errors)?.length > 0 ||
                                displayExceededStageHoursAllocationError
                            }
                            isLocked={!!data?.locked}
                            doesTimelogStageExist={!!data?.stage}
                            iri={singleTimeTrackingData?.iri}
                            status={values?.status}
                            formValueTimeSpent={values?.time_spent}
                            formValueStartedAtDate={values?.started_at}
                            notEnoughAvailableHours={notEnoughtAvailableHours}
                            allowedTimeTrackingOverflow={allowedTimeTrackingOverflow}
                            timeAvailable={data?.time_available}
                            stageRequiredButNotSelected={
                                !!singleTimeTrackingData?.project_contract?.meta?.stage_required &&
                                !!singleTimeTrackingData?.project_contract?.stages?.some(
                                    (stage) => !stage.is_completed
                                ) &&
                                !singleTimeTrackingData?.stage
                            }
                            submitForm={form.submit}
                            handlePause={handlePause}
                            setIsUpdatingTimelog={setIsUpdatingTimelog}
                            onDuplicateTimelog={onDuplicateTimelog}
                            handleModal={handleModal}
                        />
                    )}
                </div>
            </div>

            {opened && (
                <Modal
                    variation="small"
                    opened={opened}
                    onClose={() => handleModal()}
                    title="Delete time log"
                    subtitle="Are you sure you want to delete this time log?"
                >
                    <Button
                        onClick={onSubmitDeleteTimeLog}
                        loading={submittingDeleteModal}
                        label="Delete"
                    />
                    <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
                </Modal>
            )}
        </Form>
    );
};

TimeTrackingForm.defaultProps = {
    data: {},
    handleSubmit: () => {},
    onTimelogUpdate: () => {},
    onStartTimer: () => {},
    onPauseTimer: () => {},
    onDeleteTimelog: () => {},
    onDuplicateTimelog: () => {},
    onTimelogChange: () => {},
    values: {},
    form: {},
    getCurrentTime: null,
    type: null,
    submitting: false,
    errors: {},
    onTimelogBillableHoursUpdate: () => {},
    submitErrors: null,
    isDisabledStartTimerButton: false
};

TimeTrackingForm.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]),
    handleSubmit: PropTypes.func,
    onTimelogUpdate: PropTypes.func,
    onStartTimer: PropTypes.func,
    onPauseTimer: PropTypes.func,
    onDeleteTimelog: PropTypes.func,
    onDuplicateTimelog: PropTypes.func,
    onTimelogChange: PropTypes.func,
    values: PropTypes.oneOfType([PropTypes.object]),
    form: PropTypes.oneOfType([PropTypes.object]),
    getCurrentTime: PropTypes.func,
    type: PropTypes.string,
    submitting: PropTypes.bool,
    errors: PropTypes.oneOfType([PropTypes.object]),
    onTimelogBillableHoursUpdate: PropTypes.func,
    submitErrors: PropTypes.oneOfType([PropTypes.object]),
    isDisabledStartTimerButton: PropTypes.bool
};

export default memo(TimeTrackingForm);
