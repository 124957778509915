import { actions as projectContractActions } from 'erpcore/screens/Projects/screens/ProjectContracts/ProjectContracts.reducer';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';

const ProjectContractDataFetcher = () => {
    const dispatch = useDispatch();
    const routeParams = useParams();

    const fetchProjectContractData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectContractActions.START_FETCHING_PROJECT_CONTRACT,
                contractIri: `/api/contracts/${routeParams?.id}`
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        if (routeParams?.id) fetchProjectContractData();
    }, [routeParams?.id]);

    return <Outlet />;
};

export default ProjectContractDataFetcher;
