import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Projects Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const Projects = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'project.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project.existWithProvidedJiraProjectId':
            return (
                <Notification
                    identifier={identifier}
                    title="Warning"
                    text={detail}
                    type="warning"
                    expire="6000"
                />
            );
        case 'issue.assignSuccess':
            return (
                <Notification
                    identifier={identifier}
                    title="In progress"
                    type="info"
                    expire="5000"
                    text={
                        <>
                            {detail}. <br />
                            Adding bulk issues is in progress.
                            <br />
                            <br />
                            Please{' '}
                            <button
                                type="button"
                                style={{
                                    display: 'inline',
                                    margin: 0,
                                    padding: 0,
                                    border: 'none',
                                    borderRadius: 0,
                                    font: 'inherit',
                                    textDecoration: 'underline',
                                    fontWeight: 700,
                                    textTransform: 'none',
                                    color: '#fff',
                                    background: 'none',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    window.location.reload();
                                    return false;
                                }}
                            >
                                refresh
                            </button>{' '}
                            the page.
                        </>
                    }
                />
            );
        default:
            return null;
    }
};

Projects.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

Projects.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default Projects;
