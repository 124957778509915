export const actions = {
    // Utilization over time
    START_FETCHING_UTILIZATION_OVER_TIME_DATA: 'START_FETCHING_UTILIZATION_OVER_TIME_DATA',
    FETCHING_SUCCESSFUL_UTILIZATION_OVER_TIME_DATA:
        'FETCHING_SUCCESSFUL_UTILIZATION_OVER_TIME_DATA',
    FETCHING_FAILED_UTILIZATION_OVER_TIME_DATA: 'FETCHING_FAILED_UTILIZATION_OVER_TIME_DATA',
    STORE_UTILIZATION_OVER_TIME_DATA: 'STORE_UTILIZATION_OVER_TIME_DATA',

    // Labor cost and value
    START_FETCHING_LABOUR_COST_AND_VALUE_DATA: 'START_FETCHING_LABOUR_COST_AND_VALUE_DATA',
    FETCHING_SUCCESSFUL_LABOUR_COST_AND_VALUE_DATA:
        'FETCHING_SUCCESSFUL_LABOUR_COST_AND_VALUE_DATA',
    FETCHING_FAILED_LABOUR_COST_AND_VALUE_DATA: 'FETCHING_FAILED_LABOUR_COST_AND_VALUE_DATA',
    STORE_LABOUR_COST_AND_VALUE_DATA: 'STORE_LABOUR_COST_AND_VALUE_DATA',

    // Staff cost and value
    START_FETCHING_STAFF_COST_AND_VALUE_DATA: 'START_FETCHING_STAFF_COST_AND_VALUE_DATA',
    FETCHING_SUCCESSFUL_STAFF_COST_AND_VALUE_DATA: 'FETCHING_SUCCESSFUL_STAFF_COST_AND_VALUE_DATA',
    FETCHING_FAILED_STAFF_COST_AND_VALUE_DATA: 'FETCHING_FAILED_STAFF_COST_AND_VALUE_DATA',
    STORE_STAFF_COST_AND_VALUE_DATA: 'STORE_STAFF_COST_AND_VALUE_DATA',

    // Output value and labour cost by work type
    START_FETCHING_WORK_TYPE_DATA: 'START_FETCHING_WORK_TYPE_DATA',
    FETCHING_SUCCESSFUL_WORK_TYPE_DATA: 'FETCHING_SUCCESSFUL_WORK_TYPE_DATA',
    FETCHING_FAILED_WORK_TYPE_DATA: 'FETCHING_FAILED_WORK_TYPE_DATA',
    STORE_WORK_TYPE_DATA: 'STORE_WORK_TYPE_DATA',

    // Output value and labour cost by client
    START_FETCHING_CLIENT_DATA: 'START_FETCHING_CLIENT_DATA',
    FETCHING_SUCCESSFUL_CLIENT_DATA: 'FETCHING_SUCCESSFUL_CLIENT_DATA',
    FETCHING_FAILED_CLIENT_DATA: 'FETCHING_FAILED_CLIENT_DATA',
    STORE_CLIENT_DATA: 'STORE_CLIENT_DATA'
};

export default (state = {}, { type, data }) => {
    switch (type) {
        // Utilization over time
        case actions.START_FETCHING_UTILIZATION_OVER_TIME_DATA:
            return {
                ...state,
                utilizationOverTime: {
                    ...state.utilizationOverTime,
                    fetching: true
                }
            };

        case actions.FETCHING_SUCCESSFUL_UTILIZATION_OVER_TIME_DATA:
        case actions.FETCHING_FAILED_UTILIZATION_OVER_TIME_DATA:
            return {
                ...state,
                utilizationOverTime: {
                    ...state.utilizationOverTime,
                    fetching: false
                }
            };

        case actions.STORE_UTILIZATION_OVER_TIME_DATA:
            return {
                ...state,
                utilizationOverTime: {
                    ...state.utilizationOverTime,
                    data
                }
            };

        // Labour cost and value
        case actions.START_FETCHING_LABOUR_COST_AND_VALUE_DATA:
            return {
                ...state,
                labourCostAndValue: {
                    ...state.labourCostAndValue,
                    fetching: true
                }
            };

        case actions.FETCHING_SUCCESSFUL_LABOUR_COST_AND_VALUE_DATA:
        case actions.FETCHING_FAILED_LABOUR_COST_AND_VALUE_DATA:
            return {
                ...state,
                labourCostAndValue: {
                    ...state.labourCostAndValue,
                    fetching: false
                }
            };

        case actions.STORE_LABOUR_COST_AND_VALUE_DATA:
            return {
                ...state,
                labourCostAndValue: {
                    ...state.labourCostAndValue,
                    data
                }
            };

        // Staff cost and value
        case actions.START_FETCHING_STAFF_COST_AND_VALUE_DATA:
            return {
                ...state,
                staffCostAndValue: {
                    ...state.staffCostAndValue,
                    fetching: true
                }
            };

        case actions.FETCHING_SUCCESSFUL_STAFF_COST_AND_VALUE_DATA:
        case actions.FETCHING_FAILED_STAFF_COST_AND_VALUE_DATA:
            return {
                ...state,
                staffCostAndValue: {
                    ...state.staffCostAndValue,
                    fetching: false
                }
            };

        case actions.STORE_STAFF_COST_AND_VALUE_DATA:
            return {
                ...state,
                staffCostAndValue: {
                    ...state.staffCostAndValue,
                    data
                }
            };

        // Output value and labour cost by work type
        case actions.START_FETCHING_WORK_TYPE_DATA:
            return {
                ...state,
                workType: {
                    ...state.workType,
                    // [chartType]: {
                    //     ...state.expenses?.[chartType],
                    //     [by]: { ...state.expenses?.[by], fetching: true }
                    // },
                    fetching: true
                }
            };

        case actions.FETCHING_SUCCESSFUL_WORK_TYPE_DATA:
        case actions.FETCHING_FAILED_WORK_TYPE_DATA:
            return {
                ...state,
                workType: {
                    ...state.workType,
                    // [chartType]: {
                    //     ...state.expenses?.[chartType],
                    //     [by]: { ...state.expenses?.[by], fetching: false }
                    // }
                    fetching: false
                }
            };

        case actions.STORE_WORK_TYPE_DATA:
            return {
                ...state,
                workType: {
                    ...state.workType,
                    data
                }
            };

        // Output value and labour cost by client
        case actions.START_FETCHING_CLIENT_DATA:
            return {
                ...state,
                client: {
                    ...state.client,
                    // [chartType]: {
                    //     ...state.expenses?.[chartType],
                    //     [by]: { ...state.expenses?.[by], fetching: true }
                    // },
                    fetching: true
                }
            };

        case actions.FETCHING_SUCCESSFUL_CLIENT_DATA:
        case actions.FETCHING_FAILED_CLIENT_DATA:
            return {
                ...state,
                client: {
                    ...state.client,
                    // [chartType]: {
                    //     ...state.expenses?.[chartType],
                    //     [by]: { ...state.expenses?.[by], fetching: false }
                    // }
                    fetching: false
                }
            };

        case actions.STORE_CLIENT_DATA:
            return {
                ...state,
                client: {
                    ...state.client,
                    data
                }
            };

        default:
            return state;
    }
};
