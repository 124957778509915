export const actions = {
    START_FETCHING_PROJECT_DASHBOARD_REPORT_TIME_LOGS:
        'START_FETCHING_PROJECT_DASHBOARD_REPORT_TIME_LOGS',
    FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_REPORT_TIME_LOGS:
        'FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_REPORT_TIME_LOGS',
    FETCHING_FAILED_PROJECT_DASHBOARD_REPORT_TIME_LOGS:
        'FETCHING_FAILED_PROJECT_DASHBOARD_REPORT_TIME_LOGS',
    STORE_REPORT_PROJECT_DASHBOARD_TIME_LOGS_DATA: 'STORE_REPORT_PROJECT_DASHBOARD_TIME_LOGS_DATA',

    START_FETCHING_PROJECT_DASHBOARD_PROJECT_CONTRACTS_LISTING:
        'START_FETCHING_PROJECT_DASHBOARD_PROJECT_CONTRACTS_LISTING',
    FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_PROJECT_CONTRACTS_LISTING:
        'FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_PROJECT_CONTRACTS_LISTING',
    FETCHING_FAILED_PROJECT_DASHBOARD_PROJECT_CONTRACTS_LISTING:
        'FETCHING_FAILED_PROJECT_DASHBOARD_PROJECT_CONTRACTS_LISTING',

    START_FETCHING_PROJECT_DASHBOARD_SALES_ORDERS_LISTING:
        'START_FETCHING_PROJECT_DASHBOARD_SALES_ORDERS_LISTING',
    FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_SALES_ORDERS_LISTING:
        'FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_SALES_ORDERS_LISTING',
    FETCHING_FAILED_PROJECT_DASHBOARD_SALES_ORDERS_LISTING:
        'FETCHING_FAILED_PROJECT_DASHBOARD_SALES_ORDERS_LISTING',

    START_FETCHING_PROJECT_DASHBOARD_REVENUES_LISTING:
        'START_FETCHING_PROJECT_DASHBOARD_REVENUES_LISTING',
    FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_REVENUES_LISTING:
        'FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_REVENUES_LISTING',
    FETCHING_FAILED_PROJECT_DASHBOARD_REVENUES_LISTING:
        'FETCHING_FAILED_PROJECT_DASHBOARD_REVENUES_LISTING',

    START_FETCHING_PROJECT_DASHBOARD_EXPENSES_LISTING:
        'START_FETCHING_PROJECT_DASHBOARD_EXPENSES_LISTING',
    FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_EXPENSES_LISTING:
        'FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_EXPENSES_LISTING',
    FETCHING_FAILED_PROJECT_DASHBOARD_EXPENSES_LISTING:
        'FETCHING_FAILED_PROJECT_DASHBOARD_EXPENSES_LISTING',

    CLEAR_PROJECT_DASHBOARD_STATE: 'CLEAR_PROJECT_DASHBOARD_STATE'
};

export const initialState = {
    projectContractsListingFetching: false,
    projectContractsListingResponse: {
        data: [],
        meta: {},
        included: []
    },
    projectContractsListingError: [],
    salesOrdersListingFetching: false,
    salesOrdersListingResponse: {
        data: [],
        meta: {},
        included: []
    },
    salesOrdersListingError: [],
    revenuesListingFetching: false,
    revenuesListingResponse: {
        data: [],
        meta: {},
        included: []
    },
    revenuesListingError: [],
    expensesListingFetching: false,
    expensesListingResponse: {
        data: [],
        meta: {},
        included: []
    },
    expensesListingError: []
};

export default (state = initialState, { type, response, by }) => {
    switch (type) {
        case actions.START_FETCHING_PROJECT_DASHBOARD_REPORT_TIME_LOGS: {
            return {
                ...state,
                ...{ [by]: { ...state[by], ...{ fetching: true } } }
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_REPORT_TIME_LOGS: {
            return {
                ...state,
                ...{ [by]: { ...state[by], ...{ fetching: false } } }
            };
        }
        case actions.FETCHING_FAILED_PROJECT_DASHBOARD_REPORT_TIME_LOGS: {
            return {
                ...state,
                ...{ [by]: { ...state[by], ...{ fetching: false, error: response } } }
            };
        }
        case actions.STORE_REPORT_PROJECT_DASHBOARD_TIME_LOGS_DATA: {
            return {
                ...state,
                ...{ [by]: { ...state[by], ...{ data: response } } }
            };
        }

        case actions.START_FETCHING_PROJECT_DASHBOARD_PROJECT_CONTRACTS_LISTING: {
            return {
                ...state,
                projectContractsListingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_PROJECT_CONTRACTS_LISTING: {
            return {
                ...state,
                projectContractsListingFetching: false,
                projectContractsListingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PROJECT_DASHBOARD_PROJECT_CONTRACTS_LISTING: {
            return {
                ...state,
                projectContractsListingFetching: false,
                projectContractsListingError: response
            };
        }

        case actions.START_FETCHING_PROJECT_DASHBOARD_SALES_ORDERS_LISTING: {
            return {
                ...state,
                salesOrdersListingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_SALES_ORDERS_LISTING: {
            return {
                ...state,
                salesOrdersListingFetching: false,
                salesOrdersListingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PROJECT_DASHBOARD_SALES_ORDERS_LISTING: {
            return {
                ...state,
                salesOrdersListingFetching: false,
                salesOrdersListingError: response
            };
        }

        case actions.START_FETCHING_PROJECT_DASHBOARD_REVENUES_LISTING: {
            return {
                ...state,
                revenuesListingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_REVENUES_LISTING: {
            return {
                ...state,
                revenuesListingFetching: false,
                revenuesListingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PROJECT_DASHBOARD_REVENUES_LISTING: {
            return {
                ...state,
                revenuesListingFetching: false,
                revenuesListingError: response
            };
        }

        case actions.START_FETCHING_PROJECT_DASHBOARD_EXPENSES_LISTING: {
            return {
                ...state,
                expensesListingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_EXPENSES_LISTING: {
            return {
                ...state,
                expensesListingFetching: false,
                expensesListingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PROJECT_DASHBOARD_EXPENSES_LISTING: {
            return {
                ...state,
                expensesListingFetching: false,
                expensesListingError: response
            };
        }

        case actions.CLEAR_PROJECT_DASHBOARD_STATE: {
            return {
                ...initialState
            };
        }
        default:
            return state;
    }
};
