import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import Form, { Autocomplete, RadioGroup, Textarea } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

import { actions as propsWidgetActions } from 'erpcore/components/PropsWidget/PropsWidget.reducer';
import { useDispatch, useSelector } from 'react-redux';
import ElementLoader from 'erpcore/components/ElementLoader';
import {
    getPropTypes,
    getPropTypesFetching
} from 'erpcore/components/PropsWidget/PropsWidget.selectors';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { useWindowWidth } from 'erpcore/utils/utils.hooks';
import { ifMobile } from 'erpcore/utils/utils';
import Tooltip from 'erpcore/components/Tooltip';

const PropsWidgetCreateForm = ({ handleSubmit, submitting, invalid, pristine, onSubmit }) => {
    const windowWidth = useWindowWidth();
    const dispatch = useDispatch();
    const fetching = useSelector(getPropTypesFetching);
    const propTypes = useSelector(getPropTypes);
    const meData = useSelector(getMeData);

    const radioOptions = propTypes.map(prop => {
        const { image_versions: imageVersions = [], name, iri } = { ...prop };

        const smallImage = imageVersions.find(({ data }) => data?.attributes?.name === 'small');
        let url = null;

        if (smallImage) {
            const { attributes } = { ...smallImage?.data };
            const { content_url: imageUrl } = { ...attributes };

            url = imageUrl;
        }

        return {
            id: iri,
            value: iri,
            label: ifMobile(windowWidth) ? (
                <img src={url} alt={name} />
            ) : (
                <Tooltip content={name}>
                    <img src={url} alt={name} />
                </Tooltip>
            )
        };
    });

    const fetchProps = params => {
        params = {
            ...params,
            include: 'image,image.versions',
            pagination: false
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: propsWidgetActions.START_FETCHING_PROP_TYPES,
                params
            });
        }).catch(error => {
            return error;
        });
    };

    useEffect(() => {
        fetchProps();
    }, []);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="toUser"
                    fieldProps={{
                        label: 'User',
                        options: {
                            endpoint: `api/users/list?filters[id][not_in][]=${meData?.iri}&filters[is_active][equals]=true`,
                            mapData: {
                                label: '{first_name} {last_name}',
                                value: 'iri'
                            }
                        }
                    }}
                    fieldAttr={{ id: 'user', required: true }}
                    component={Autocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="message"
                    fieldProps={{
                        label: 'Message'
                    }}
                    fieldAttr={{ id: 'note', required: true }}
                    component={Textarea}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row className="props-widget__icons">
                {fetching && <ElementLoader overlay />}
                <Field
                    name="type"
                    fieldProps={{
                        options: radioOptions
                    }}
                    fieldAttr={{ id: 'prop', required: true }}
                    component={RadioGroup}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Button submit disabled={invalid || pristine} loading={submitting} label="Submit" />
        </Form>
    );
};

PropsWidgetCreateForm.defaultProps = {
    submitting: false,
    invalid: false,
    pristine: false,
    onSubmit: () => {}
};

PropsWidgetCreateForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    onSubmit: PropTypes.func
};

export default reduxForm({
    form: 'PropsWidgetForm',
    enableReinitialize: true
})(PropsWidgetCreateForm);
