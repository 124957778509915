import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import clientLayout from 'erpcore/utils/LayoutManager/clientLayout';
import LayoutManager from 'erpcore/utils/LayoutManager';
import SuspenseLoader from 'erpcore/components/SuspenseLoader';

const ClientDashboard = React.lazy(() => import('erpcore/screens/Client/screens/Dashboard'));
const ClientProjects = React.lazy(() => import('erpcore/screens/Client/screens/Projects'));
const ClientProjectDashboard = React.lazy(() =>
    import('erpcore/screens/Client/screens/ProjectDashboard')
);
const ClientApprovals = React.lazy(() => import('erpcore/screens/Client/screens/Approvals'));
const ClientInvoices = React.lazy(() => import('erpcore/screens/Client/screens/Invoices'));
const ClientProjectContractDashboard = React.lazy(() =>
    import('erpcore/screens/Client/screens/ProjectContractDashboard')
);

const ClientBackRoutes = () => {
    return (
        <LayoutManager layout={clientLayout} slot="main" layoutType="merge">
            <Suspense fallback={<SuspenseLoader />}>
                <Routes>
                    {/* Client dashboard preview */}
                    <Route
                        key="ClientDashboard"
                        path="dashboard"
                        exact
                        element={<ClientDashboard />}
                    />
                    <Route
                        key="ClientDashboard"
                        path="dashboard/:id"
                        exact
                        element={<ClientDashboard />}
                    />
                    <Route
                        key="ClientProjects"
                        path="projects"
                        exact
                        element={<ClientProjects />}
                    />
                    <Route
                        key="ClientProjectDashboard"
                        path="projects/:id/dashboard"
                        exact
                        element={<ClientProjectDashboard />}
                    />
                    <Route
                        key="ClientApprovals"
                        path="approvals"
                        exact
                        element={<ClientApprovals />}
                    />
                    <Route
                        key="ClientInvoices"
                        path="invoices"
                        exact
                        element={<ClientInvoices />}
                    />
                    <Route
                        key="ClientProjectContractDashboard"
                        path="projects/:projectId/contracts/:id/dashboard"
                        exact
                        element={<ClientProjectContractDashboard />}
                    />
                </Routes>
            </Suspense>
        </LayoutManager>
    );
};

ClientBackRoutes.defaultProps = {};

ClientBackRoutes.propTypes = {};

export default ClientBackRoutes;
