import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - contracts Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const contracts = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'contract.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Contract successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'contract.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Contract successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'contract.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Contract successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'contract.canNotDeleteHasTimeLogs':
            return (
                <Notification
                    identifier={identifier}
                    title="Warning"
                    text="Can not delete contract because it has timelogs."
                    type="warning"
                />
            );
        case 'contract.StagesCloned':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Timeline successfully cloned!"
                    type="success"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

contracts.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

contracts.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default contracts;
