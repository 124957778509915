import restClient from 'erpcore/api/restClient';
import { call, put, takeLatest } from 'redux-saga/effects';
import { actions as projectApprovalsActions } from 'erpcore/screens/Projects/screens/ProjectApprovals/ProjectApprovals.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';

/**
 * Create project approval
 * @param promise
 * @param formData
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|AxiosPromise<any>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>, void, *>}
 */
export function* createProjectApproval({ promise, formData }) {
    try {
        const createApprovalAPI = yield restClient.post('api/approvals', formData);
        yield put({
            type: projectApprovalsActions.CREATE_PROJECT_APPROVAL_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createApprovalAPI?.data
        });
        yield call(promise.resolve, createApprovalAPI?.data);
    } catch (error) {
        yield put({
            type: projectApprovalsActions.CREATE_PROJECT_APPROVAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch single approval data
 * @param promise
 * @param id
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|AxiosPromise<any>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{response, type: string}>>, void, *>}
 */
export function* fetchApproval({ promise, id }) {
    try {
        const fetchApprovalAPI = yield restClient.get(`api/approvals/${id}`, {
            params: {
                include: 'contact,contract,company,file,workflowTransitionLogs,createdBy,assignedTo'
            }
        });
        const approvalsData = dto(fetchApprovalAPI?.data)?.data;
        yield put({
            type: projectApprovalsActions.FETCHING_SINGLE_PROJECT_APPROVAL_SUCCESS
        });
        yield put({
            type: projectApprovalsActions.STORE_SINGLE_PROJECT_APPROVAL_DATA,
            response: approvalsData
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectApprovalsActions.FETCHING_SINGLE_PROJECT_APPROVAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Update project approval
 * @param promise
 * @param formData
 * @param id
 * @param changeStatus
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|AxiosPromise<any>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>, void, *>}
 */
export function* updateProjectApproval({ promise, formData, id, changeStatus }) {
    try {
        const approvalAPI = yield restClient.put(`api/approvals/${id}`, formData);
        if (changeStatus) {
            yield restClient.patch(`api/approvals/${id}/status`, {
                transition: 'to_pending'
            });
        }
        yield put({
            type: projectApprovalsActions.UPDATING_SINGLE_PROJECT_APPROVAL_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: approvalAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectApprovalsActions.UPDATING_SINGLE_PROJECT_APPROVAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Delete project approval
 * @param promise
 * @param id
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|AxiosPromise<any>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>, void, *>}
 */
export function* deleteProjectApproval({ promise, id }) {
    try {
        const approvalAPI = yield restClient.delete(`api/approvals/${id}`);
        yield put({
            type: projectApprovalsActions.DELETING_SINGLE_PROJECT_APPROVAL_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: approvalAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectApprovalsActions.DELETING_SINGLE_PROJECT_APPROVAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Fetch private approval
 * @param promise
 * @param hash
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|AxiosPromise<any>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>, void, *>}
 */
export function* fetchPrivateApproval({ promise, hash }) {
    try {
        const getTokenAPI = yield restClient.get(`approvals/public-token/${hash}`);
        // eslint-disable-next-line camelcase
        const token = getTokenAPI?.data?.detail?.access_token;
        const getDataAPI = yield restClient.get(`approvals/info/${hash}/${token}`, {
            params: {
                include:
                    'contact,contract,company,file,workflowTransitionLogs,createdBy,signature,assignedTo'
            }
        });
        const approvalsData = dto(getDataAPI?.data)?.data;
        yield put({
            type: projectApprovalsActions.PRIVATE_PROJECT_APPROVAL_FETCH_SUCCESS,
            response: {
                token,
                data: approvalsData
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectApprovalsActions.PRIVATE_PROJECT_APPROVAL_FETCH_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Approve
 * @param promise
 * @param formData
 * @param hash
 * @param token
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|AxiosPromise<any>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>, void, *>}
 */
export function* projectApprovalSubmit({ promise, formData, hash, token }) {
    try {
        const apiData = yield restClient.post(`approvals/approve/${hash}/${token}`, formData, {
            headers: { 'content-type': 'application/vnd.api+json' }
        });
        yield put({
            type: projectApprovalsActions.PROJECT_APPROVAL_APPROVE_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: apiData?.data
        });
        yield call(promise.resolve, apiData?.data);
    } catch (error) {
        yield put({
            type: projectApprovalsActions.PROJECT_APPROVAL_APPROVE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Change request
 * @param promise
 * @param formData
 * @param hash
 * @param token
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|AxiosPromise<any>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>, void, *>}
 */
export function* projectApprovalChangeRequest({ promise, formData, hash, token }) {
    try {
        const apiData = yield restClient.post(
            `approvals/change-request/${hash}/${token}`,
            formData
        );
        yield put({
            type: projectApprovalsActions.PROJECT_APPROVAL_CHANGE_REQUEST_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: apiData?.data
        });
        yield call(promise.resolve, apiData?.data);
    } catch (error) {
        yield put({
            type: projectApprovalsActions.PROJECT_APPROVAL_CHANGE_REQUEST_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export default [
    takeLatest(projectApprovalsActions.START_CREATE_PROJECT_APPROVAL, createProjectApproval),
    takeLatest(projectApprovalsActions.START_FETCHING_SINGLE_PROJECT_APPROVAL, fetchApproval),
    takeLatest(
        projectApprovalsActions.START_UPDATING_SINGLE_PROJECT_APPROVAL,
        updateProjectApproval
    ),
    takeLatest(
        projectApprovalsActions.START_DELETING_SINGLE_PROJECT_APPROVAL,
        deleteProjectApproval
    ),
    takeLatest(
        projectApprovalsActions.START_FETCHING_PRIVATE_PROJECT_APPROVAL,
        fetchPrivateApproval
    ),
    takeLatest(projectApprovalsActions.START_APPROVE_PROJECT_APPROVAL, projectApprovalSubmit),
    takeLatest(
        projectApprovalsActions.START_UPDATING_PROJECT_APPROVAL_CHANGE_REQUEST,
        projectApprovalChangeRequest
    )
];
