import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

// Notifications
import getDefaultNotifications from './notifications/default';
import getAuthNotifications from './notifications/auth';
import getListingNotifications from './notifications/listing';
import getMyAccountNotifications from './notifications/myAccount';
import getAccountingNotifications from './notifications/accounting';
import getCompanyNotifications from './notifications/company';
import getContactsNotifications from './notifications/contacts';
import getContractsNotifications from './notifications/contracts';
import getCurrencyNotifications from './notifications/currency';
import getDepartmentsNotifications from './notifications/departments';
import getDivisionsNotifications from './notifications/divisions';
import getEmploymentRecords from './notifications/employmentRecords';
import getEmployeeHourlyCost from './notifications/employeeHourlyCost';
import getJobTitlesNotifications from './notifications/jobTitles';
import getMediaNotifications from './notifications/media';
import getOfficesNotifications from './notifications/offices';
import getOptionsNotifications from './notifications/options';
import getOrganizationsNotifications from './notifications/organizations';
import getPaymentMethodsNotifications from './notifications/paymentMethods';
import getPayrollReportNotifications from './notifications/payrollReport';
import getPaymentTermsNotifications from './notifications/paymentTerms';
import getProjectsNotifications from './notifications/projects';
import getProjectMilestonesNotifications from './notifications/projectMilestones';
import getContractMilestonesNotifications from './notifications/contractMilestones';
import getProjectFeaturesNotifications from './notifications/projectFeatures';
import getProjectApprovalsNotifications from './notifications/projectApprovals';
import getPropsNotifications from './notifications/props';
import getProjectStages from './notifications/projectStages';
import getRolesAndPermissionsNotifications from './notifications/rolesAndPermissions';
import getSalesOrdersNotifications from './notifications/salesOrders';
import getServicesNotifications from './notifications/services';
import getTimeLogsNotifications from './notifications/timeLogs';
import getTimeOffNotifications from './notifications/timeOff';
import getUsersNotifications from './notifications/users';
import getWorkTypesNotifications from './notifications/workTypes';
import getTimeOffTypesNotifications from './notifications/timeOffTypes';
import getTagsNotifications from './notifications/tags';
import getTimelineNotifications from './notifications/contractStages';
import getScheduledCommandsNotifications from './notifications/scheduledCommands';
import getDashboardsNotifications from './notifications/dashboards';
import getDeleteEntityNotifications from './notifications/deleteEntity';
import getCommentsNotifications from './notifications/comments';
import getFreshbooksNotifications from './notifications/freshbooks';
import getBundleTemplatesNotifications from './notifications/bundleTemplates';
import getContractBudgetsNotifications from './notifications/contractBudgets';

/**
 * Notification Manager
 * @return {Node} Returns Notification component
 */
const NotificationManager = ({ code, identifier, detail, notificationData }) => {
    const forwardData = { code, identifier, detail, notificationData };

    // Default
    const defaultNotifications = getDefaultNotifications(forwardData);
    if (defaultNotifications) {
        return defaultNotifications;
    }

    // Auth
    const authNotifications = getAuthNotifications(forwardData);
    if (authNotifications) {
        return authNotifications;
    }

    // Listing
    const listingNotifications = getListingNotifications(forwardData);
    if (listingNotifications) {
        return listingNotifications;
    }

    // MyAccount
    const myAccountNotifications = getMyAccountNotifications(forwardData);
    if (myAccountNotifications) {
        return myAccountNotifications;
    }

    // Users
    const usersNotifications = getUsersNotifications(forwardData);
    if (usersNotifications) {
        return usersNotifications;
    }

    // Employment Records
    const employmentRecords = getEmploymentRecords(forwardData);
    if (employmentRecords) {
        return employmentRecords;
    }

    // Employment Costs
    const employeeHourlyCosts = getEmployeeHourlyCost(forwardData);
    if (employeeHourlyCosts) {
        return employeeHourlyCosts;
    }

    // Departments
    const departmentsNotifications = getDepartmentsNotifications(forwardData);
    if (departmentsNotifications) {
        return departmentsNotifications;
    }

    // Divisions
    const divisionsNotifications = getDivisionsNotifications(forwardData);
    if (divisionsNotifications) {
        return divisionsNotifications;
    }

    // Tags
    const tagsNotifications = getTagsNotifications(forwardData);
    if (tagsNotifications) {
        return tagsNotifications;
    }

    // Timeline
    const timelineNotifications = getTimelineNotifications(forwardData);
    if (timelineNotifications) {
        return timelineNotifications;
    }

    // WorkTypes
    const workTypesNotifications = getWorkTypesNotifications(forwardData);
    if (workTypesNotifications) {
        return workTypesNotifications;
    }

    // TimeOffTypes
    const timeOffTypesNotifications = getTimeOffTypesNotifications(forwardData);
    if (timeOffTypesNotifications) {
        return timeOffTypesNotifications;
    }

    // JobTitles
    const jobTitlesNotifications = getJobTitlesNotifications(forwardData);
    if (jobTitlesNotifications) {
        return jobTitlesNotifications;
    }

    // Media
    const mediaNotifications = getMediaNotifications(forwardData);
    if (mediaNotifications) {
        return mediaNotifications;
    }

    // Offices
    const officesNotifications = getOfficesNotifications(forwardData);
    if (officesNotifications) {
        return officesNotifications;
    }

    // Options
    const optionsNotifications = getOptionsNotifications(forwardData);
    if (optionsNotifications) {
        return optionsNotifications;
    }

    // Organizations
    const organizationsNotifications = getOrganizationsNotifications(forwardData);
    if (organizationsNotifications) {
        return organizationsNotifications;
    }

    // Roles and Permissions
    const rolesAndPermissionsNotifications = getRolesAndPermissionsNotifications(forwardData);
    if (rolesAndPermissionsNotifications) {
        return rolesAndPermissionsNotifications;
    }

    // Scheduled Commands
    const scheduledCommandsNotifications = getScheduledCommandsNotifications(forwardData);
    if (scheduledCommandsNotifications) {
        return scheduledCommandsNotifications;
    }

    // Time logs
    const timeLogsNotifications = getTimeLogsNotifications(forwardData);
    if (timeLogsNotifications) {
        return timeLogsNotifications;
    }

    // Time Off
    const timeOffNotifications = getTimeOffNotifications(forwardData);
    if (timeOffNotifications) {
        return timeOffNotifications;
    }

    // Company
    const companysNotifications = getCompanyNotifications(forwardData);
    if (companysNotifications) {
        return companysNotifications;
    }

    // Contacts
    const contactsNotifications = getContactsNotifications(forwardData);
    if (contactsNotifications) {
        return contactsNotifications;
    }

    // Currency
    const currencyNotifications = getCurrencyNotifications(forwardData);
    if (currencyNotifications) {
        return currencyNotifications;
    }

    // Projects
    const projectsNotifications = getProjectsNotifications(forwardData);
    if (projectsNotifications) {
        return projectsNotifications;
    }

    // Props
    const propsNotifications = getPropsNotifications(forwardData);
    if (propsNotifications) {
        return propsNotifications;
    }

    // Stages
    const projectStagesNotification = getProjectStages(forwardData);
    if (projectStagesNotification) {
        return projectStagesNotification;
    }

    // Services
    const servicesNotifications = getServicesNotifications(forwardData);
    if (servicesNotifications) {
        return servicesNotifications;
    }

    // Payroll Report
    const payrollReportNotifications = getPayrollReportNotifications(forwardData);
    if (payrollReportNotifications) {
        return payrollReportNotifications;
    }

    // Payment Terms
    const paymentTermsNotifications = getPaymentTermsNotifications(forwardData);
    if (paymentTermsNotifications) {
        return paymentTermsNotifications;
    }

    // Payment Methods
    const paymentMethodsNotifications = getPaymentMethodsNotifications(forwardData);
    if (paymentMethodsNotifications) {
        return paymentMethodsNotifications;
    }

    // Project features
    const projectFeaturesNotifications = getProjectFeaturesNotifications(forwardData);
    if (projectFeaturesNotifications) {
        return projectFeaturesNotifications;
    }

    // Project approvals
    const projectApprovalsNotifications = getProjectApprovalsNotifications(forwardData);
    if (projectApprovalsNotifications) {
        return projectApprovalsNotifications;
    }

    // Project milestones
    const projectMilestonesNotifications = getProjectMilestonesNotifications(forwardData);
    if (projectMilestonesNotifications) {
        return projectMilestonesNotifications;
    }

    // Project contract milestones
    const contractMilestonesNotifications = getContractMilestonesNotifications(forwardData);
    if (contractMilestonesNotifications) {
        return contractMilestonesNotifications;
    }

    // Contracts
    const contractsNotifications = getContractsNotifications(forwardData);
    if (contractsNotifications) {
        return contractsNotifications;
    }

    // Sales Orders
    const salesOrdersNotifications = getSalesOrdersNotifications(forwardData);
    if (salesOrdersNotifications) {
        return salesOrdersNotifications;
    }

    // Accounting
    const accountingNotifications = getAccountingNotifications(forwardData);
    if (accountingNotifications) {
        return accountingNotifications;
    }

    // Dashboards

    const dashboardsNotifications = getDashboardsNotifications(forwardData);
    if (dashboardsNotifications) {
        return dashboardsNotifications;
    }

    // Delete entity

    const deleteEntityNotifications = getDeleteEntityNotifications(forwardData);
    if (deleteEntityNotifications) {
        return deleteEntityNotifications;
    }

    // Comments

    const commentsNotifications = getCommentsNotifications(forwardData);
    if (commentsNotifications) {
        return commentsNotifications;
    }

    // Freshbooks
    const freshbooksNotifications = getFreshbooksNotifications(forwardData);
    if (freshbooksNotifications) {
        return freshbooksNotifications;
    }

    // Bundle templates

    const bundleTemplatesNotifications = getBundleTemplatesNotifications(forwardData);
    if (bundleTemplatesNotifications) {
        return bundleTemplatesNotifications;
    }

    // Contract budgets

    const contractBudgetsNotifications = getContractBudgetsNotifications(forwardData);
    if (contractBudgetsNotifications) {
        return contractBudgetsNotifications;
    }

    return (
        <Notification
            identifier={identifier}
            title="Warning"
            text={
                <Fragment>
                    <strong>{code}</strong> - This is the message missing Notification case!
                    {!!detail && (
                        <Fragment>
                            <br />
                            {detail}
                        </Fragment>
                    )}
                </Fragment>
            }
            type="warning"
        />
    );
};

NotificationManager.defaultProps = {
    code: null,
    identifier: null,
    detail: null,
    notificationData: null
};

NotificationManager.propTypes = {
    code: PropTypes.string,
    identifier: PropTypes.string,
    detail: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    notificationData: PropTypes.oneOfType([PropTypes.object])
};

export default NotificationManager;
