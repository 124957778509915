import restClient from 'erpcore/api/restClient';
import { call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import { actions as timeTrackingActions } from 'erpcore/components/TimeTracking/TimeTracking.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import orderBy from 'lodash/orderBy';
import {
    getDraftTimelogs,
    getSubmittedTimelogs
} from 'erpcore/components/TimeTracking/TimeTracking.selectors';

/**
 * Create Draft timelog
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createDraftTimelog({ promise, formData }) {
    try {
        const timelogDraftAPI = yield restClient.post(
            `/api/time-logs?include=projectContract,projectContract.stages,workType,stage&sync_method=BACKGROUND`,
            formData
        );
        const timeLogDraftAPIDTO = dto(timelogDraftAPI?.data, {
            listOfNoIncludePropertyNames: ['affects', 'depends_on']
        })?.data;

        yield put({
            type: timeTrackingActions.CREATE_DRAFT_TIMELOG_SUCCESSFUL,
            response: timeLogDraftAPIDTO
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: timelogDraftAPI?.data
        });
        yield call(promise.resolve, timelogDraftAPI?.data);
    } catch (error) {
        yield put({
            type: timeTrackingActions.CREATE_DRAFT_TIMELOG_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* startUpdateAvailableTime({ iri }) {
    try {
        const updatedItems = yield restClient.get(`${iri}?include=timeAvailable`);

        yield put({
            type: timeTrackingActions.UPDATE_AVAILABLE_TIME_SUCCESSFUL,
            response: dto(updatedItems?.data, {
                listOfNoIncludePropertyNames: ['affects', 'depends_on']
            })?.data
        });
    } catch (error) {
        yield put({
            type: timeTrackingActions.UPDATE_AVAILABLE_TIME_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
    }
}

/**
 * Fetch Draft timelogs
 * @return {Object} Response from API
 */
export function* fetchDraftTimelogs({ promise }) {
    try {
        const meData = yield select(getMeData);
        const draftTimelogsAPI = yield restClient.get(
            `/api/time-logs?include=projectContract,projectContract.stages,workType,stage&filters[status][in][]=draft&filters[status][in][]=paused&filters[created_by][equals]=${meData?.iri}&pagination=false&order_by[id]=DESC`
        );
        const draftTimelogsAPIDTO = dto(draftTimelogsAPI?.data, {
            listOfNoIncludePropertyNames: ['affects', 'depends_on']
        })?.data;

        yield put({
            type: timeTrackingActions.FETCHING_DRAFT_TIMELOGS_SUCCESSFUL,
            response: draftTimelogsAPIDTO
        });

        if (promise) yield call(promise.resolve, draftTimelogsAPI?.data);
    } catch (error) {
        yield put({
            type: timeTrackingActions.FETCHING_DRAFT_TIMELOGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        if (promise) yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Submitted timelogs
 * @return {Object} Response from API
 */
export function* fetchSubmittedTimelogs({ promise, page = 1 }) {
    try {
        const meData = yield select(getMeData);
        const submittedTimelogsAPI = yield restClient.get(
            `/api/time-logs?include=projectContract,projectContract.stages,workType,stage&filters[status][in][]=completed&filters[created_by][equals]=${meData?.iri}&order_by[date]=DESC&page=${page}&limit=10`
        );
        const submittedTimelogsAPIDTO = dto(submittedTimelogsAPI?.data, {
            listOfNoIncludePropertyNames: ['affects', 'depends_on']
        });

        const apiDto = {
            data: orderBy(submittedTimelogsAPIDTO.data, ['date', 'updated_at'], ['desc', 'desc']),
            meta: submittedTimelogsAPIDTO?.meta,
            links: submittedTimelogsAPIDTO.links
        };

        yield put({
            type: timeTrackingActions.FETCHING_SUBMITTED_TIMELOGS_SUCCESSFUL,
            response: apiDto.data,
            meta: apiDto.meta
        });

        if (promise) yield call(promise.resolve, apiDto);
    } catch (error) {
        yield put({
            type: timeTrackingActions.FETCHING_SUBMITTED_TIMELOGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        if (promise) yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Updates timelog
 * @return {Object} Response from API
 */
export function* updateTimelog({ promise, formData, iri, method = 'INSTANT' }) {
    try {
        const response = yield restClient.put(
            `${iri}?include=projectContract,projectContract.stages,workType,stage&sync_method=${method}`,
            formData
        );
        const responseDTO = dto(response?.data, {
            listOfNoIncludePropertyNames: ['affects', 'depends_on']
        })?.data;

        yield put({
            type: timeTrackingActions.UPDATE_SUCCESSFUL_TIMELOG,
            response: responseDTO
        });

        if (responseDTO?.stage) {
            yield call(startUpdateAvailableTime, { iri: responseDTO?.stage?.iri });
        }

        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: response.data
        });

        yield call(promise.resolve, responseDTO);
    } catch (error) {
        yield put({
            type: timeTrackingActions.UPDATE_FAILED_TIMELOG
        });

        if (error?.response?.data?.code !== 'timelogs.notEnoughAvailableTime') {
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: error?.response?.data || error
            });
        }

        yield call(promise.resolve, error?.response?.data || error);
    }
}

/**
 * Submit draft timelog
 * @return {Object} Response from API
 */
export function* submitDraftTimelog({ promise, iri, formData }) {
    // Used to check for case when the user tries to submit time log with a non-existent / completed stage
    function processResponseError(errorData) {
        const errorCode = 'timelog.stage.invalidTimeLogConstraint';

        const nonExistentOrCompletedStageSubmittedError = errorData?.errors?.find(
            error => error.code === errorCode
        );

        if (!nonExistentOrCompletedStageSubmittedError) {
            return errorData;
        }

        return {
            code: errorCode,
            detail: nonExistentOrCompletedStageSubmittedError.message
        };
    }

    try {
        const response = yield restClient.put(
            `${iri}?include=projectContract,projectContract.stages,stage,workType&sync_method=INSTANT`,

            formData
        );
        const responseDTO = dto(response?.data, {
            listOfNoIncludePropertyNames: ['affects', 'depends_on']
        })?.data;

        yield put({
            type: timeTrackingActions.SUBMIT_DRAFT_TIMELOG_SUCCESSFUL,
            iri,
            response: responseDTO
        });

        yield call(promise.resolve, responseDTO);
    } catch (error) {
        yield put({
            type: timeTrackingActions.SUBMIT_DRAFT_TIMELOG_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: processResponseError(error?.response?.data || error) || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete timelog
 * @return {Object} Response from API
 */
export function* deleteDraftTimelog({ promise, iri }) {
    try {
        const draftLogs = yield select(getDraftTimelogs);
        const deleteItem = draftLogs?.find(item => item?.iri === iri);

        const deleteTimeLogAPI = yield restClient.delete(`${iri}?confirmDelete=DELETE`);

        yield put({
            type: timeTrackingActions.DELETE_DRAFT_TIMELOG_SUCCESSFUL,
            iri
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteTimeLogAPI?.data
        });

        if (deleteItem?.stage)
            yield call(startUpdateAvailableTime, { iri: deleteItem?.stage?.iri });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: timeTrackingActions.DELETE_DRAFT_TIMELOG_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete submitted timelog
 * @return {Object} Response from API
 */
export function* deleteSubmittedTimelog({ promise, iri }) {
    try {
        const submittedLogs = yield select(getSubmittedTimelogs);
        const deleteItem = submittedLogs?.find(item => item?.iri === iri);

        const deleteTimeLogAPI = yield restClient.delete(`${iri}?confirmDelete=DELETE`);
        yield put({
            type: timeTrackingActions.DELETE_SUBMITTED_TIMELOG_SUCCESSFUL,
            iri
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteTimeLogAPI?.data
        });

        if (deleteItem?.stage)
            yield call(startUpdateAvailableTime, { iri: deleteItem?.stage?.iri });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: timeTrackingActions.DELETE_SUBMITTED_TIMELOG_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const timeTrackingSaga = [
    takeEvery(timeTrackingActions.START_CREATE_DRAFT_TIMELOG, createDraftTimelog),
    takeLatest(timeTrackingActions.START_FETCHING_DRAFT_TIMELOGS, fetchDraftTimelogs),
    takeLatest(timeTrackingActions.START_FETCHING_SUBMITTED_TIMELOGS, fetchSubmittedTimelogs),
    takeEvery(timeTrackingActions.START_UPDATE_TIMELOG, updateTimelog),
    takeEvery(timeTrackingActions.START_DELETE_DRAFT_TIMELOG, deleteDraftTimelog),
    takeEvery(timeTrackingActions.START_DELETE_SUBMITTED_TIMELOG, deleteSubmittedTimelog),
    takeEvery(timeTrackingActions.START_SUBMIT_DRAFT_TIMELOG, submitDraftTimelog),
    takeEvery(timeTrackingActions.START_UPDATE_AVAILABLE_TIME, startUpdateAvailableTime)
];
