import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import clientLayout from 'erpcore/utils/LayoutManager/clientLayout';
import LayoutManager from 'erpcore/utils/LayoutManager';
import ClientAccount from 'erpcore/screens/Client/screens/Account';
import ProjectApprovalsPrivate from 'erpcore/screens/Projects/screens/ProjectApprovals/screens/ProjectApprovalsPrivate';
import ClientError404 from 'erpcore/screens/Client/screens/Error404';
import SuspenseLoader from 'erpcore/components/SuspenseLoader';

const ClientDashboard = React.lazy(() => import('erpcore/screens/Client/screens/Dashboard'));
const ClientProjects = React.lazy(() => import('erpcore/screens/Client/screens/Projects'));
const ClientProjectDashboard = React.lazy(() =>
    import('erpcore/screens/Client/screens/ProjectDashboard')
);
const ClientApprovals = React.lazy(() => import('erpcore/screens/Client/screens/Approvals'));
const ClientInvoices = React.lazy(() => import('erpcore/screens/Client/screens/Invoices'));
const ClientProjectContractDashboard = React.lazy(() =>
    import('erpcore/screens/Client/screens/ProjectContractDashboard')
);

const ClientRoutes = () => {
    return (
        <LayoutManager layout={clientLayout} slot="main" layoutType="merge">
            <Suspense fallback={<SuspenseLoader />}>
                <Routes>
                    <Route
                        key="ClientAccount"
                        path="/client/account"
                        exact
                        element={<ClientAccount />}
                    />
                    <Route
                        key="ClientDashboard"
                        path="/client/dashboard"
                        exact
                        element={<ClientDashboard />}
                    />
                    <Route
                        key="ClientDashboard"
                        path="/client/dashboard/:id"
                        exact
                        element={<ClientDashboard />}
                    />
                    <Route
                        key="ClientProjects"
                        path="/client/projects"
                        exact
                        element={<ClientProjects />}
                    />
                    <Route
                        key="ClientProjectDashboard"
                        path="/client/projects/:id/dashboard"
                        exact
                        element={<ClientProjectDashboard />}
                    />
                    <Route
                        key="ClientApprovals"
                        path="/client/approvals"
                        exact
                        element={<ClientApprovals />}
                    />
                    <Route
                        key="ClientInvoices"
                        path="/client/invoices"
                        exact
                        element={<ClientInvoices />}
                    />
                    <Route
                        key="ClientProjectContractDashboard"
                        path="/client/projects/:projectId/contracts/:id/dashboard"
                        exact
                        element={<ClientProjectContractDashboard />}
                    />
                    <Route exact path="/approvals/:hash" element={<ProjectApprovalsPrivate />} />
                    <Route exact path="/" element={<Navigate to="/client/dashboard" />} />
                    <Route exact path="/dashboard" element={<Navigate to="/client/dashboard" />} />
                    <Route element={<ClientError404 />} />
                </Routes>
            </Suspense>
        </LayoutManager>
    );
};

ClientRoutes.defaultProps = {};

ClientRoutes.propTypes = {};

export default ClientRoutes;
