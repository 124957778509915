import restClient from 'erpcore/api/restClient';
import { call, put, takeLatest } from 'redux-saga/effects';
import { actions as expensesActions } from 'erpcore/screens/Accounting/screens/Expenses/Expenses.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';

/**
 * Delete Single Expense
 * @param {string} iri - Iri of expense
 * @param {object} promise - promise
 * @return {Object} Response from API
 */
export function* deleteSingleExpense({ promise, iri }) {
    try {
        const deleteSingleExpenseAPI = yield restClient.delete(iri);
        yield put({
            type: expensesActions.DELETE_EXPENSE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleExpenseAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: expensesActions.DELETE_EXPENSE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Single Expense
 * @param  {object} promise - promise
 * @param  {object} formData - formData
 * @return {Object} Response from API
 */
export function* createSingleExpense({ promise, formData }) {
    try {
        const currency = yield restClient.get(formData.currency);
        const currencyDto = dto(currency?.data);

        formData = {
            ...formData,
            amount: {
                amount: formData?.total,
                currency: currencyDto?.data?.code
            },
            tax_amount: {
                amount: formData?.tax,
                currency: currencyDto?.data?.code
            },
            allocation: {
                department: formData?.department,
                contract: formData?.contract
            }
        };

        delete formData?.total;
        delete formData?.currency;
        delete formData?.tax;

        const createSingleExpenseAPI = yield restClient.post('/api/accounting/expenses', formData);
        yield put({
            type: expensesActions.CREATE_EXPENSE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: dto(createSingleExpenseAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: expensesActions.CREATE_EXPENSE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Single Expense
 * @param  {object} promise - promise
 * @param  {string} iri - expense iri
 * @return {Object} Response from API
 */
export function* fetchSingleExpense({ promise, iri, params }) {
    try {
        const fetchSingleExpenseAPI = yield restClient.get(iri, { params });
        const fetchSingleExpenseAPIDto = dto(fetchSingleExpenseAPI?.data);

        const currencyData = yield restClient.get(
            `/api/currencies?filters[code][equals]=${fetchSingleExpenseAPIDto?.data?.amount?.currency}`
        );
        const currencyDto = dto(currencyData.data);

        fetchSingleExpenseAPIDto.data = {
            ...fetchSingleExpenseAPIDto.data,
            amount: {
                ...fetchSingleExpenseAPIDto.data.amount,
                currency: currencyDto?.data[0]?.iri
            },
            tax_amount: {
                ...fetchSingleExpenseAPIDto.data.tax_amount,
                currency: currencyDto?.data[0]?.iri
            }
        };

        yield put({
            type: expensesActions.FETCHING_SINGLE_EXPENSE_SUCCESSFUL
        });
        yield put({
            type: expensesActions.STORE_SINGLE_EXPENSE_DATA,
            response: fetchSingleExpenseAPIDto,
            iri
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: expensesActions.FETCHING_SINGLE_EXPENSE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Single Expense
 * @param  {object} promise - promise
 * @param  {object} formData - formData
 * @param  {string} iri - Expense iri
 * @return {Object} Response from API
 */
export function* updateSingleExpense({ promise, formData, iri }) {
    try {
        if (formData.currency) {
            const currency = yield restClient.get(formData.currency);
            const currencyDto = dto(currency?.data);

            formData = {
                ...formData,
                amount: {
                    amount: formData?.total,
                    currency: currencyDto?.data?.code
                },
                tax_amount: {
                    amount: formData?.tax,
                    currency: currencyDto?.data?.code
                }
            };

            delete formData?.total;
            delete formData?.currency;
            delete formData?.tax;
        }

        const updateSingleExpenseAPI = yield restClient.patch(iri, formData);
        yield put({
            type: expensesActions.UPDATE_EXPENSE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: dto(updateSingleExpenseAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: expensesActions.UPDATE_EXPENSE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export default [
    takeLatest(expensesActions.START_DELETE_EXPENSE, deleteSingleExpense),
    takeLatest(expensesActions.START_CREATE_EXPENSE, createSingleExpense),
    takeLatest(expensesActions.START_FETCHING_SINGLE_EXPENSE, fetchSingleExpense),
    takeLatest(expensesActions.START_UPDATE_EXPENSE, updateSingleExpense)
];
