import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import Input from '../Input';

/**
 * Currency Component's local state amount, redux state and input value are integers (number without decimals, value expressed in cents)
 * Value passed to NumberFormat is float, amount divided by 100
 */
const Currency = ({ input, suffix, prefix, fieldAttr, fieldProps, field, meta }) => {
    const { name, value, onChange, onBlur } = { ...input };

    const convertToCents = float => {
        // multiply by 100, expect weird decimals (because js binary), convert to string integer, convert to int
        return float || float === 0
            ? // multiplying by 100 (extra code is for weird JS decimals ie. 2500.01 * 100 -> 250001.00000000003)
              parseInt((float * 100).toFixed(), 10)
            : null;
    };

    /**
     * @param values {Object}
     * @param values.formattedValue {string} Value after applying formatting
     * @param values.value {string} Non formatted value as numeric string
     * @param values.floatValue {Number} Floating point representation
     */
    const handleValueChange = values => {
        return onChange(convertToCents(values?.floatValue));
    };

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
        >
            <NumberFormat
                name={name}
                {...fieldAttr}
                className="input__field"
                allowEmptyFormatting={false}
                value={value === null || value === '' ? null : value / 100}
                suffix={suffix}
                prefix={prefix}
                onValueChange={values => handleValueChange(values)}
                isNumericString={false}
                thousandSeparator
                decimalSeparator="."
                fixedDecimalScale
                decimalScale={2}
                onBlur={() => onBlur()}
            />
        </Input>
    );
};

Currency.defaultProps = {
    value: '',
    prefix: '', // do not set this to null
    suffix: '', // do not set this to null
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

Currency.propTypes = {
    value: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default Currency;
