export const actions = {
    START_FETCHING_DASHBOARDS: 'START_FETCHING_DASHBOARDS',
    FETCHING_SUCCESSFUL_DASHBOARDS: 'FETCHING_SUCCESSFUL_DASHBOARDS',
    FETCHING_FAILED_DASHBOARDS: 'FETCHING_FAILED_DASHBOARDS',
    STORE_DASHBOARDS_DATA: 'STORE_DASHBOARDS_DATA',

    START_UPDATE_DASHBOARD: 'START_UPDATE_DASHBOARD',
    UPDATE_DASHBOARD_SUCCESSFUL: 'UPDATE_DASHBOARD_SUCCESSFUL',
    UPDATE_DASHBOARD_FAILED: 'UPDATE_DASHBOARD_FAILED',
    STORE_DASHBOARD_DATA: 'STORE_DASHBOARD_DATA',

    ADD_NEW_WIDGET: 'ADD_NEW_WIDGET',
    REMOVE_NEW_WIDGET: 'REMOVE_NEW_WIDGET',

    ADD_WIDGET_TO_DASHBOARD: 'ADD_WIDGET_TO_DASHBOARD',
    REMOVE_WIDGET_FROM_DASHBOARD: 'REMOVE_WIDGET_FROM_DASHBOARD',

    UPDATE_INITIAL_DASHBOARD: 'UPDATE_INITIAL_DASHBOARD',
    REMOVE_WIDGET_FROM_INITIAL_DASHBOARD: 'REMOVE_WIDGET_FROM_INITIAL_DASHBOARD',

    START_CREATE_DASHBOARD: 'START_CREATE_DASHBOARD',
    CREATE_DASHBOARD_SUCCESSFUL: 'CREATE_DASHBOARD_SUCCESSFUL',
    CREATE_DASHBOARD_FAILED: 'CREATE_DASHBOARD_FAILED',

    SET_CURRENT_DASHBOARD: 'SET_CURRENT_DASHBOARD',

    STORE_DATA_OF_CREATED_DASHBOARD: 'STORE_DATA_OF_CREATED_DASHBOARD',

    SET_DASHBOARD_AS_FAVORITE: 'SET_DASHBOARD_AS_FAVORITE',
    SET_DASHBOARD_AS_FAVORITE_SUCCESSFUL: 'SET_DASHBOARD_AS_FAVORITE_SUCCESSFUL',
    SET_DASHBOARD_AS_FAVORITE_FAILED: 'SET_DASHBOARD_AS_FAVORITE_FAILED',

    UPDATE_DASHBOARD_DATA: 'UPDATE_DASHBOARD_DATA',

    SET_ACTIVE_DASHBOARD_TO_USER: 'SET_ACTIVE_DASHBOARD_TO_USER',

    REMOVE_CURRENTLY_EDITING_USER_FROM_DASHBOARD: 'REMOVE_CURRENTLY_EDITING_USER_FROM_DASHBOARD'
};

export const initialState = {
    fetching: false,
    dashboardUpdating: false,
    error: null,
    newWidget: null,
    listOfDashboards: {},
    initialDashboard: { config: { widgets: {} } }
};

export default (state = initialState, { type, response, iri, widgetId }) => {
    switch (type) {
        case actions.START_FETCHING_DASHBOARDS: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_DASHBOARDS: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.FETCHING_FAILED_DASHBOARDS: {
            return {
                ...state,
                fetching: false,
                error: response
            };
        }
        case actions.START_UPDATE_DASHBOARD:
        case actions.SET_DASHBOARD_AS_FAVORITE: {
            return {
                ...state,
                dashboardUpdating: true
            };
        }
        case actions.UPDATE_DASHBOARD_SUCCESSFUL:
        case actions.SET_DASHBOARD_AS_FAVORITE_SUCCESSFUL: {
            return {
                ...state,
                dashboardUpdating: false
            };
        }
        case actions.UPDATE_DASHBOARD_FAILED:
        case actions.SET_DASHBOARD_AS_FAVORITE_FAILED: {
            return {
                ...state,
                dashboardUpdating: false,
                error: response
            };
        }
        case actions.STORE_DASHBOARDS_DATA: {
            const dashboards = {};

            response.data.forEach(dashboard => {
                dashboards[dashboard.iri] = dashboard;
            });

            return { ...state, listOfDashboards: dashboards, dashboardsMeta: response.meta };
        }
        case actions.STORE_DASHBOARD_DATA: {
            return {
                ...state,
                listOfDashboards: {
                    ...state.listOfDashboards,
                    [response.iri]: { ...response }
                }
            };
        }
        case actions.STORE_DATA_OF_CREATED_DASHBOARD: {
            return {
                ...state,
                listOfDashboards: {
                    ...state.listOfDashboards,
                    [response.data.iri]: {
                        ...response.data
                    }
                }
            };
        }
        case actions.ADD_NEW_WIDGET: {
            return {
                ...state,
                newWidget: response
            };
        }
        case actions.REMOVE_NEW_WIDGET: {
            return {
                ...state,
                newWidget: null
            };
        }
        case actions.ADD_WIDGET_TO_DASHBOARD: {
            return {
                ...state,
                listOfDashboards: {
                    ...state.listOfDashboards,
                    [iri]: {
                        ...state.listOfDashboards?.[iri],
                        config: {
                            ...state.listOfDashboards?.[iri]?.config,
                            widgets: {
                                ...state.listOfDashboards?.[iri]?.config?.widgets,
                                ...response
                            }
                        }
                    }
                }
            };
        }
        case actions.UPDATE_INITIAL_DASHBOARD: {
            return {
                ...state,
                initialDashboard: {
                    ...state.initialDashboard,
                    ...response
                }
            };
        }
        case actions.REMOVE_WIDGET_FROM_INITIAL_DASHBOARD: {
            const updatedWidgets = { ...state.initialDashboard?.config?.widgets };
            delete updatedWidgets[widgetId];

            return {
                ...state,
                initialDashboard: {
                    ...state?.initialDashboard,
                    config: {
                        ...state?.initialDashboard?.config,
                        widgets: {
                            ...updatedWidgets
                        }
                    }
                }
            };
        }
        case actions.REMOVE_WIDGET_FROM_DASHBOARD: {
            const updatedWidgets = { ...state.listOfDashboards?.[iri]?.config?.widgets };
            delete updatedWidgets[widgetId];

            return {
                ...state,
                listOfDashboards: {
                    ...state.listOfDashboards,
                    [iri]: {
                        ...state.listOfDashboards?.[iri],
                        config: {
                            ...state.listOfDashboards?.[iri]?.config,
                            widgets: {
                                ...updatedWidgets
                            }
                        }
                    }
                }
            };
        }

        case actions.SET_CURRENT_DASHBOARD: {
            return { ...state, currentDashboard: iri };
        }

        case actions.UPDATE_DASHBOARD_DATA: {
            return {
                ...state,
                listOfDashboards: {
                    ...state.listOfDashboards,
                    [iri]: {
                        ...state.listOfDashboards?.[iri],
                        ...response
                    }
                }
            };
        }

        default:
            return state;
    }
};
