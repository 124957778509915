export const actions = {
    START_FETCHING_SALES_ORDERS: 'START_FETCHING_SALES_ORDERS',
    FETCHING_SUCCESSFUL_SALES_ORDERS: 'FETCHING_SUCCESSFUL_SALES_ORDERS',
    FETCHING_FAILED_SALES_ORDERS: 'FETCHING_FAILED_SALES_ORDERS',

    START_CREATE_SALES_ORDER: 'START_CREATE_SALES_ORDER',
    CREATE_SALES_ORDER_SUCCESSFUL: 'CREATE_SALES_ORDER_SUCCESSFUL',
    CREATE_SALES_ORDER_FAILED: 'CREATE_SALES_ORDER_FAILED',
    START_FETCHING_SALES_ORDER: 'START_FETCHING_SALES_ORDER',
    FETCH_SALES_ORDER_SUCCESSFUL: 'FETCH_SALES_ORDER_SUCCESSFUL',
    FETCH_SALES_ORDER_FAILED: 'FETCH_SALES_ORDER_FAILED',
    START_UPDATE_SALES_ORDER: 'START_UPDATE_SALES_ORDER',
    UPDATE_SALES_ORDER_SUCCESSFUL: 'UPDATE_SALES_ORDER_SUCCESSFUL',
    UPDATE_SALES_ORDER_FAILED: 'UPDATE_SALES_ORDER_FAILED',
    START_DELETE_SALES_ORDER: 'START_DELETE_SALES_ORDER',
    DELETE_SALES_ORDER_SUCCESSFUL: 'DELETE_SALES_ORDER_SUCCESSFUL',
    DELETE_SALES_ORDER_FAILED: 'DELETE_SALES_ORDER_FAILED',
    STORE_SALES_ORDER_DATA: 'STORE_SALES_ORDER_DATA',
    // Change Status
    START_SALES_ORDER_CHANGE_STATUS: 'START_SALES_ORDER_CHANGE_STATUS',
    SALES_ORDER_CHANGE_STATUS_SUCCESSFUL: 'SALES_ORDER_CHANGE_STATUS_SUCCESSFUL',
    SALES_ORDER_CHANGE_STATUS_FAILED: 'SALES_ORDER_CHANGE_STATUS_FAILED',
    // Time logs
    START_FETCHING_SALES_ORDER_TIME_LOGS: 'START_FETCHING_SALES_ORDER_TIME_LOGS',
    FETCHING_SUCCESSFUL_SALES_ORDER_TIME_LOGS: 'FETCHING_SUCCESSFUL_SALES_ORDER_TIME_LOGS',
    FETCHING_FAILED_SALES_ORDER_TIME_LOGS: 'FETCHING_FAILED_SALES_ORDER_TIME_LOGS',

    CLEAR_SALES_ORDER_STATE: 'CLEAR_SALES_ORDER_STATE',

    START_REFRESHING_TIMELOGS: 'START_REFRESHING_TIMELOGS',
    REFRESHING_TIMELOGS_SUCCESSFUL: 'REFRESHING_TIMELOGS_SUCCESSFUL',
    REFRESHING_TIMELOGS_FAILED: 'REFRESHING_TIMELOGS_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    refreshingTimelogs: false
};

export default (state = initialState, { type, iri, response }) => {
    switch (type) {
        case actions.START_FETCHING_SALES_ORDERS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_SALES_ORDERS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_SALES_ORDERS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_SALES_ORDER: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SALES_ORDER_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SALES_ORDER_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.UPDATE_SALES_ORDER_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.DELETE_USER_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }
        case actions.STORE_SALES_ORDER_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [iri]: response.data
            });
        }
        case actions.START_FETCHING_SALES_ORDER_TIME_LOGS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_SALES_ORDER_TIME_LOGS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_SALES_ORDER_TIME_LOGS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.CLEAR_SALES_ORDER_STATE: {
            return {
                ...initialState
            };
        }
        case actions.START_REFRESHING_TIMELOGS: {
            return {
                ...state,
                refreshingTimelogs: true
            };
        }
        case actions.REFRESHING_TIMELOGS_SUCCESSFUL: {
            return {
                ...state,
                refreshingTimelogs: false
            };
        }
        case actions.REFRESHING_TIMELOGS_FAILED: {
            return {
                ...state,
                refreshingTimelogs: false
            };
        }
        default:
            return state;
    }
};
