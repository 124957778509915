import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { parseParamsForApi } from 'erpcore/components/Listing/Listing.utils';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as projectDashboardActions } from './ProjectDashboard.reducer';

/**
 * Fetch Time Logs Report
 * @param  {Object} promise
 * @return {string}
 */
export function* fetchTimeLogsReport({ promise, params, by }) {
    try {
        const fetchTimeLogsReportAPI = yield restClient.get(`api/reports/time-logs`, {
            params,
            headers: { Accept: 'application/json' }
        });
        yield put({
            type: projectDashboardActions.FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_REPORT_TIME_LOGS,
            by
        });
        yield put({
            type: projectDashboardActions.STORE_REPORT_PROJECT_DASHBOARD_TIME_LOGS_DATA,
            response: fetchTimeLogsReportAPI?.data,
            by
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectDashboardActions.FETCHING_FAILED_PROJECT_DASHBOARD_REPORT_TIME_LOGS,
            by
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Project Contracts Listing Saga
 * @param  {Object} promise Resolve and reject promise
 * @param  {Object} params Set query params for listing request
 * @param  {String} endpoint Endpoint to wich saga points
 * @return {Object} Response from API
 */
export function* fetchProjectContractsListing({ promise, params, endpoint }) {
    try {
        const fetchListingAPI = yield restClient.get(endpoint, {
            params: parseParamsForApi(params)
        });
        yield put({
            type:
                projectDashboardActions.FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_PROJECT_CONTRACTS_LISTING,
            response: dto(fetchListingAPI?.data)
        });
        if (promise) {
            yield call(promise.resolve);
        }
    } catch (error) {
        yield put({
            type:
                projectDashboardActions.FETCHING_FAILED_PROJECT_DASHBOARD_PROJECT_CONTRACTS_LISTING,
            response: error?.response?.data || error
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        if (promise) {
            yield call(promise.reject, error?.response?.data || error);
        }
    }
}

/**
 * Fetch Sales Orders Listing Saga
 * @param  {Object} promise Resolve and reject promise
 * @param  {Object} params Set query params for listing request
 * @param  {String} endpoint Endpoint to wich saga points
 * @return {Object} Response from API
 */
export function* fetchSalesOrdersListing({ promise, params, endpoint }) {
    try {
        const fetchListingAPI = yield restClient.get(endpoint, {
            params: parseParamsForApi(params)
        });
        yield put({
            type:
                projectDashboardActions.FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_SALES_ORDERS_LISTING,
            response: dto(fetchListingAPI?.data)
        });
        if (promise) {
            yield call(promise.resolve);
        }
    } catch (error) {
        yield put({
            type: projectDashboardActions.FETCHING_FAILED_PROJECT_DASHBOARD_SALES_ORDERS_LISTING,
            response: error?.response?.data || error
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        if (promise) {
            yield call(promise.reject, error?.response?.data || error);
        }
    }
}

export function* fetchRevenuesListing({ promise, params, endpoint }) {
    try {
        const fetchListingAPI = yield restClient.get(endpoint, {
            params: parseParamsForApi(params)
        });
        yield put({
            type: projectDashboardActions.FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_REVENUES_LISTING,
            response: dto(fetchListingAPI?.data)
        });
        if (promise) {
            yield call(promise.resolve);
        }
    } catch (error) {
        yield put({
            type: projectDashboardActions.FETCHING_FAILED_PROJECT_DASHBOARD_REVENUES_LISTING,
            response: error?.response?.data || error
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        if (promise) {
            yield call(promise.reject, error?.response?.data || error);
        }
    }
}

export function* fetchExpensesListing({ promise, params, endpoint }) {
    try {
        const fetchListingAPI = yield restClient.get(endpoint, {
            params: parseParamsForApi(params)
        });
        yield put({
            type: projectDashboardActions.FETCHING_SUCCESSFUL_PROJECT_DASHBOARD_EXPENSES_LISTING,
            response: dto(fetchListingAPI?.data)
        });
        if (promise) {
            yield call(promise.resolve);
        }
    } catch (error) {
        yield put({
            type: projectDashboardActions.FETCHING_FAILED_PROJECT_DASHBOARD_EXPENSES_LISTING,
            response: error?.response?.data || error
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        if (promise) {
            yield call(promise.reject, error?.response?.data || error);
        }
    }
}

/**
 * Register action to watcher
 */
export default [
    takeEvery(
        projectDashboardActions.START_FETCHING_PROJECT_DASHBOARD_REPORT_TIME_LOGS,
        fetchTimeLogsReport
    ),
    takeLatest(
        projectDashboardActions.START_FETCHING_PROJECT_DASHBOARD_PROJECT_CONTRACTS_LISTING,
        fetchProjectContractsListing
    ),
    takeLatest(
        projectDashboardActions.START_FETCHING_PROJECT_DASHBOARD_SALES_ORDERS_LISTING,
        fetchSalesOrdersListing
    ),
    takeLatest(
        projectDashboardActions.START_FETCHING_PROJECT_DASHBOARD_REVENUES_LISTING,
        fetchRevenuesListing
    ),
    takeLatest(
        projectDashboardActions.START_FETCHING_PROJECT_DASHBOARD_EXPENSES_LISTING,
        fetchExpensesListing
    )
];
