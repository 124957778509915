import React from 'react';
import PropTypes from 'prop-types';
import { SelectNew } from 'erpcore/components/Form';

const AutocompleteMiddlewareToNewField = ({
    fieldProps,
    fieldAttr,
    field,
    input,
    meta,
    ...rest
}) => {
    return (
        <SelectNew
            {...rest}
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
            actionButton={fieldProps?.actionButton}
            apiData={fieldProps?.options}
            additionalOptions={fieldProps?.additionalOptions}
            isClearable={fieldAttr?.clearable === undefined ? true : fieldAttr?.clearable}
            isMulti={false}
        />
    );
};

AutocompleteMiddlewareToNewField.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};
AutocompleteMiddlewareToNewField.propTypes = {
    fieldProps: PropTypes.shape({
        endpoint: PropTypes.string,
        formatApiValue: PropTypes.func,
        params: PropTypes.object,
        defaultOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
        options: PropTypes.object,
        actionButton: PropTypes.object,
        forceLabelActive: PropTypes.bool,
        menuPlacement: PropTypes.string,
        additionalOptions: PropTypes.array
    }),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default AutocompleteMiddlewareToNewField;
