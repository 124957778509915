export const getDraftTimelogs = state => state?.timeTracking?.draftTimelogs || [];

export const getDraftTimelogsFetching = state =>
    state?.timeTracking?.fetchingDraftTimelogs || false;

export const getSubmittedTimelogs = state => state?.timeTracking?.submittedTimelogs || [];

export const getSubmittedTimelogsFetching = state =>
    state?.timeTracking?.fetchingSubmittedTimelogs || false;

export const getSubmittedTimelogsMeta = state => state?.timeTracking?.meta || null;
