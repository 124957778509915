import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import restClient from 'erpcore/api/restClient';
import Svg from 'erpcore/components/Svg';
import { operations } from 'erpcore/data/filterOperations';
import { getOptionByValue } from 'erpcore/components/Form/Form.utils';
import { generateKey } from 'erpcore/utils/utils';
import moment from 'moment-timezone';
import './FilterTags.scss';

const FilterTags = ({ onChangeFilterTag, queryParams, filterSchema }) => {
    const [apiLabels, setApiLabels] = useState([]);

    const getFilterSchemaData = (queryParamsItemKey) => {
        const filterSchemaItem = find(
            filterSchema,
            (item) => item.name === queryParamsItemKey.replace(/>/g, '.')
        );

        const props = filterSchemaItem?.filterFields?.value;

        const isSelectNew = (props?.component || '').toLowerCase() === 'selectnew';

        const checkIfHasAPIOptions = () => {
            if (isSelectNew) {
                return !!(props?.apiData?.endpoint && props?.apiData?.mapData);
            }

            if (!isSelectNew) {
                return !!(
                    props?.fieldProps?.options?.endpoint && props?.fieldProps?.options?.mapData
                );
            }

            return false;
        };

        const hasAPIOptions = checkIfHasAPIOptions();

        let mapData = false;
        if (hasAPIOptions) {
            mapData = isSelectNew ? props?.apiData?.mapData : props?.fieldProps?.options?.mapData;
        }

        const hasOptions = isSelectNew
            ? !!(props?.options && isArray(props?.options))
            : !!(props?.fieldProps?.options && isArray(props?.fieldProps?.options));

        let filterSchemaOptions = [];
        if (hasOptions) {
            filterSchemaOptions = props?.fieldProps?.options;
        }

        return {
            filterSchemaItem,
            hasAPIOptions,
            mapData,
            hasOptions,
            filterSchemaOptions,
            defaultOperator: filterSchemaItem?.defaultOperator,
            formatCurrency: props?.fieldProps?.formatCurrency || false
        };
    };

    useEffect(() => {
        map(queryParams.filter, (queryParamsItem, queryParamsItemKey) => {
            const filterSchemaData = getFilterSchemaData(queryParamsItemKey);
            if (filterSchemaData.hasAPIOptions) {
                map(queryParamsItem, (queryParamValue) => {
                    map(queryParamValue, (valueItem, key) => {
                        if (key !== 'operator') {
                            if (!isArray(valueItem)) valueItem = [valueItem];
                            map(valueItem, (valueItemItem) => {
                                if (getOptionByValue(valueItemItem, apiLabels)?.labelEqualsValue) {
                                    restClient
                                        .get(valueItemItem)
                                        .then((response) => {
                                            let labelTemplate = filterSchemaData.mapData?.label;
                                            const labelTemplateMatch =
                                                labelTemplate?.match(/[^{]+(?=})/g);
                                            if (labelTemplateMatch) {
                                                labelTemplateMatch.forEach((labelItem) => {
                                                    labelTemplate = labelTemplate.replace(
                                                        `{${labelItem}}`,
                                                        response?.data?.data?.attributes?.[
                                                            labelItem
                                                        ] || ''
                                                    );
                                                });
                                            }
                                            const apiLabel = labelTemplateMatch
                                                ? labelTemplate
                                                : response?.data?.data?.attributes?.[labelTemplate];
                                            setApiLabels((oldApiLabels) => [
                                                ...oldApiLabels,
                                                { value: valueItemItem, label: apiLabel }
                                            ]);
                                        })
                                        .catch((error) => error);
                                }
                            });
                        }
                    });
                });
            }
        });
    }, [queryParams]);

    const tags = [];
    map(queryParams.filter, (queryParamsItem, queryParamsItemKey) => {
        const filterSchemaData = getFilterSchemaData(queryParamsItemKey);

        map(queryParamsItem, (queryParamValue, index) => {
            const values = [];
            const delimiter = filterSchemaData?.filterSchemaItem?.defaultDelimiter;
            const operator = queryParamValue?.operator || filterSchemaData.defaultOperator;
            map(queryParamValue, (valueArray, key) => {
                if (key !== 'operator') {
                    if (!isArray(valueArray)) valueArray = [valueArray];
                    map(valueArray, (valueItem) => {
                        const value =
                            getOptionByValue(valueItem, [
                                ...apiLabels,
                                ...filterSchemaData.filterSchemaOptions
                            ])?.label || '...';

                        if (
                            filterSchemaData?.filterSchemaItem?.filterFields?.value?.fieldProps
                                ?.dateFormat
                        ) {
                            values.push(moment(value).format('M/D/YYYY'));
                        } else if (filterSchemaData?.formatCurrency) {
                            values.push((parseFloat(value, 10) / 100).toFixed(2));
                        } else {
                            values.push(value);
                        }
                    });
                }
            });
            tags.push({
                key: queryParamsItemKey,
                index,
                label: filterSchemaData?.filterSchemaItem?.label,
                operator: getOptionByValue(operator, operations)?.label || operator,
                value: delimiter ? values.join(` ${delimiter} `) : values.join(' or ')
            });
        });
    });

    const removeTag = (item) => {
        queryParams.filter[item.key].splice(item.index, 1);
        onChangeFilterTag(queryParams);
    };

    return (
        tags.length > 0 && (
            <ul className="filtertags">
                {map(tags, (item) => (
                    <li className="filtertags__item" key={generateKey(`${item.key}${item.index}`)}>
                        <span className="filtertags__label">
                            {item.label}
                            {item.operator && ` ${item.operator.toLowerCase()}`}
                            {item.value && ` ${item.value}`}
                        </span>
                        <button
                            type="button"
                            className="filtertags__btn"
                            key={`${item.type}${item.value}`}
                            onClick={() => removeTag(item)}
                        >
                            <Svg icon="close" />
                        </button>
                    </li>
                ))}
                <li className="filtertags__clear">
                    <button
                        type="button"
                        className="filtertags__btn-clear"
                        onClick={() => {
                            queryParams.filter = {};
                            onChangeFilterTag(queryParams);
                        }}
                    >
                        Clear All
                    </button>
                </li>
            </ul>
        )
    );
};

FilterTags.defaultProps = {
    fieldProps: {},
    options: {},
    onChangeFilterTag: () => {},
    queryParams: {},
    filterSchema: [],
    apiData: {},
    component: ''
};

FilterTags.propTypes = {
    fieldProps: PropTypes.shape({
        options: PropTypes.shape({
            endpoint: PropTypes.string,
            mapData: PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string
            })
        }),
        endpoint: PropTypes.string,
        formatCurrency: PropTypes.bool
    }),
    options: PropTypes.object,
    onChangeFilterTag: PropTypes.func,
    queryParams: PropTypes.oneOfType([PropTypes.object]),
    filterSchema: PropTypes.oneOfType([PropTypes.array]),
    apiData: PropTypes.shape({
        endpoint: PropTypes.string,
        mapData: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string
        })
    }),
    component: PropTypes.string
};

export default FilterTags;
