import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { getUserCurrentOrganization } from 'erpcore/utils/AuthManager/AuthManager.selectors';

import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as organizationActions } from './Organization.reducer';

const organizationParams = {
    include: 'currency,timezone,activeCapacitySchedule'
};

/**
 * Fetch Organization
 * @param  {Object} promise
 * @param {string} iri Organization iri
 */
export function* fetchOrganization({ promise, iri }) {
    try {
        const fetchOrganizationAPI = yield restClient.get(iri, { params: organizationParams });
        yield put({
            type: organizationActions.FETCH_SINGLE_ORGANIZATION_SUCCESSFUL
        });
        yield put({
            type: organizationActions.STORE_SINGLE_ORGANIZATION_DATA,
            iri,
            response: dto(fetchOrganizationAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: organizationActions.FETCH_SINGLE_ORGANIZATION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Mine Organization
 * @param  {Object} promise
 */
export function* fetchMineOrganization({ promise }) {
    const currentUserOrganization = yield select(getUserCurrentOrganization);
    const { iri } = yield { ...currentUserOrganization };
    try {
        const fetchOrganizationAPI = yield restClient.get(iri, { params: organizationParams });
        yield put({
            type: organizationActions.FETCH_SINGLE_ORGANIZATION_SUCCESSFUL
        });
        const fetchOrganizationDTO = dto(fetchOrganizationAPI?.data);
        yield put({
            type: authActions.STORE_USER_ORGANIZATION,
            response: fetchOrganizationDTO?.data
        });
        yield put({
            type: organizationActions.STORE_SINGLE_ORGANIZATION_DATA,
            iri,
            response: fetchOrganizationDTO
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: organizationActions.FETCH_SINGLE_ORGANIZATION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Organization single data
 * @param  {Object} promise
 * @param {Object} formData
 * @param {string} iri Organization iri
 * @return {Object} Response from API
 */
export function* updateSingleOrganization({ promise, formData, iri }) {
    try {
        const updateSingleOrganizationAPI = yield restClient.put(iri, formData, {
            params: organizationParams
        });
        const dtoUpdateSingleOrganizationAPI = yield dto(updateSingleOrganizationAPI?.data);
        yield put({
            type: organizationActions.UPDATE_SINGLE_ORGANIZATION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleOrganizationAPI?.data
        });
        yield put({
            type: organizationActions.STORE_SINGLE_ORGANIZATION_DATA,
            iri,
            response: dtoUpdateSingleOrganizationAPI
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: organizationActions.UPDATE_SINGLE_ORGANIZATION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update mine Organization data
 * @param  {Object} promise
 * @param {Object} formData
 * @return {Object} Response from API
 */
export function* updateMineOrganization({ promise, formData }) {
    const currentUserOrganization = yield select(getUserCurrentOrganization);
    const { iri } = yield { ...currentUserOrganization };
    try {
        if (formData?.working_days?.method === 'patch') {
            yield restClient.patch(formData?.working_days?.iri, {
                ...formData?.working_days
            });
        } else {
            yield restClient.post('/api/organization-capacity-schedules', {
                ...formData?.working_days,
                organization: iri
            });
        }
        const updateSingleOrganizationAPI = yield restClient.put(iri, formData, {
            params: organizationParams
        });
        const dtoUpdateSingleOrganizationAPI = yield dto(updateSingleOrganizationAPI?.data);
        yield put({
            type: organizationActions.UPDATE_SINGLE_ORGANIZATION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleOrganizationAPI?.data
        });
        yield put({
            type: organizationActions.STORE_SINGLE_ORGANIZATION_DATA,
            iri,
            response: dtoUpdateSingleOrganizationAPI
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: organizationActions.UPDATE_SINGLE_ORGANIZATION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(organizationActions.START_FETCHING_SINGLE_ORGANIZATION, fetchOrganization),
    takeLatest(organizationActions.START_FETCHING_MINE_ORGANIZATION, fetchMineOrganization),
    takeLatest(organizationActions.START_UPDATE_SINGLE_ORGANIZATION, updateSingleOrganization),
    takeLatest(organizationActions.START_UPDATE_MINE_ORGANIZATION, updateMineOrganization)
];
