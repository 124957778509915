import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getMeData,
    getUserClientData,
    isUserClient
} from 'erpcore/utils/AuthManager/AuthManager.selectors';
import Image from 'erpcore/components/Image';
import Svg from 'erpcore/components/Svg';
import { getResponsive } from 'erpcore/utils/utils';
import ProfileSettings from 'erpcore/screens/Client/components/ProfileSettings';
import { useNavigate, useParams } from 'react-router-dom';
import restClient from 'erpcore/api/restClient';
import { actions as companyActions } from 'erpcore/screens/Companies/Companies.reducer';
import Button from 'erpcore/components/Button';
import {
    getCompanyData,
    getSingleCompanyFetching
} from 'erpcore/screens/Companies/Companies.selectors';
import PageLoader from 'erpcore/components/PageLoader';
import styles from './SidebarClient.module.scss';

const SidebarClient = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const userData = useSelector(getMeData);
    const userClientData = useSelector(getUserClientData);
    const isClient = useSelector(isUserClient);
    const companyFetching = useSelector(getSingleCompanyFetching);
    const { isMobile } = getResponsive();
    const [showProfileMenu, setShowProfileMenu] = useState(false);

    // If we are impersonating a user, we do not get companyId in the route so we have to use the company id in user data
    // WARNING: user data and user client data are different if one user is impersonating the other!
    const companyID = params.companyId || userClientData.company?.id;

    const companyData = useSelector((state) =>
        getCompanyData(state, `/api/companies/${companyID}`)
    );

    const formatedCompanyData = useMemo(() => {
        return {
            company_logo: userClientData?.company_logo?.id || companyData?.logo?.id || null,
            company_name: userData?.company?.name || companyData?.name,
            full_address: userData?.company?.full_address || companyData?.full_address,
            account_manager: {
                image:
                    userClientData?.account_manager?.image || companyData?.account_manager?.image,
                first_name:
                    userClientData?.account_manager?.first_name ||
                    companyData?.account_manager?.first_name,
                last_name:
                    userClientData?.account_manager?.last_name ||
                    companyData?.account_manager?.last_name,
                phone_number:
                    userClientData?.account_manager?.phone_number ||
                    companyData?.account_manager?.phone_number,
                email: userClientData?.account_manager?.email || companyData?.account_manager?.email
            }
        };
    }, [userClientData, companyData]);

    const fetchCompany = useCallback(() => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: companyActions.START_FETCHING_COMPANY,
                iri: `/api/companies/${companyID}`,
                promise: { resolve, reject },
                params: {
                    include: 'accountManager,accountManager.image,logo'
                }
            });
        });
    }, [companyID]);

    /**
     * Used by the hamburger button
     */
    const handleDeck = useCallback(() => {
        setShowProfileMenu(!showProfileMenu);
    }, [showProfileMenu]);

    useEffect(() => {
        // If URL has company ID in it, it means admin is viewing client dashboard
        // Regular clients dont have companyId in URL
        if (companyID) {
            restClient.defaults.headers.common[
                `erp-scope-to-company`
            ] = `/api/companies/${companyID}`;

            if (!companyData) {
                fetchCompany();
            }
        }
    }, [companyID]);

    const handleBackToERP = useCallback(() => {
        delete restClient.defaults.headers.common[`erp-scope-to-company`];

        navigate('/companies');
    }, []);

    return (
        <aside
            className={`${styles['sidebar-client']} ${
                showProfileMenu ? styles['sidebar-client--opened'] : ''
            }`}
        >
            {companyFetching && <PageLoader />}
            <div className={styles['sidebar-client__content']}>
                <div className={styles['sidebar-client__deck']}>
                    {isMobile && <div className={styles['sidebar-client__mobile-placeholder']} />}

                    <div className={styles['sidebar-client__deck-header']}>
                        <div className={styles['sidebar-client__deck-image']}>
                            {formatedCompanyData?.company_logo ? (
                                <Image iri={formatedCompanyData?.company_logo} version="medium" />
                            ) : (
                                <div className={styles['sidebar-client__deck-image--default']}>
                                    <h4>Logo</h4>
                                </div>
                            )}
                        </div>

                        <h3 className={styles['sidebar-client__deck-title']}>
                            {formatedCompanyData?.company_name}
                        </h3>
                        {formatedCompanyData?.full_address && (
                            <p className={styles['sidebar-client__deck-info']}>
                                {formatedCompanyData?.full_address}
                            </p>
                        )}
                    </div>
                    <div className={styles['sidebar-client__deck-footer']}>
                        {formatedCompanyData?.account_manager?.email && (
                            <div className={styles['sidebar-client__deck-footer__account']}>
                                <h3 className={styles['sidebar-client__deck-title']}>
                                    Account manager
                                </h3>

                                <div className={styles['sidebar-client__deck-account']}>
                                    <div className={styles['sidebar-client__deck-account-image']}>
                                        <Image
                                            iri={formatedCompanyData?.account_manager?.image}
                                            version="small"
                                            fallback={<Svg icon="profile" iconColor="white" />}
                                        />
                                    </div>

                                    <div className={styles['sidebar-client__deck-account-name']}>
                                        <h4>
                                            {formatedCompanyData?.account_manager?.first_name}{' '}
                                            {formatedCompanyData?.account_manager?.last_name}
                                        </h4>
                                        {formatedCompanyData?.account_manager?.phone_number && (
                                            <p
                                                className={
                                                    styles['sidebar-client__deck-account-phone']
                                                }
                                            >
                                                {formatedCompanyData?.account_manager?.phone_number}
                                            </p>
                                        )}
                                        <p className={styles['sidebar-client__deck-account-email']}>
                                            {formatedCompanyData?.account_manager?.email}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={styles['sidebar-client__deck-footer__copy']}>
                            <Svg icon="MyZoneErpHorizontalWhite" iconColor="white" />
                        </div>
                    </div>

                    {isMobile && isClient && (
                        <button
                            type="button"
                            className={styles['sidebar-client__hamburger']}
                            onClick={() => handleDeck()}
                            onKeyPress={() => handleDeck()}
                        >
                            <Svg icon="menu" />
                        </button>
                    )}

                    {isMobile && !isClient && (
                        <div className={styles['sidebar-client__deck-back-link']}>
                            <Svg icon="arrowLeft" iconColor="light-blue" />{' '}
                            <Button
                                variation="link"
                                label="Back to ERP"
                                onClick={handleBackToERP}
                            />
                        </div>
                    )}
                </div>
            </div>

            {isMobile && (
                <div
                    className={`${styles['sidebar-client__mobile-deck']} ${
                        showProfileMenu ? styles['sidebar-client__mobile-deck--opened'] : ''
                    }`}
                >
                    <ProfileSettings mobile />

                    <div className={styles['sidebar-client__deck-footer']}>
                        {formatedCompanyData?.account_manager?.email && (
                            <div className={styles['sidebar-client__deck-footer__account']}>
                                <h3 className={styles['sidebar-client__deck-title']}>
                                    Account manager
                                </h3>

                                <div className={styles['sidebar-client__deck-account']}>
                                    <div className={styles['sidebar-client__deck-account-image']}>
                                        <Image
                                            iri={formatedCompanyData?.account_manager?.image}
                                            version="small"
                                            fallback={<Svg icon="profile" iconColor="white" />}
                                        />
                                    </div>

                                    <div className={styles['sidebar-client__deck-account-name']}>
                                        <h4>
                                            {formatedCompanyData?.account_manager?.first_name}{' '}
                                            {formatedCompanyData?.account_manager?.last_name}
                                        </h4>
                                        {formatedCompanyData?.account_manager?.phone_number && (
                                            <p
                                                className={
                                                    styles['sidebar-client__deck-account-phone']
                                                }
                                            >
                                                {formatedCompanyData?.account_manager?.phone_number}
                                            </p>
                                        )}
                                        <p className={styles['sidebar-client__deck-account-email']}>
                                            {formatedCompanyData?.account_manager?.email}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={styles['sidebar-client__deck-footer__copy']}>
                            <Svg icon="MyZoneErpHorizontalWhite" iconColor="white" />
                        </div>
                    </div>
                </div>
            )}
        </aside>
    );
};

export default SidebarClient;
