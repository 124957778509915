export const getDashboards = (state) => state.dashboards?.listOfDashboards || {};

export const getDashboardByIri = (state, iri) => {
    if (!iri) {
        return {};
    }

    return state.dashboards.listOfDashboards[iri] || {};
};

export const getNewWidgetData = (state) => state.dashboards?.newWidget;

export const getCurrentDashboardIri = (state) => state.dashboards.currentDashboard;

export const getInitialDashboard = (state) => state.dashboards?.initialDashboard;

export const dashboardFetching = (state) => state.dashboards.fetching;

export const dashboardUpdating = (state) => state.dashboards.dashboardUpdating;

export const dashboardsMeta = (state) => state.dashboards.dashboardsMeta;

export default {
    getDashboards,
    getDashboardByIri,
    getNewWidgetData,
    getInitialDashboard,
    getCurrentDashboardIri,
    dashboardFetching,
    dashboardUpdating,
    dashboardsMeta
};
