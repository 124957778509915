import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Input from '../Input';

const Float = ({ input, fieldAttr, fieldProps, field, meta, falsyValue }) => {
    const { name, value, onChange, onBlur } = { ...input };

    /**
     * @param values {Object}
     * @param values.floatValue {Number} Floating point representation
     */
    const handleValueChange = values => {
        const newValue =
            values?.floatValue || values?.floatValue === 0 ? values.floatValue : falsyValue;

        return onChange(newValue);
    };

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
        >
            <NumberFormat
                name={name}
                {...fieldAttr}
                className="input__field"
                allowEmptyFormatting
                value={value === null || value === '' ? null : value}
                onValueChange={values => handleValueChange(values)}
                isNumericString={false}
                thousandSeparator
                decimalSeparator="."
                onBlur={() => onBlur()}
            />
        </Input>
    );
};

Float.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {},
    falsyValue: ''
};

Float.propTypes = {
    fieldProps: PropTypes.object,
    fieldAttr: PropTypes.object,
    field: PropTypes.object,
    input: PropTypes.object,
    meta: PropTypes.object,
    falsyValue: PropTypes.any
};

export default Float;
