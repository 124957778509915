import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';

import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { hasPermission, getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';
import Svg from 'erpcore/components/Svg';

/**
 * Main Navigation
 * @param {Object} props
 * @return {Node} Returns Main Navigation
 */
function MainNavigation() {
    const menuId = 'main-navigation';
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);

    /**
     * Effect used only on component mount
     */
    useEffect(() => {
        // Expand Submenu on Page Load
        const menu = document.getElementById(menuId);
        const activeItems = menu.querySelectorAll('.main-navigation__submenu-link--active');

        if (activeItems.length) {
            activeItems.forEach((activeItem) => {
                activeItem
                    .closest('.main-navigation__menu-item')
                    .classList.add('main-navigation__menu-item--open');
            });
        }
    }, []);

    return (
        <Menu id={menuId}>
            <Menu.Item to="/dashboards" label="Dashboards" />

            {hasPermissionsAccessForEntities.users && <Menu.Item to="/users" label="Users" />}
            {hasPermissionsAccessForEntities.companies && (
                <Menu.Item to="/companies" label="Companies" />
            )}
            {hasPermissionsAccessForEntities.contacts && (
                <Menu.Item to="/contacts" label="Contacts" />
            )}
            {hasPermissionsAccessForEntities.projects && (
                <Menu.Item to="/projects" label="Projects" />
            )}
            {hasPermissionsAccessForEntities.projects && (
                <Menu.Item to="/approvals" label="Approvals" />
            )}
            {hasPermissionsAccessForEntities.hr && (
                <Menu.Submenu label="HR">
                    {hasPermissionsAccessForEntities.timeOffHR && (
                        <Menu.SubmenuItem to="/time-off" label="Time off" />
                    )}
                    {hasPermissionsAccessForEntities.timeOffBalance && (
                        <Menu.SubmenuItem to="/time-off-balance" label="Time off balance" />
                    )}
                </Menu.Submenu>
            )}
            {hasPermission('CAN_MANAGE_COMMON_PROP', meData) && (
                <Menu.Item to="/props" label="Props" />
            )}
            {hasPermissionsAccessForEntities.timeOff && (
                <Menu.Item to="/time-off" label="Time off" />
            )}
            {hasPermissionsAccessForEntities.timeLogs && (
                <Menu.Item to="/time-logs" label="Time logs" />
            )}
            {hasPermissionsAccessForEntities.salesOrders && (
                <Menu.Item to="/sales-orders" label="Sales orders" />
            )}
            {hasPermissionsAccessForEntities.accounting && (
                <Menu.Submenu label="Accounting">
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_CATEGORY', meData) && (
                        <Menu.SubmenuItem to="/accounting/categories" label="Categories" />
                    )}
                    {hasPermission('CAN_MANAGE_PAYROLL_REPORT_PAYROLLREPORT', meData) && (
                        <Menu.SubmenuItem
                            to="/accounting/payroll-reports"
                            label="Payroll reports"
                        />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_INVOICE', meData) && (
                        <Menu.SubmenuItem to="/accounting/invoices" label="Invoices" />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_EXPENSE', meData) && (
                        <Menu.SubmenuItem to="/accounting/expenses" label="Expenses" />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_REVENUE', meData) && (
                        <Menu.SubmenuItem to="/accounting/revenues" label="Revenues" />
                    )}
                </Menu.Submenu>
            )}
            {hasPermissionsAccessForEntities.reports && (
                <Menu.Submenu label="Reports">
                    {hasPermission('CAN_MANAGE_COMMON_EMPLOYMENTRECORD', meData) && (
                        <Menu.SubmenuItem to="/reports/employee-records" label="Employee records" />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_TIMELOG', meData) && (
                        <Menu.SubmenuItem to="/reports/time-logs" label="Time logs" />
                    )}
                    {hasPermissionsAccessForEntities.timeLogs && (
                        <Menu.SubmenuItem to="/reports/user" label="User reports" />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_REPORT_EXPENSE', meData) &&
                        hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_REPORT_REVENUE', meData) && (
                            <Menu.SubmenuItem
                                to="/reports/profit-and-loss"
                                label="Profit and loss"
                            />
                        )}
                    {hasPermission('CAN_MANAGE_PROFITABILITY_REPORT', meData) && (
                        <Menu.SubmenuItem to="/reports/profitability" label="Profitability" />
                    )}
                    {hasPermission('CAN_MANAGE_EFFICIENCY_REPORT', meData) && (
                        <Menu.SubmenuItem to="/reports/efficiency" label="Efficiency" />
                    )}
                </Menu.Submenu>
            )}
            {(hasPermission('CAN_MANAGE_COMMON_CAPACITYSCHEDULE', meData) ||
                hasPermission('CAN_VIEW_OWN_SCHEDULE', meData)) && (
                <Menu.Submenu label="Planning">
                    <Menu.SubmenuItem to="/planning/assignments" label="Assignments" />
                    <Menu.SubmenuItem to="/planning/capacity" label="Capacity" />
                </Menu.Submenu>
            )}

            {hasPermissionsAccessForEntities.integrations && (
                <Menu.Submenu label="Integrations">
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <Menu.SubmenuItem
                            to="/integrations/slack/time-off"
                            label={
                                <>
                                    <Svg icon="slack" /> Slack
                                </>
                            }
                        />
                    )}
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <Menu.SubmenuItem
                            to="/integrations/jira"
                            label={
                                <>
                                    <Svg icon="jira" /> Jira
                                </>
                            }
                        />
                    )}
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <Menu.SubmenuItem
                            to="/integrations/harvest"
                            label={
                                <>
                                    <Svg icon="harvest" /> Harvest
                                </>
                            }
                        />
                    )}
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <Menu.SubmenuItem
                            to="/integrations/toggl"
                            label={
                                <>
                                    <Svg icon="toggl" /> Toggl
                                </>
                            }
                        />
                    )}
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <Menu.SubmenuItem
                            to="/integrations/freshbooks"
                            label={
                                <>
                                    <Svg icon="freshbooks" /> Freshbooks
                                </>
                            }
                        />
                    )}
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <Menu.SubmenuItem
                            to="/integrations/quickbooks"
                            label={
                                <>
                                    <Svg icon="quickbooks" /> QuickBooks
                                </>
                            }
                        />
                    )}
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <Menu.SubmenuItem
                            to="/integrations/sage"
                            label={
                                <>
                                    <Svg icon="sage" /> Sage
                                </>
                            }
                        />
                    )}
                </Menu.Submenu>
            )}
            {hasPermissionsAccessForEntities.settings && (
                <Menu.Submenu label="Settings">
                    {hasPermission('CAN_MANAGE_COMMON_DEPARTMENT', meData) && (
                        <Menu.SubmenuItem to="/settings/departments" label="Departments" />
                    )}
                    {(hasPermission('CAN_MANAGE_COMMON_TAGS_CONTRACTTAG', meData) ||
                        hasPermission('CAN_MANAGE_COMMON_TAGS_PROJECTTAG', meData)) && (
                        <Menu.SubmenuItem
                            to={`/settings/tags/${
                                hasPermission('CAN_MANAGE_COMMON_TAGS_PROJECTTAG', meData)
                                    ? 'project'
                                    : 'contract'
                            }`}
                            label="Tags"
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_DIVISION', meData) && (
                        <Menu.SubmenuItem to="/settings/divisions" label="Divisions" />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_HOURLYCOST', meData) && (
                        <Menu.SubmenuItem
                            to="/settings/hourly-cost"
                            label="Employee hourly costs"
                        />
                    )}

                    {hasPermission('CAN_MANAGE_COMMON_JOBTITLE', meData) && (
                        <Menu.SubmenuItem to="/settings/job-titles" label="Job titles" />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_OFFICE', meData) && (
                        <Menu.SubmenuItem to="/settings/offices" label="Offices" />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_ORGANIZATION', meData) && (
                        <Menu.SubmenuItem
                            to="/settings/organization/general"
                            label="Organization"
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_PAYMENTMETHOD', meData) && (
                        <Menu.SubmenuItem to="/settings/payment-methods" label="Payment methods" />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_PAYMENTTERM', meData) && (
                        <Menu.SubmenuItem to="/settings/payment-terms" label="Payment terms" />
                    )}
                    {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_PERMISSION', meData) && (
                        <Menu.SubmenuItem to="/settings/permissions" label="Permissions" />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_PROP_TYPE', meData) && (
                        <Menu.SubmenuItem to="/settings/props" label="Props" />
                    )}
                    {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) && (
                        <Menu.SubmenuItem to="/settings/roles" label="Roles" />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_ORGANIZATION_SERVICE', meData) && (
                        <Menu.SubmenuItem to="/settings/services" label="Services" />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_WORKTYPE', meData) && (
                        <Menu.SubmenuItem to="/settings/work-types" label="Work types" />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_TIMEOFF', meData) && (
                        <Menu.SubmenuItem to="/settings/time-off" label="Time off types" />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_TIMEOFF', meData) && (
                        <Menu.SubmenuItem
                            to="/settings/time-off-tracking"
                            label="Time off tracking settings"
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_BUNDLETEMPLATE', meData) && (
                        <Menu.SubmenuItem
                            to="/settings/bundle-templates"
                            label="Bundle templates"
                        />
                    )}
                </Menu.Submenu>
            )}
        </Menu>
    );
}

export default MainNavigation;
