import PropTypes from 'prop-types';
import React from 'react';
import TooltipPlugin from 'react-tooltip-lite';
import './Tooltip.scss';

const Tooltip = ({
    content,
    children,
    tipContentHover,
    direction,
    zIndex,
    lightTheme,
    className,
    contentClassname,
    styles
}) => (
    <TooltipPlugin
        className={`tooltip ${lightTheme ? 'tooltip--light' : ''} ${className || ''}`}
        tagName="span"
        padding="8px"
        useDefaultStyles={false}
        content={content}
        direction={direction}
        tipContentHover={tipContentHover}
        tipContentClassName={`tooltip-content ${
            lightTheme ? 'tooltip-content--light' : ''
        } ${contentClassname || ''}`}
        zIndex={zIndex}
        styles={styles}
    >
        {children}
    </TooltipPlugin>
);

Tooltip.defaultProps = {
    content: '',
    children: null,
    tipContentHover: false,
    direction: 'down',
    zIndex: 10000,
    lightTheme: false,
    className: null,
    contentClassname: null,
    styles: {}
};

Tooltip.propTypes = {
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.string
    ]),
    tipContentHover: PropTypes.bool,
    direction: PropTypes.string,
    zIndex: PropTypes.number,
    lightTheme: PropTypes.bool,
    className: PropTypes.string,
    contentClassname: PropTypes.string,
    styles: PropTypes.oneOfType([PropTypes.object])
};

export default Tooltip;
