import { takeEvery, takeLatest, delay, put, call } from 'redux-saga/effects';
import { takeLatestPerProps } from 'erpcore/utils/sagaUtils';
import restClient from 'erpcore/api/restClient';

import { actions as projectContractsActions } from 'erpcore/screens/Projects/screens/ProjectContracts/ProjectContracts.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';

const reducerName = 'projectContracts';
const actionName = 'PROJECT_CONTRACTS';

/**
 * Create contract
 * @param promise
 * @param formData
 * @returns {Generator<<"PUT", PutEffectDescriptor<{response: *, type: string}>>|<"CALL", CallEffectDescriptor>|AxiosPromise<any>|<"PUT", PutEffectDescriptor<{type: string}>>, void, ?>}
 */
export function* createContract({ promise, formData, projectIri }) {
    try {
        const createContractAPI = yield restClient.post('api/contracts', formData);
        yield put({
            type: projectContractsActions.CREATE_PROJECT_CONTRACT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createContractAPI?.data
        });
        try {
            yield put({
                type: listingActions.START_FETCHING_LISTING,
                entity: actionName,
                name: reducerName,
                endpoint: `api/contracts?filters[project][equals]=${projectIri}`
            });
        } catch (error) {
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: error?.response?.data || error
            });
            yield call(promise.reject, error?.response?.data || error);
        }
        yield call(promise.resolve, createContractAPI?.data);
    } catch (error) {
        yield put({
            type: projectContractsActions.CREATE_PROJECT_CONTRACT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export const defaultIncludesForFetchingContract =
    'project.company,department,projectManager,contractItems,totalEmployeeCost,summaryReport,agreement,contractItems.workType,service,salesOrderAutomations,expenses,expenses.company,expenses.category,expenses.contract,tags,workTypeReport,expensesReport,workTypeReportActuals,agreements';

/**
 * Fetch contract
 * @param promise {Object}
 * @param iri {number} contract iri
 * @param params {Object}
 * @return {void}
 */
export function* fetchContract({ promise, contractIri, params }) {
    try {
        const fetchContractAPI = yield restClient.get(contractIri, {
            params: {
                include: defaultIncludesForFetchingContract,
                ...params
            }
        });
        // const fetchContractSalesOrdersAPI = yield restClient.get(
        //     `/api/sales-orders?filters[contract.id][equals]=${contractIri}`
        // );

        const contractData = dto(fetchContractAPI?.data);
        yield put({
            type: projectContractsActions.FETCH_PROJECT_CONTRACT_SUCCESSFUL
        });
        yield put({
            type: projectContractsActions.STORE_PROJECT_CONTRACT_DATA,
            contractIri,
            response: contractData
        });
        yield call(promise.resolve, contractData);
    } catch (error) {
        yield put({
            type: projectContractsActions.FETCH_PROJECT_CONTRACT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Update contract
 * @param  promise {Object}
 * @param  formData {Object}
 * @param  iri {number} contract iri
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* updateContract({ promise, formData, contractIri, debounceDelay = 0 }) {
    yield delay(debounceDelay);
    yield put({
        type: projectContractsActions.START_UPDATE_PROJECT_CONTRACT
    });
    try {
        const updateSingleContractAPI = yield restClient.put(contractIri, formData, {
            params: {
                include: defaultIncludesForFetchingContract
            }
        });
        const contractData = dto(updateSingleContractAPI?.data);
        yield put({
            type: projectContractsActions.UPDATE_PROJECT_CONTRACT_SUCCESSFUL
        });
        yield put({
            type: projectContractsActions.STORE_PROJECT_CONTRACT_DATA,
            contractIri,
            response: contractData
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                ...contractData,
                notificationData: contractData?.data
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectContractsActions.UPDATE_PROJECT_CONTRACT_FAILED
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Delete contract
 * @param  promise {Object}
 * @param  contractIri {string} contract iri
 * @param  formData {object}
 * @return {Promise}
 */
export function* deleteContract({ promise, contractIri, formData }) {
    let deleteURL = contractIri;
    if (formData?.moveTo) {
        deleteURL = `${contractIri}?move_to=${formData?.moveTo}`;
    }

    try {
        const deleteContractAPI = yield restClient.delete(deleteURL);
        yield put({
            type: projectContractsActions.DELETE_PROJECT_CONTRACT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteContractAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectContractsActions.DELETE_PROJECT_CONTRACT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(projectContractsActions.START_CREATE_PROJECT_CONTRACT, createContract),
    takeLatest(projectContractsActions.START_DELETE_PROJECT_CONTRACT, deleteContract),
    takeEvery(projectContractsActions.START_FETCHING_PROJECT_CONTRACT, fetchContract), // MUST BE takeEvery(), DON'T CHANGE!!!!
    takeLatestPerProps(
        'contractIri',
        projectContractsActions.REQUEST_UPDATE_PROJECT_CONTRACT,
        updateContract
    )
];
