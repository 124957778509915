import { takeLatest, put, call, select, takeEvery } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

/**
 * Create Project
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createProject({ promise, formData }) {
    try {
        const createProjectAPI = yield restClient.post('api/projects', formData);
        yield put({
            type: projectsActions.CREATE_PROJECT_SUCCESFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createProjectAPI?.data
        });
        yield call(promise.resolve, createProjectAPI?.data);
    } catch (error) {
        yield put({
            type: projectsActions.CREATE_PROJECT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Project
 * @param  {Object} promise
 * @return {string} id Project id
 */
export function* fetchProject({ promise, iri, params }) {
    try {
        const fetchProjectAPI = yield restClient.get(iri, { params });
        const fetchProjectAPIdto = dto(fetchProjectAPI?.data);
        yield put({
            type: projectsActions.FETCHING_PROJECT_SUCCESSFUL
        });

        yield put({
            type: projectsActions.STORE_PROJECT_DATA,
            iri,
            response: fetchProjectAPIdto
        });

        yield call(promise.resolve, fetchProjectAPIdto);
    } catch (error) {
        yield put({
            type: projectsActions.FETCHING_PROJECT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Project data
 * @param  {Object} iri id of Project
 * @return {Object} Response from API
 */
export function* updateProject({ promise, iri, formData }) {
    try {
        const updateProjectAPI = yield restClient.patch(iri, formData);
        yield put({
            type: projectsActions.UPDATE_PROJECT_SUCCESSFUL
        });
        yield put({
            type: projectsActions.STORE_PROJECT_DATA,
            iri,
            response: dto(updateProjectAPI?.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateProjectAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.UPDATE_PROJECT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Project
 * @param  {Object} projectIri iri of Permission
 * @return {Object} Response from API
 */
export function* deleteProject({ promise, iri }) {
    try {
        const deleteProjectAPI = yield restClient.delete(iri);
        yield put({
            type: projectsActions.DELETE_PROJECT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteProjectAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'projects' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'PROJECTS',
            name: 'projects',
            endpoint: 'api/projects?include=company,department,projectManager'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.DELETE_PROJECT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const projectsSaga = [
    takeLatest(projectsActions.START_CREATE_PROJECT, createProject),
    takeEvery(projectsActions.START_FETCHING_PROJECT, fetchProject),
    takeLatest(projectsActions.START_UPDATE_PROJECT, updateProject),
    takeLatest(projectsActions.START_DELETE_PROJECT, deleteProject)
];

export default projectsSaga;
