import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';

import './Input.scss';
import Tooltip from 'erpcore/components/Tooltip';

function Input({ input, fieldProps, fieldAttr, meta, children, isSelect, className }) {
    const hasValue =
        (input.value && (input.value.length > 0 || input.value)) ||
        input.value === 0 ||
        input.value === false;
    const labelActive =
        meta.active || meta.autofilled || hasValue || fieldProps.forceLabelActive === true;

    return (
        <div
            className={`input${labelActive !== false ? ' input--active' : ''}${
                fieldAttr.required === true ? ' input--required' : ''
            }${fieldProps.overridePaddingNormal ? ' input--override-padding-normal' : ''}${
                fieldProps.hidden ? ' input--hidden' : ''
            }${fieldProps.icon ? ` input--has-icon` : ''}${
                fieldProps.iconPosition
                    ? ` input--icon-${fieldProps.iconPosition}`
                    : `${fieldProps.icon ? ' input--icon-left' : ''}`
            }${meta.active ? ` input--focused` : ''}${
                fieldAttr.disabled ? ` input--disabled` : ''
            }${meta.touched && meta.error ? ` input--error` : ''}${
                className ? ` ${className}` : ''
            }${fieldAttr?.className ? ` ${fieldAttr?.className}` : ''}`}
        >
            {children}
            {fieldProps.icon && (
                <div
                    className={`input__icon${
                        fieldProps.iconPosition === 'right'
                            ? ` input__icon--right`
                            : ` input__icon--left`
                    }`}
                >
                    <Svg icon={fieldProps.icon} />
                </div>
            )}
            {fieldProps.clearable && !fieldAttr.disabled && hasValue && !isSelect && (
                <button
                    type="button"
                    aria-label={`Clear ${input.name}`}
                    className="input__action input__action--clear"
                    onClick={() => {
                        const newValue =
                            'clearableValue' in fieldProps ? fieldProps?.clearableValue : '';

                        input.onChange(newValue);
                        if ('afterCleared' in fieldProps) {
                            fieldProps.afterCleared(newValue);
                        }
                    }}
                >
                    <Svg icon="close" />
                </button>
            )}
            {meta.touched && meta.error && !fieldProps.errorAsTooltip && (
                <span className="input__helper-text input__helper-text--error">
                    {meta.error.message ? meta.error.message : meta.error}
                </span>
            )}
            {meta.touched && meta.error && fieldProps.errorAsTooltip && (
                <span className="input__helper-text input__helper-text--error input__helper-text--tooltip">
                    <Tooltip content={meta.error.message ? meta.error.message : meta.error}>
                        <Svg icon="info" />
                    </Tooltip>
                </span>
            )}
            {fieldProps.helperText && (
                <span className="input__helper-text">{fieldProps.helperText}</span>
            )}
            {fieldProps.label && (
                <label
                    htmlFor={input.name}
                    className={`input__label${
                        labelActive !== false ? ' input__label--active' : ''
                    }`}
                >
                    {fieldProps.label}
                </label>
            )}
        </div>
    );
}

Input.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    children: null,
    isSelect: false,
    className: ''
};

Input.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]).isRequired,
    meta: PropTypes.oneOfType([PropTypes.object]).isRequired,
    children: PropTypes.node,
    isSelect: PropTypes.bool,
    className: PropTypes.string
};

export default Input;
