import { takeLatest, delay, put, call } from 'redux-saga/effects';
import { takeLatestPerProps } from 'erpcore/utils/sagaUtils';
import restClient from 'erpcore/api/restClient';

import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as projectFeaturesActions } from './ProjectFeatures.reducer';

/**
 * Create projectFeature
 * @param  promise {Object}
 * @param  formData {Object}
 * @return {void}
 */
export function* createProjectFeature({ promise, formData }) {
    try {
        // Create projectFeature
        const createProjectFeatureAPI = yield restClient.post(`api/project/features`, formData);
        const featureData = dto(createProjectFeatureAPI?.data);
        yield put({
            type: projectFeaturesActions.CREATE_PROJECT_FEATURE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                ...featureData,
                notificationData: featureData?.data
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectFeaturesActions.CREATE_PROJECT_FEATURE_FAILED,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Fetch projectFeature
 * @param promise {Object}
 * @param iri {number} projectFeature iri
 * @param params {Object}
 * @return {void}
 */
export function* fetchProjectFeature({ promise, featureIri, params }) {
    try {
        const fetchProjectFeatureAPI = yield restClient.get(featureIri, {
            params
        });
        const featureData = dto(fetchProjectFeatureAPI?.data);
        yield put({
            type: projectFeaturesActions.FETCH_PROJECT_FEATURE_SUCCESSFUL
        });
        yield put({
            type: projectFeaturesActions.STORE_PROJECT_FEATURE_DATA,
            featureIri,
            response: featureData
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectFeaturesActions.FETCH_PROJECT_FEATURE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Update projectFeature single data
 * @param  promise {Object}
 * @param  formData {Object}
 * @param  iri {number} projectFeature iri
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* updateSingleProjectFeature({ promise, formData, featureIri, debounceDelay = 0 }) {
    yield delay(debounceDelay);
    yield put({
        type: projectFeaturesActions.START_UPDATE_PROJECT_FEATURE
    });
    try {
        const updateSingleProjectFeatureAPI = yield restClient.put(featureIri, formData);
        const featureData = dto(updateSingleProjectFeatureAPI?.data);
        yield put({
            type: projectFeaturesActions.UPDATE_PROJECT_FEATURE_SUCCESSFUL
        });
        yield put({
            type: projectFeaturesActions.STORE_PROJECT_FEATURE_DATA,
            featureIri,
            response: featureData
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                ...featureData,
                notificationData: featureData?.data
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectFeaturesActions.UPDATE_PROJECT_FEATURE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Delete Single projectFeature
 * @param  promise {Object}
 * @param  featureIri {string} projectFeature iri
 * @param  formData {object}
 * @return {void}
 */
export function* deleteSingleProjectFeature({ promise, featureIri, formData }) {
    try {
        const deleteSingleProjectFeatureAPI = yield restClient.delete(featureIri, {
            params: formData
        });
        yield put({
            type: projectFeaturesActions.DELETE_PROJECT_FEATURE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleProjectFeatureAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectFeaturesActions.DELETE_PROJECT_FEATURE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Sort multiple projectFeatures
 * @param  promise {Object}
 * @param  projectIri {string}
 * @param  formData {Object} Should contain property 'positions' with array of projectFeature positions, Example `positions: [{position: 1, object_id: 6}, {...}, ...]`
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* sortProjectFeatures({ promise, projectIri, formData, debounceDelay = 0 }) {
    yield delay(debounceDelay);
    yield put({
        type: projectFeaturesActions.START_SORT_PROJECT_FEATURES
    });
    try {
        const updateSingleProjectFeatureAPI = yield restClient.patch(
            `${projectIri}/features/position`,
            formData
        );
        yield put({
            type: projectFeaturesActions.SORT_SUCCESSFUL_PROJECT_FEATURES
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleProjectFeatureAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectFeaturesActions.SORT_FAILED_PROJECT_FEATURES
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Fetch milestone data
 * @param  promise {Object}
 * @param  featureIri {string}
 * @return {void}
 */
export function* fetchFeatureTimelogData({ promise, featureIri }) {
    try {
        const milestoneTimelogAPI = yield restClient.get(
            `/api/time-logs?filters[feature][equals]=${featureIri}`
        );
        yield put({
            type: projectFeaturesActions.FETCHING_SUCCESSFUL_FEATURE_TIMELOGS_DATA,
            response: dto(milestoneTimelogAPI?.data),
            featureIri
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectFeaturesActions.FETCHING_FAILED_FEATURE_TIMELOGS_DATA
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(projectFeaturesActions.START_CREATE_PROJECT_FEATURE, createProjectFeature),
    takeLatest(projectFeaturesActions.START_FETCHING_PROJECT_FEATURE, fetchProjectFeature),
    takeLatestPerProps(
        'featureIri',
        projectFeaturesActions.REQUEST_UPDATE_PROJECT_FEATURE,
        updateSingleProjectFeature
    ),
    takeLatest(projectFeaturesActions.START_DELETE_PROJECT_FEATURE, deleteSingleProjectFeature),
    takeLatest(projectFeaturesActions.REQUEST_SORT_PROJECT_FEATURES, sortProjectFeatures),
    takeLatest(projectFeaturesActions.START_FETCHING_FEATURE_TIMELOGS_DATA, fetchFeatureTimelogData)
];
