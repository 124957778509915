import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { DateRangePicker } from 'react-dates';
import { getResponsive } from 'erpcore/utils/utils';
import { TABLE_INPUT_FOCUS_OUT_CHECK_DELAY_MILLISECONDS } from 'erpcore/components/Form/components/TableInput/data';
import Input from '../Input';
import 'react-dates/lib/css/_datepicker.css';
import './DateRange.scss';

const TRANSITION_DURATION = TABLE_INPUT_FOCUS_OUT_CHECK_DELAY_MILLISECONDS + 100;

const presetMap = {
    last7Days: {
        startDate: moment().subtract(7, 'days'),
        endDate: moment()
    },
    last30Days: {
        startDate: moment().subtract(30, 'days'),
        endDate: moment()
    },
    last365Days: {
        startDate: moment().subtract(365, 'days'),
        endDate: moment()
    },
    currentWeek: {
        startDate: moment().startOf('week'),
        endDate: moment().endOf('week')
    },
    currentMonth: {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month')
    },
    currentYear: {
        startDate: moment().startOf('year'),
        endDate: moment().endOf('year')
    },
    startOfWeek: {
        startDate: moment().startOf('week'),
        endDate: moment()
    },
    startOfMonth: {
        startDate: moment().startOf('month'),
        endDate: moment()
    },
    startOfYear: {
        startDate: moment().startOf('year'),
        endDate: moment()
    },
    endOfWeek: {
        startDate: moment(),
        endDate: moment().endOf('week')
    },
    endOfMonth: { startDate: moment(), endDate: moment().endOf('month') },
    endOfYear: {
        startDate: moment(),
        endDate: moment().endOf('year')
    },
    previousWeek: {
        startDate: moment().subtract(1, 'weeks').startOf('week'),
        endDate: moment().subtract(1, 'weeks').endOf('week')
    },
    previousMonth: {
        startDate: moment().subtract(1, 'months').startOf('month'),
        endDate: moment().subtract(1, 'months').endOf('month')
    },
    previousYear: {
        startDate: moment().subtract(1, 'years').startOf('year'),
        endDate: moment().subtract(1, 'years').endOf('year')
    },
    allTime: {
        startDate: moment().subtract(100, 'years').startOf('year'),
        endDate: moment()
    }
};

const DateRangePresets = React.memo(
    ({
        // eslint-disable-next-line react/prop-types
        activePreset = '',
        // eslint-disable-next-line react/prop-types
        setActivePreset = () => {},
        // eslint-disable-next-line react/prop-types
        onDateChange = () => {}
    }) => {
        const handlePresetChange = useCallback((event) => {
            onDateChange(presetMap[event?.target?.value]);
            setActivePreset(event?.target?.value);
        }, []);

        return (
            <div className="daterange-presets">
                <select
                    onChange={handlePresetChange}
                    className="daterange-presets__select"
                    value={activePreset}
                >
                    <option value="">Date range preset</option>
                    {/* <option value="last365Days">Last 365 Days</option> */}
                    <option value="currentWeek">This week</option>
                    <option value="currentMonth">This month</option>
                    {/* <option value="currentYear">Current Year</option> */}
                    {/* <option value="startOfWeek">From Start of the Week</option> */}
                    {/* <option value="startOfMonth">From Start of the Month</option> */}
                    {/* <option value="startOfYear">From Start of the Year</option> */}
                    {/* <option value="endOfWeek">Till End of the Week</option> */}
                    {/* <option value="endOfMonth">Till End of the Month</option> */}
                    {/* <option value="endOfYear">Till End of the Year</option> */}
                    <option value="previousWeek">Last week</option>
                    <option value="previousMonth">Last month</option>
                    {/* <option value="previousYear">Previous Year</option> */}
                    <option value="last7Days">Last 7 days</option>
                    <option value="last30Days">Last 30 days</option>
                    <option value="allTime">All time</option>
                </select>
            </div>
        );
    }
);

const DateRange = ({ input, meta, fieldProps, fieldAttr, field, isOutsideRange }) => {
    const { name, value } = input;
    const { keyStart, keyEnd, minimumNights, arraySupport } = fieldProps;
    const { required, disabled, startId, endId } = fieldAttr;

    const keepFocusLock_Ref = useRef(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const { isMobile } = getResponsive();
    const [activePreset, setActivePreset] = useState(false);
    const dateFormat = fieldProps?.dateFormat || 'M/D/YYYY';

    const onDateChange = useCallback(
        (dates) => {
            if (arraySupport) {
                // create empty array as fallback
                const reduxArray = [];

                if (dates?.startDate) {
                    // push start date
                    reduxArray.push(moment(dates.startDate).format(dateFormat));
                }

                if (dates?.endDate) {
                    // push end date
                    reduxArray.push(moment(dates.endDate).format(dateFormat));
                }

                // dispatch to redux form
                input.onChange(reduxArray);
            } else if (!arraySupport) {
                // create empty object as fallback
                const reduxKeys = {};

                if (dates?.startDate) {
                    // add first key to the object
                    reduxKeys[keyStart || 'start'] = moment(dates.startDate).format(dateFormat);
                }

                if (dates?.endDate) {
                    // add second key to the object
                    reduxKeys[keyEnd || 'end'] = moment(dates.endDate).format(dateFormat);
                }

                // dispatch to redux form
                input.onChange(reduxKeys);
            } else {
                // set empty/error value
                input.onChange(null);
            }

            setStartDate(dates.startDate || null);
            setEndDate(dates.endDate || null);
            setActivePreset('');
        },
        [arraySupport, dateFormat, keyStart, keyEnd]
    );

    useEffect(() => {
        if (focusedInput) {
            input.onFocus();
        } else if (!focusedInput && meta.visited) {
            // clear field if both values are not present
            if (!startDate || !endDate) {
                setStartDate(null);
                setEndDate(null);
            }
            input.onBlur();
        }
    }, [focusedInput]);

    useEffect(() => {
        if (!value) {
            setStartDate(null);
            setEndDate(null);
        }
        if (value) {
            if (Object.prototype.toString.call(value) === '[object Array]') {
                if (value[0]) setStartDate(moment(value[0]));
                if (value[1]) setEndDate(moment(value[1]));
            }
            if (Object.prototype.toString.call(value) === '[object Object]') {
                if (value[keyStart || 'start']) setStartDate(moment(value[keyStart || 'start']));
                if (value[keyEnd || 'end']) setEndDate(moment(value[keyEnd || 'end']));
            }
        }
    }, [value]);

    const renderDateRangePresets = useCallback(() => {
        return (
            <DateRangePresets
                activePreset={activePreset}
                setActivePreset={setActivePreset}
                onDateChange={onDateChange}
            />
        );
    }, [activePreset, onDateChange]);

    const handleClose = useCallback(() => {
        keepFocusLock_Ref.current = false;
    }, []);

    /**
     * Trigger close
     */
    useEffect(() => {
        const handleBackdropClick = (e) => {
            if (e.target.classList.contains('DateRangePicker_picker')) {
                try {
                    e.target.querySelector('.DateRangePicker_closeButton').click();
                } catch (e2) {
                    //
                }
            }
        };

        window.addEventListener('click', handleBackdropClick);

        return () => {
            window.removeEventListener('click', handleBackdropClick);
        };
    }, []);

    /**
     * Lock focus
     */
    useEffect(() => {
        const lockFocus = (e) => {
            const dateRangeModalElement =
                e.target.closest('.DateRangePicker_picker') ||
                document.querySelector('.DateRangePicker_picker');

            if (
                dateRangeModalElement &&
                !dateRangeModalElement.contains(document.activeElement) &&
                keepFocusLock_Ref.current
            ) {
                const elements1 = dateRangeModalElement.querySelector(
                    'td[role="button"][tabindex="0"]:not([aria-disabled="true"])'
                );
                const elements2 = dateRangeModalElement.querySelectorAll(
                    'div[role="button"]:not([aria-disabled="true"]):not(:disabled), button:not(:disabled):not([aria-disabled="true"]), select:not(:disabled)'
                );

                const focusableTarget = elements1 || elements2[0];

                focusableTarget.focus();
            }
        };

        window.addEventListener('focusout', lockFocus);

        return () => {
            window.removeEventListener('focusout', lockFocus);
        };
    }, []);

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
            className={`input--daterange ${
                startDate || endDate ? 'input--daterange-has-value' : 'input--daterange-no-value'
            }`}
        >
            <DateRangePicker
                appendToBody={false}
                block
                disabled={disabled}
                disableScroll
                endDate={endDate}
                endDateAriaLabel="Select end date"
                endDateId={endId || `${name}EndDate`}
                endDatePlaceholderText=""
                focusedInput={focusedInput}
                hideKeyboardShortcutsPanel
                isOutsideRange={(day) =>
                    isOutsideRange ? isOutsideRange(day, startDate || endDate) : false
                }
                minimumNights={minimumNights || 0}
                noBorder
                numberOfMonths={isMobile ? 1 : 2}
                onDatesChange={onDateChange}
                onFocusChange={(focusValue) => setFocusedInput(focusValue)}
                withFullScreenPortal
                readOnly
                required={required}
                startDate={startDate}
                startDateAriaLabel="Select start date"
                startDateId={startId || `${name}StartDate`}
                startDatePlaceholderText=""
                renderCalendarInfo={renderDateRangePresets}
                onClose={handleClose}
                keepFocusOnInput
                transitionDuration={TRANSITION_DURATION}
            />
        </Input>
    );
};

DateRange.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {},
    isOutsideRange: null
};

DateRange.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    isOutsideRange: PropTypes.func
};

export default DateRange;
