import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Sales orders Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const SalesOrders = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'salesorder.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Sales order successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'salesorder.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Sales order successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'salesorder.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Sales order successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'salesOrder.statusSuccessfullyChanged':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Sales order status successfully changed!"
                    type="success"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

SalesOrders.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

SalesOrders.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default SalesOrders;
