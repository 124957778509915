import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    getProjectApprovalPrivateData,
    getProjectApprovalPrivateFetching,
    getProjectApprovalPrivateToken
} from 'erpcore/screens/Projects/screens/ProjectApprovals/ProjectApprovals.selectors';
import { actions as projectApprovalsActions } from 'erpcore/screens/Projects/screens/ProjectApprovals/ProjectApprovals.reducer';
import ProjectApprovalSignatureForm from 'erpcore/screens/Projects/screens/ProjectApprovals/components/ProjectApprovalSignatureForm';
import ProjectApprovalChangeRequestForm from 'erpcore/screens/Projects/screens/ProjectApprovals/components/ProjectApprovalChangeRequestForm';
import FloatingNotifications from 'erpcore/components/Layout/FloatingNotifications';
import ProjectApprovalStates from 'erpcore/screens/Projects/screens/ProjectApprovals/components/ProjectApprovalStates';

import Svg from 'erpcore/components/Svg';
import PageLoader from 'erpcore/components/PageLoader';
import Modal from 'erpcore/components/Modal';
import Button from 'erpcore/components/Button';
import BrandingManager from 'erpcore/utils/BrandingManager';
import background from './ProjectApprovals.bg.png';
import styles from './ProjectApprovalsPrivate.module.scss';

const ProjectApprovalsPrivate = () => {
    const routeParams = useParams();
    const dispatch = useDispatch();

    const [modalOpened, setModalState] = useState(false);

    const routeHash = routeParams?.hash;

    const fetching = useSelector(state => getProjectApprovalPrivateFetching(state));
    const reduxData = useSelector(state => getProjectApprovalPrivateData(state));
    const token = useSelector(state => getProjectApprovalPrivateToken(state));

    const handleModal = useCallback(() => {
        setModalState(!modalOpened);
    }, [modalOpened]);

    const fetchApprovalData = useCallback(hash => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectApprovalsActions.START_FETCHING_PRIVATE_PROJECT_APPROVAL,
                hash
            });
        });
    }, []);

    const submitApproval = useCallback(
        formData => {
            return new Promise((resolve, reject) => {
                dispatch({
                    promise: { resolve, reject },
                    type: projectApprovalsActions.START_APPROVE_PROJECT_APPROVAL,
                    formData,
                    token,
                    hash: routeHash
                });
            })
                .catch(error => ({ error }))
                .then(() => {
                    handleModal();
                    fetchApprovalData(routeHash).catch(error => ({ error }));
                });
        },
        [routeHash, token, handleModal]
    );

    const submitChangeRequest = useCallback(
        formData => {
            return new Promise((resolve, reject) => {
                dispatch({
                    promise: { resolve, reject },
                    type: projectApprovalsActions.START_UPDATING_PROJECT_APPROVAL_CHANGE_REQUEST,
                    formData,
                    token,
                    hash: routeHash
                });
            })
                .catch(error => ({ error }))
                .then(() => {
                    handleModal();
                    fetchApprovalData(routeHash).catch(error => ({ error }));
                });
        },
        [routeHash, token, handleModal]
    );

    useEffect(() => {
        if (!reduxData) fetchApprovalData(routeHash).catch(error => ({ error }));
    }, [reduxData, routeHash]);

    const data = useMemo(() => {
        const { reason, note, contract, company, file, status, signature } = { ...reduxData };
        const { content_url: fileURL, meta: fileMeta } = { ...file };
        const { content_url: signatureURL } = { ...signature };
        const isInternalUser = reduxData?.assigned_to;

        const name = isInternalUser
            ? `${isInternalUser?.first_name || ''} ${isInternalUser?.last_name || ''}`
            : `${reduxData?.contact?.first_name || ''} ${reduxData?.contact?.last_name || ''}`;

        return {
            name,
            subject: reason,
            note,
            contract: contract?.name,
            company: company?.name,
            file: file ? { url: fileURL, name: fileMeta?.filename } : null,
            status,
            signature: {
                content_url: signatureURL,
                iri: signature?.iri
            }
        };
    }, [reduxData]);

    const initialValues = useMemo(() => {
        const values = {};
        if (data?.signature) values.signature = data.signature;
        return values;
    }, [data]);

    return (
        <>
            <BrandingManager />
            <div className={styles.page}>
                <Helmet>
                    <base target="_blank" />
                </Helmet>
                <FloatingNotifications />
                <div
                    className={styles.page__left}
                    style={{ backgroundImage: `url('${background}')` }}
                >
                    <Svg icon="MyZoneErpHorizontalWhite" className={styles.logo} />
                </div>
                {fetching ? (
                    <PageLoader />
                ) : (
                    <div className={styles.page__right}>
                        <div className={styles.inner}>
                            <ProjectApprovalStates
                                title="Approval Request Status"
                                data={reduxData}
                            />
                            <h1 className={styles.name}>Dear {data.name} </h1>
                            <p>
                                This approval request is for:{' '}
                                <strong>&quot;{data.subject}&quot;</strong>.
                            </p>
                            {(data.status === 'pending' || data.status === 'created') && (
                                <p>
                                    Please review the approval request and if you agree with
                                    everything presented please add your signature below and press
                                    ‘Approve’.
                                </p>
                            )}
                            {data.note && (
                                <div className={styles.note}>
                                    <strong className={styles.note__title}>Note:</strong>
                                    <div
                                        className={styles.note__content}
                                        dangerouslySetInnerHTML={{ __html: data.note }}
                                    />
                                </div>
                            )}
                            <ul className={styles.list}>
                                <li>
                                    <strong>Project & project contract name:</strong>{' '}
                                    {data.contract}
                                </li>
                                <li>
                                    <strong>Company name:</strong> {data.company}
                                </li>
                                {data.file && (
                                    <li>
                                        <strong>Attachment:</strong>{' '}
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={data.file.url}
                                        >
                                            {data.file.name}
                                        </a>
                                    </li>
                                )}
                            </ul>
                            {data.status !== 'change_requested' && (
                                <div className={styles.form}>
                                    <ProjectApprovalSignatureForm
                                        initialValues={initialValues}
                                        onSubmit={submitApproval}
                                        modalBtn={
                                            <Button
                                                variation="secondary"
                                                label="Request changes"
                                                onClick={() => handleModal()}
                                            />
                                        }
                                    />
                                    {(data.status === 'pending' || data.status === 'created') && (
                                        <Modal
                                            variation="medium"
                                            opened={modalOpened}
                                            onClose={() => handleModal()}
                                            title="Request changes"
                                            subtitle="Please add a description of changes requested:"
                                        >
                                            <ProjectApprovalChangeRequestForm
                                                onSubmit={submitChangeRequest}
                                                onCancel={() => handleModal()}
                                            />
                                        </Modal>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ProjectApprovalsPrivate;
