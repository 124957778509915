import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import { useDispatch, useSelector } from 'react-redux';
import { getBrandingSettings } from 'erpcore/utils/BrandingManager/BrandingManager.selectors';
import { actions as imageManagerActions } from 'erpcore/components/ImageManager/ImageManager.reducer';
import { getImage } from 'erpcore/components/ImageManager/ImageManager.selectors';
import { getImageSrcFromMediaObject } from 'erpcore/components/ImageManager';
import { actions as brandingActions } from 'erpcore/utils/BrandingManager/BrandingManager.reducer';
import styles from './Hero.module.scss';

const Hero = ({ className }) => {
    const branding = useSelector(getBrandingSettings);
    const backgroundImage = useSelector(state =>
        getImage(state, branding?.settings?.erp_login_background)
    );
    const logoImage = useSelector(state => getImage(state, branding?.settings?.erp_login_logo));
    const backgroundImageURL = getImageSrcFromMediaObject(backgroundImage, 'large');
    const logoImageURL = getImageSrcFromMediaObject(logoImage, 'large');

    const dispatch = useDispatch();

    const fetchBackgroundImage = useCallback(() => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: imageManagerActions.START_FETCHING_IMAGE,
                iri: branding?.settings?.erp_login_background,
                promise: { resolve, reject }
            });
        });
    }, [branding]);

    const fetchLogo = useCallback(() => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: imageManagerActions.START_FETCHING_IMAGE,
                iri: branding?.settings?.erp_login_logo,
                promise: { resolve, reject }
            });
        });
    }, [branding]);

    useEffect(() => {
        if (branding?.settings?.erp_login_background) {
            fetchBackgroundImage();
        }
    }, [fetchBackgroundImage]);

    useEffect(() => {
        if (branding?.settings?.erp_login_logo) {
            fetchLogo();
        }
    }, [fetchLogo]);

    useEffect(() => {
        if (logoImageURL || branding?.settings?.erp_login_logo === null) {
            dispatch({
                type: brandingActions.SET_BRANDING_OPTION,
                options: {
                    cached_logo: logoImageURL
                }
            });
        }
    }, [logoImageURL]);

    useEffect(() => {
        if (backgroundImageURL || branding?.settings?.erp_login_background === null) {
            dispatch({
                type: brandingActions.SET_BRANDING_OPTION,
                options: {
                    cached_login_bg: backgroundImageURL
                }
            });
        }
    }, [backgroundImageURL]);

    return (
        <div
            className={`${styles.hero} ${className || ''}`}
            style={{
                ...(branding?.cached_login_bg
                    ? { backgroundImage: `url(${branding?.cached_login_bg})` }
                    : {})
            }}
        >
            <div className={styles.hero__content}>
                {branding?.cached_logo ? (
                    <img
                        src={branding?.cached_logo}
                        className={styles.hero__logo}
                        alt="organization logo"
                    />
                ) : (
                    <Svg icon="MyZoneErpHorizontalWhite" className={styles.hero__logo} />
                )}
            </div>
        </div>
    );
};

Hero.defaultProps = {
    className: ''
};

Hero.propTypes = {
    className: PropTypes.string
};

export default Hero;
