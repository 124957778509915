import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Tags Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const Tags = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'contracttag.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Contract tag successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'contracttag.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Contract tag successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'contracttag.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Contract tag successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'projecttag.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project tag successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'projecttag.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project tag successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'projecttag.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project tag successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

Tags.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

Tags.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default Tags;
