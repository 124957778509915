import { getIdFromIri as getIdFromUniqueIdentifierGenericReports } from 'erpcore/utils/dto';

function getSubAggregationData(subAggregation, includedDataMap) {
    if (includedDataMap.size === 0) {
        return {};
    }

    const result = {};

    subAggregation.forEach(subAggregationItem => {
        const { iri, key, attributes } = subAggregationItem;

        const basicSubAggregationData = {
            ...attributes,
            key
        };

        const includedItemData = includedDataMap.get(iri);

        if (
            // Case when sub aggregation is an entity that does not have an IRI (e.g. month, day, year, geolocation etc.)
            !iri ||
            // Case when we could not find sub aggregation item in included items
            !includedItemData
        ) {
            result[iri] = basicSubAggregationData;
            return;
        }

        result[iri] = {
            ...basicSubAggregationData,
            ...includedItemData,
            iri
        };
    });

    return result;
}

function getAggregationAttributes(aggregationIri, originalAggregationAttributes, includedDataMap) {
    const {
        sub_aggregation: originalSubAggregation,
        ...restOfAggregationAttributes
    } = originalAggregationAttributes;

    // Merge all of aggregation attributes except IRI and sub_aggregation (because they may not exist)
    let result = {
        ...restOfAggregationAttributes
    };

    /*  If we have IRI, that means we should have some included data that is connected with that IRI, 
        so we will merge that included data with the result object.
    */
    if (aggregationIri) {
        const includedItem = includedDataMap.get(aggregationIri);

        if (includedItem) {
            result = {
                ...result,
                ...includedItem
            };
        }
    }

    if (originalSubAggregation) {
        result.sub_aggregation = getSubAggregationData(originalSubAggregation, includedDataMap);
    }

    return result;
}

function getIdentificationProperties(key, iri) {
    const result = {
        key,

        /*  We may receive aggregations that are not DB entities, e.g. months, days, geolocation etc. 
            In such cases, aggregation does not have "iri" property, so we will use "key" property instead
        */
        id: getIdFromUniqueIdentifierGenericReports(iri || key)
    };

    // Add "iri" property if we can find it
    if (iri) {
        result.iri = iri;
    }

    return result;
}

function processInputItem(inputItem, includedDataMap) {
    const { attributes, iri, key, ...otherAggregationProperties } = inputItem;

    return {
        ...getAggregationAttributes(iri, attributes, includedDataMap),
        ...otherAggregationProperties,
        ...getIdentificationProperties(key, iri)
    };
}

function getIncludedDataMap(includedData) {
    const includedDataMap = new Map();

    includedData.forEach(includedItem => {
        const { id, type, attributes } = includedItem;

        if (!id) {
            return;
        }

        includedDataMap.set(id, {
            ...attributes,
            id: getIdFromUniqueIdentifierGenericReports(id),
            type
        });
    });

    return includedDataMap;
}

/**
 * Transfering data from the generic reports API to Frontend friendly format
 * @param {object} response
 * @returns {object}
 */
const dtoGenericReports = response => {
    if (!response) {
        return null;
    }

    const { data, included, stats, meta } = response;

    if (!data) {
        return response;
    }

    return {
        /*  Currently, stats item is an array that always contains one item -> 
            we can probably change the API response to return an object
        */
        stats: stats[0] || {},
        meta,
        data: data.map(dataItem => processInputItem(dataItem, getIncludedDataMap(included)))
    };
};

export default dtoGenericReports;
