import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react';
import { Field, getFormValues, reduxForm, focus, change } from 'redux-form';
import Button from 'erpcore/components/Button';
import Dropdown from 'erpcore/components/TimeTracking/components/Dropdown';
import styles from 'erpcore/components/TimeTracking/TimeTracking.module.scss';
import Form, { Autocomplete, Select } from 'erpcore/components/Form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useDropdown } from 'erpcore/components/TimeTracking/TimeTrackingHooks';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';

const TimeTrackingCreateForm = ({
    form,
    submitting,
    pristine,
    invalid,
    onSubmit,
    handleSubmit
}) => {
    const formValues = useSelector((state) => getFormValues(form)(state));
    const [projectContract, setProjectContract] = useState(null);
    const { toggleDropdown, dropdownOpenStatus } = useDropdown();
    const dispatch = useDispatch();
    const currentProjectContract = useRef(null);
    const projectContractStages = useMemo(
        () => projectContract?.stages?.filter((stage) => !stage?.is_completed),
        [projectContract?.stages]
    );

    const fetchProjectContract = useCallback(async (contractID) => {
        try {
            const projectContractAPI = await restClient.get(contractID, {
                params: {
                    include: 'stages'
                }
            });
            const projectContractDTO = dto(projectContractAPI?.data)?.data;

            setProjectContract(projectContractDTO);

            return true;
        } catch (e) {
            return e;
        }
    }, []);

    useEffect(() => {
        if (
            currentProjectContract.current &&
            formValues?.project_contract &&
            currentProjectContract.current !== formValues?.project_contract
        ) {
            dispatch(change(form, 'stage', null));
        }

        currentProjectContract.current = formValues?.project_contract;
        if (dropdownOpenStatus.project_contract) toggleDropdown('project_contract');
        if (formValues?.project_contract) {
            fetchProjectContract(formValues?.project_contract);
        }
    }, [formValues?.project_contract]);

    const timeLogsRequireProjectStageSelection = useMemo(() => {
        return projectContract?.meta?.stage_required !== undefined
            ? projectContract?.meta?.stage_required
            : true;
    }, [projectContract?.meta]);

    const workTypeOptions = useMemo(() => {
        return projectContract?.available_work_types?.length
            ? projectContract?.available_work_types?.map((workType) => ({
                  label: workType?.data?.attributes?.name,
                  value: workType?.data?.id
              }))
            : null;
    }, [projectContract?.available_work_types]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className={styles['time-tracking__create-form']}>
            <div className={styles['time-tracking__create-new']}>
                <div className={styles['time-tracking__create-project']}>
                    {!formValues?.project_contract ? (
                        <Button
                            className={styles['time-tracking__create-button']}
                            label="Choose Project"
                            iconName="plus"
                            variation="tertiary"
                            onClick={() => {
                                toggleDropdown('project_contract');
                                dispatch(focus(form, 'project_contract'));
                            }}
                        />
                    ) : (
                        <div className={styles['time-tracking__create-selected']}>
                            <Field
                                name="project_contract"
                                fieldProps={{
                                    label: '',
                                    options: {
                                        endpoint:
                                            '/api/contracts?filters[status][in][]=in_progress&filters[is_timetrackable][equals]=true',
                                        mapData: {
                                            value: 'iri',
                                            label: '{project_name} - {name}'
                                        }
                                    },
                                    clearable: false
                                }}
                                fieldAttr={{
                                    id: 'project_contract',
                                    clearable: false
                                }}
                                validate={valueValidation([{ validator: 'required' }])}
                                component={Autocomplete}
                            />
                        </div>
                    )}

                    {!formValues?.project_contract && (
                        <Dropdown opened={dropdownOpenStatus.project_contract}>
                            <Field
                                name="project_contract"
                                fieldProps={{
                                    label: 'Contract',
                                    options: {
                                        endpoint:
                                            '/api/contracts?filters[status][in][]=in_progress&filters[is_timetrackable][equals]=true',
                                        mapData: {
                                            value: 'iri',
                                            label: '{project_name} - {name}'
                                        }
                                    }
                                }}
                                fieldAttr={{
                                    id: 'project_contract',
                                    required: true
                                }}
                                validate={valueValidation([{ validator: 'required' }])}
                                component={Autocomplete}
                            />
                        </Dropdown>
                    )}
                </div>

                {formValues?.project_contract && projectContract && (
                    <div className={styles['time-tracking__create-work-type']}>
                        {!formValues?.work_type ? (
                            <Button
                                className={styles['time-tracking__create-button']}
                                label="Choose Work type"
                                iconName="plus"
                                variation="tertiary"
                                onClick={() => toggleDropdown('work_type')}
                            />
                        ) : (
                            <div className={styles['time-tracking__create-selected']}>
                                <Field
                                    name="work_type"
                                    fieldProps={{
                                        label: '',
                                        options: workTypeOptions?.length
                                            ? workTypeOptions
                                            : {
                                                  endpoint:
                                                      '/api/work-types?filters[visible][equals]=true',
                                                  mapData: {
                                                      value: 'iri',
                                                      label: 'name'
                                                  }
                                              },
                                        clearable: false
                                    }}
                                    component={workTypeOptions?.length ? Select : Autocomplete}
                                    fieldAttr={{
                                        id: 'work_type',
                                        clearable: false
                                    }}
                                    validate={valueValidation([{ validator: 'required' }])}
                                />
                            </div>
                        )}

                        {!formValues?.work_type && (
                            <Dropdown
                                opened={dropdownOpenStatus.work_type}
                                style={{ width: '380px' }}
                            >
                                <Field
                                    name="work_type"
                                    fieldProps={{
                                        menuIsOpen: dropdownOpenStatus.work_type,
                                        label: 'Choose Work Type',
                                        options: workTypeOptions?.length
                                            ? workTypeOptions
                                            : {
                                                  endpoint:
                                                      '/api/work-types?filters[visible][equals]=true',
                                                  mapData: {
                                                      value: 'iri',
                                                      label: 'name'
                                                  }
                                              }
                                    }}
                                    fieldAttr={{
                                        id: 'work_type',
                                        required: true,
                                        clearable: false
                                    }}
                                    validate={valueValidation([{ validator: 'required' }])}
                                    component={workTypeOptions?.length ? Select : Autocomplete}
                                />
                            </Dropdown>
                        )}
                    </div>
                )}

                {formValues?.work_type && !!projectContractStages?.length && (
                    <div className={styles['time-tracking__create-work-type']}>
                        {!formValues?.stage ? (
                            <Button
                                className={styles['time-tracking__create-button']}
                                label="Choose Project Stage"
                                iconName="plus"
                                variation="tertiary"
                                onClick={() => toggleDropdown('stage')}
                            />
                        ) : (
                            <div className={styles['time-tracking__create-selected']}>
                                <Field
                                    name="stage"
                                    fieldProps={{
                                        label: '',
                                        options: projectContractStages?.map((item) => ({
                                            label: item?.name,
                                            value: item?.iri
                                        })),
                                        clearable: true
                                    }}
                                    component={Select}
                                    fieldAttr={{
                                        id: 'stage',
                                        clearable: false,
                                        required: !!timeLogsRequireProjectStageSelection
                                    }}
                                    validate={
                                        timeLogsRequireProjectStageSelection
                                            ? valueValidation([{ validator: 'required' }])
                                            : null
                                    }
                                />
                            </div>
                        )}

                        {!formValues?.stage && !!projectContractStages?.length && (
                            <Dropdown opened={dropdownOpenStatus.stage}>
                                <Field
                                    name="stage"
                                    fieldProps={{
                                        menuIsOpen: dropdownOpenStatus.stage,
                                        label: 'Choose Project Stage',
                                        options: projectContractStages?.map((item) => ({
                                            label: item?.name,
                                            value: item?.iri
                                        }))
                                    }}
                                    fieldAttr={{
                                        id: 'stage',
                                        required: !!timeLogsRequireProjectStageSelection,
                                        clearable: false
                                    }}
                                    validate={
                                        timeLogsRequireProjectStageSelection
                                            ? valueValidation([{ validator: 'required' }])
                                            : null
                                    }
                                    component={Select}
                                />
                            </Dropdown>
                        )}
                    </div>
                )}
            </div>

            {formValues?.work_type && (
                <Button
                    className={styles['time-tracking__create-submit']}
                    label="Add"
                    submit
                    loading={submitting}
                    disabled={invalid || pristine}
                />
            )}
        </Form>
    );
};

TimeTrackingCreateForm.defaultProps = {
    form: 'TimeTrackingForm',
    invalid: false,
    pristine: false,
    submitting: false,
    handleSubmit: () => {},
    onSubmit: () => {}
};

TimeTrackingCreateForm.propTypes = {
    form: PropTypes.string,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func
};

export default reduxForm({ form: 'TimeTrackingForm', enableReinitialize: true })(
    TimeTrackingCreateForm
);
