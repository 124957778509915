import { takeEvery, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { parseParamsForApi } from 'erpcore/components/Listing/Listing.utils';
import { actions as dashboardsListingActions } from 'erpcore/screens/Dashboards/DashboardsListing.reducer';
import { actions as dashboardsActions } from './Dashboard.reducer';

/**
 * Fetch Dashboards
 * @param  {Object} promise
 * @param params
 * @return {string}
 */
export function* fetchDashboards({ promise, params }) {
    try {
        const fetchDashboardsAPI = yield restClient.get(
            'api/dashboards?include=owner,users.user,departments.department&pagination=false',
            {
                params: parseParamsForApi(params)
            }
        );
        yield put({
            type: dashboardsActions.FETCHING_SUCCESSFUL_DASHBOARDS
        });
        yield put({
            type: dashboardsActions.STORE_DASHBOARDS_DATA,
            response: dto(fetchDashboardsAPI?.data)
        });
        const listOfDashboards = dto(fetchDashboardsAPI?.data)?.data;
        const cachedCurrentDashboardIRI = localStorage.getItem('currentDashboard');
        let currentDashboard = listOfDashboards.find(
            dashboard => dashboard?.iri === cachedCurrentDashboardIRI
        );

        if (!currentDashboard) {
            currentDashboard = listOfDashboards.find(dashboard => dashboard?.active);
        }

        localStorage.setItem('currentDashboard', currentDashboard?.iri || null);

        yield put({
            type: dashboardsActions.SET_CURRENT_DASHBOARD,
            iri: currentDashboard?.iri || null
        });

        if (promise) yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dashboardsActions.FETCHING_FAILED_DASHBOARDS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        if (promise) yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Dashboard data
 * @param promise
 * @param data
 * @param  {Object} iri iri of Dashboard
 * @param params
 * @return {Object} Response from API
 */
export function* updateDashboard({ promise, data, iri, params }) {
    try {
        const updateDashboardApi = yield restClient.put(iri, data, { params });

        yield put({
            type: dashboardsActions.UPDATE_DASHBOARD_SUCCESSFUL
        });

        yield put({
            type: dashboardsActions.STORE_DASHBOARD_DATA,
            response: dto(updateDashboardApi?.data)?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dashboardsActions.UPDATE_DASHBOARD_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* createDashboard({ promise, formData }) {
    try {
        const createDashboardAPI = yield restClient.post(`/api/dashboards`, formData);
        const createDashboardDTO = dto(createDashboardAPI?.data);
        yield put({
            type: dashboardsActions.CREATE_DASHBOARD_SUCCESSFUL
        });
        const newActiveDashboard = createDashboardDTO?.data?.iri;
        localStorage.setItem('currentDashboard', newActiveDashboard);

        yield put({
            type: dashboardsActions.STORE_DATA_OF_CREATED_DASHBOARD,
            response: createDashboardDTO
        });

        yield put({
            type: dashboardsActions.SET_CURRENT_DASHBOARD,
            iri: newActiveDashboard
        });

        yield put({
            promise,
            type: dashboardsActions.SET_ACTIVE_DASHBOARD_TO_USER,
            iri: formData?.owner,
            data: { active_dashboard: newActiveDashboard }
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dashboardsActions.CREATE_DASHBOARD_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Set Dashboard as favorite/unfavorite
 * @param promise
 * @param data
 * @param  {Object} iri iri of Dashboard
 * @return {Object} Response from API
 */
export function* favoriteDashboard({ promise, id, data }) {
    try {
        const favoriteDashboardApi = yield restClient.patch(
            `/api/dashboards/${id}/favorite?include=owner,users.user,departments.department`,
            data
        );

        yield put({
            type: dashboardsActions.SET_DASHBOARD_AS_FAVORITE_SUCCESSFUL
        });

        yield put({
            type: dashboardsActions.STORE_DASHBOARD_DATA,
            response: dto(favoriteDashboardApi?.data)?.data
        });

        yield put({
            type: dashboardsListingActions.STORE_DASHBOARD_FAVORITE_DATA,
            response: dto(favoriteDashboardApi?.data)?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dashboardsActions.SET_DASHBOARD_AS_FAVORITE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Set active dashboard to user
 * @param promise
 * @param data
 * @param  iri iri of User
 */
export function* setActiveDashboardToUser({ promise, data, iri }) {
    try {
        restClient.patch(`${iri}?include=activeDashboard`, data);
    } catch (error) {
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Remove currently editing user from dashboard
 * @param promise
 * @param data
 * @param  iri iri of Dashboard
 */
export function* removeCurrentlyEditingUserFromDashboard({ promise, data, iri }) {
    try {
        restClient.patch(`${iri}?include=currentlyEditingUser`, data);
    } catch (error) {
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeEvery(dashboardsActions.START_FETCHING_DASHBOARDS, fetchDashboards),
    takeEvery(dashboardsActions.START_UPDATE_DASHBOARD, updateDashboard),
    takeEvery(dashboardsActions.START_CREATE_DASHBOARD, createDashboard),
    takeEvery(dashboardsActions.SET_DASHBOARD_AS_FAVORITE, favoriteDashboard),
    takeEvery(dashboardsActions.SET_ACTIVE_DASHBOARD_TO_USER, setActiveDashboardToUser),
    takeEvery(
        dashboardsActions.REMOVE_CURRENTLY_EDITING_USER_FROM_DASHBOARD,
        removeCurrentlyEditingUserFromDashboard
    )
];
