import { takeLatest, delay, put, call } from 'redux-saga/effects';
import { takeLatestPerProps } from 'erpcore/utils/sagaUtils';
import restClient from 'erpcore/api/restClient';

import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as projectMilestonesActions } from './ProjectMilestones.reducer';

/**
 * Create projectMilestone
 * @param  promise {Object}
 * @param  formData {Object}
 * @return {void}
 */
export function* createProjectMilestone({ promise, formData }) {
    try {
        // Create projectMilestone
        const createProjectMilestoneAPI = yield restClient.post(`api/project/milestones`, formData);
        const milestoneData = dto(createProjectMilestoneAPI?.data);
        yield put({
            type: projectMilestonesActions.CREATE_PROJECT_MILESTONE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                ...milestoneData,
                notificationData: milestoneData?.data
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectMilestonesActions.CREATE_PROJECT_MILESTONE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Fetch projectMilestone
 * @param promise {Object}
 * @param iri {number} projectMilestone iri
 * @param params {Object}
 * @return {void}
 */
export function* fetchProjectMilestone({ promise, milestoneIri, params }) {
    try {
        const fetchProjectMilestoneAPI = yield restClient.get(milestoneIri, {
            params
        });
        const milestoneData = dto(fetchProjectMilestoneAPI?.data);
        yield put({
            type: projectMilestonesActions.FETCH_PROJECT_MILESTONE_SUCCESSFUL
        });
        yield put({
            type: projectMilestonesActions.STORE_PROJECT_MILESTONE_DATA,
            milestoneIri,
            response: milestoneData
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectMilestonesActions.FETCH_PROJECT_MILESTONE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Update projectMilestone single data
 * @param  promise {Object}
 * @param  formData {Object}
 * @param  iri {number} projectMilestone iri
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* updateSingleProjectMilestone({
    promise,
    formData,
    milestoneIri,
    debounceDelay = 0
}) {
    yield delay(debounceDelay);
    yield put({
        type: projectMilestonesActions.START_UPDATE_PROJECT_MILESTONE
    });
    try {
        const updateSingleProjectMilestoneAPI = yield restClient.put(milestoneIri, formData);
        const milestoneData = dto(updateSingleProjectMilestoneAPI?.data);
        yield put({
            type: projectMilestonesActions.UPDATE_PROJECT_MILESTONE_SUCCESSFUL
        });
        yield put({
            type: projectMilestonesActions.STORE_PROJECT_MILESTONE_DATA,
            milestoneIri,
            response: milestoneData
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                ...milestoneData,
                notificationData: milestoneData?.data
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectMilestonesActions.UPDATE_PROJECT_MILESTONE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Delete Single projectMilestone
 * @param  promise {Object}
 * @param  milestoneIri {string} projectMilestone iri
 * @param  formData {object}
 * @return {void}
 */
export function* deleteSingleProjectMilestone({ promise, milestoneIri, formData }) {
    try {
        const deleteSingleProjectMilestoneAPI = yield restClient.delete(milestoneIri, {
            params: formData
        });
        yield put({
            type: projectMilestonesActions.DELETE_PROJECT_MILESTONE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleProjectMilestoneAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectMilestonesActions.DELETE_PROJECT_MILESTONE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Sort multiple projectMilestones
 * @param  promise {Object}
 * @param  projectIri {string}
 * @param  formData {Object} Should contain property 'positions' with array of projectMilestone positions, Example `positions: [{position: 1, object_id: 6}, {...}, ...]`
 * @param  debounceDelay {number} Number of milliseconds to delay execution
 * @return {void}
 */
export function* sortProjectMilestones({ promise, projectIri, formData, debounceDelay = 0 }) {
    yield delay(debounceDelay);
    yield put({
        type: projectMilestonesActions.START_SORT_PROJECT_MILESTONES
    });
    try {
        const updateSingleProjectMilestoneAPI = yield restClient.patch(
            `${projectIri}/milestones/position`,
            formData
        );
        yield put({
            type: projectMilestonesActions.SORT_SUCCESSFUL_PROJECT_MILESTONES
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleProjectMilestoneAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectMilestonesActions.SORT_FAILED_PROJECT_MILESTONES
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Fetch milestone data
 * @param  promise {Object}
 * @param  milestoneIri {string}
 * @return {void}
 */
export function* fetchMilestoneTimelogData({ promise, milestoneIri }) {
    try {
        const milestoneTimelogAPI = yield restClient.get(
            `/api/time-logs?filters[milestone][equals]=${milestoneIri}`
        );
        yield put({
            type: projectMilestonesActions.FETCHING_SUCCESSFUL_MILESTONE_TIMELOGS_DATA,
            response: dto(milestoneTimelogAPI?.data),
            milestoneIri
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectMilestonesActions.FETCHING_FAILED_MILESTONE_TIMELOGS_DATA
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(projectMilestonesActions.START_CREATE_PROJECT_MILESTONE, createProjectMilestone),
    takeLatest(projectMilestonesActions.START_FETCHING_PROJECT_MILESTONE, fetchProjectMilestone),
    takeLatestPerProps(
        'milestoneIri',
        projectMilestonesActions.REQUEST_UPDATE_PROJECT_MILESTONE,
        updateSingleProjectMilestone
    ),
    takeLatest(
        projectMilestonesActions.START_DELETE_PROJECT_MILESTONE,
        deleteSingleProjectMilestone
    ),
    takeLatest(projectMilestonesActions.REQUEST_SORT_PROJECT_MILESTONES, sortProjectMilestones),
    takeLatest(
        projectMilestonesActions.START_FETCHING_MILESTONE_TIMELOGS_DATA,
        fetchMilestoneTimelogData
    )
];
