export const actions = {
    START_FETCHING_RESOURCE_ALLOCATIONS: 'START_FETCHING_RESOURCE_ALLOCATIONS',
    FETCHING_SUCCESSFUL_RESOURCE_ALLOCATIONS: 'FETCHING_SUCCESSFUL_RESOURCE_ALLOCATIONS',
    FETCHING_FAILED_RESOURCE_ALLOCATIONS: 'FETCHING_FAILED_RESOURCE_ALLOCATIONS',

    CLEAR_RESOURCE_ALLOCATIONS: 'CLEAR_RESOURCE_ALLOCATIONS',

    FETCHING_SUCCESSFUL_ADDITIONAL_RESOURCE_ALLOCATIONS:
        'FETCHING_SUCCESSFUL_ADDITIONAL_RESOURCE_ALLOCATIONS',

    START_FETCHING_CAPACITY_MANAGEMENT: 'START_FETCHING_CAPACITY_MANAGEMENT',
    FETCHING_SUCCESSFUL_CAPACITY_MANAGEMENT: 'FETCHING_SUCCESSFUL_CAPACITY_MANAGEMENT',
    FETCHING_FAILED_CAPACITY_MANAGEMENT: 'FETCHING_FAILED_CAPACITY_MANAGEMENT',

    FETCHING_SUCCESSFUL_ADDITIONAL_CAPACITY_MANAGEMENT:
        'FETCHING_SUCCESSFUL_ADDITIONAL_CAPACITY_MANAGEMENT'
};

export const initialState = {};

export default (state = initialState, { type, stages, users, params, id }) => {
    const hasUserFilter = params?.['filters[users][equals]'];

    // function to group the stages per user
    const groupedStagesPerUser = (acc, curr) => {
        acc = { ...acc };

        if (curr?.users?.length) {
            const filteredUsers = curr?.users.filter((user) => {
                if (hasUserFilter) return user?.iri === hasUserFilter;
                return user;
            });

            filteredUsers.forEach((user) => {
                const userIri = user?.iri;
                if (acc?.[userIri]) {
                    const stageAlreadyExistsForThisUser = acc?.[userIri]?.find(
                        (item) => item?.iri === curr?.iri
                    );
                    if (!stageAlreadyExistsForThisUser) {
                        acc[userIri] = [
                            ...acc[userIri],
                            {
                                ...curr,
                                user: users?.find((userData) => userData?.iri === userIri)
                            }
                        ];
                    }
                } else {
                    acc[userIri] = [
                        {
                            ...curr,
                            user: users?.find((userData) => userData?.iri === userIri)
                        }
                    ];
                }
            });
        }

        return acc;
    };

    switch (type) {
        case actions.START_FETCHING_RESOURCE_ALLOCATIONS: {
            return {
                ...state,
                [id]: {
                    ...state?.[id],
                    resourceAllocationFetching: true
                }
            };
        }
        case actions.FETCHING_SUCCESSFUL_RESOURCE_ALLOCATIONS: {
            return {
                ...state,
                [id]: {
                    ...state?.[id],
                    resourceAllocationFetching: false,
                    resourceAllocationData: stages?.reduce(groupedStagesPerUser, {}) || null,
                    resourceAllocationUsers: users
                }
            };
        }
        case actions.FETCHING_FAILED_RESOURCE_ALLOCATIONS: {
            return {
                ...state,
                [id]: {
                    ...state?.[id],
                    resourceAllocationFetching: false
                }
            };
        }

        case actions.FETCHING_SUCCESSFUL_ADDITIONAL_RESOURCE_ALLOCATIONS: {
            return {
                ...state,
                [id]: {
                    ...state?.[id],
                    resourceAllocationFetching: false,
                    resourceAllocationData:
                        stages?.reduce(groupedStagesPerUser, {
                            ...state?.[id]?.resourceAllocationData
                        }) || null,
                    resourceAllocationUsers: users
                }
            };
        }

        case actions.START_FETCHING_CAPACITY_MANAGEMENT: {
            return {
                ...state,
                [id]: {
                    ...state?.[id],
                    capacityManagementFetching: true
                }
            };
        }
        case actions.FETCHING_SUCCESSFUL_CAPACITY_MANAGEMENT: {
            return {
                ...state,
                [id]: {
                    ...state?.[id],
                    capacityManagementFetching: false,
                    capacityManagementUsers: users
                }
            };
        }
        case actions.FETCHING_FAILED_CAPACITY_MANAGEMENT: {
            return {
                ...state,
                [id]: {
                    ...state?.[id],
                    capacityManagementFetching: false
                }
            };
        }

        case actions.FETCHING_SUCCESSFUL_ADDITIONAL_CAPACITY_MANAGEMENT: {
            return {
                ...state,
                [id]: {
                    ...state?.[id],
                    capacityManagementFetching: false,
                    capacityManagementUsers: users
                }
            };
        }

        case actions.CLEAR_RESOURCE_ALLOCATIONS: {
            return {
                ...state,
                [id]: {
                    ...state?.[id],
                    resourceAllocationFetching: false,
                    resourceAllocationData: null
                }
            };
        }

        default:
            return state;
    }
};
