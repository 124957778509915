import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as salesOrdersActions } from './SalesOrders.reducer';

const includeString =
    'include=workflowTransitionLogs,workflowTransitionLogs.user,workflowTransitionLogs.comment,company,department,project,contract,service,paymentMethod,paymentTerm,additionalSubscribers,attachments';

/**
 * Delete Sales Order
 * @param  {Object} iri of a Sales Order
 * @return {Object} Response from API
 */
export function* deleteSalesOrder({ promise, iri }) {
    try {
        const deleteSalesOrderAPI = yield restClient.delete(iri);
        yield put({
            type: salesOrdersActions.DELETE_SALES_ORDER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSalesOrderAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: salesOrdersActions.DELETE_SALES_ORDER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Sales Order
 * @param  {Object} promise
 * @return {Object} formData
 */
export function* createSalesOrder({ promise, formData }) {
    try {
        const createSalesOrderAPI = yield restClient.post(`/api/sales-orders`, formData);
        yield put({
            type: salesOrdersActions.CREATE_SALES_ORDER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createSalesOrderAPI?.data
        });
        yield call(promise.resolve, createSalesOrderAPI?.data);
    } catch (error) {
        yield put({
            type: salesOrdersActions.CREATE_SALES_ORDER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Sales Order
 * @param  {Object} promise
 * @return {string} iri Sales Order iri
 */
export function* fetchSalesOrder({ promise, iri }) {
    try {
        const fetchSalesOrderAPI = yield restClient.get(`${iri}?${includeString}`);
        yield put({
            type: salesOrdersActions.FETCH_SALES_ORDER_SUCCESSFUL
        });
        yield put({
            type: salesOrdersActions.STORE_SALES_ORDER_DATA,
            iri,
            response: dto(fetchSalesOrderAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: salesOrdersActions.FETCH_SALES_ORDER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Sales Order single data
 * @param  {Object} iri iri of Sales Order
 * @return {Object} Response from API
 */
export function* updateSingleSalesOrder({ promise, formData, iri }) {
    try {
        const updateSingleSalesOrderAPI = yield restClient.put(`${iri}?${includeString}`, formData);

        yield put({
            type: salesOrdersActions.UPDATE_SALES_ORDER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleSalesOrderAPI?.data
        });
        yield put({
            type: salesOrdersActions.STORE_SALES_ORDER_DATA,
            iri,
            response: dto(updateSingleSalesOrderAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: salesOrdersActions.UPDATE_SALES_ORDER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Change Status Sales Order single data
 * @param  {Object} iri of an Sales Order
 * @return {Object} Response from API
 */
export function* changeStatusSalesOrder({ promise, formData, iri }) {
    try {
        const changeStatusSalesOrderAPI = yield restClient.patch(
            `${iri}/change-status?${includeString}`,
            formData
        );
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: changeStatusSalesOrderAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: salesOrdersActions.SALES_ORDER_CHANGE_STATUS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Refresh Timelogs
 * @param {String} id - Sales order ID
 * @return {Object} Response from API
 */
export function* refreshTimeLogs({ promise, id }) {
    try {
        yield restClient.patch(`api/sales-orders/${id}/refresh-timelogs`, {});

        yield put({
            type: salesOrdersActions.REFRESHING_TIMELOGS_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: salesOrdersActions.REFRESHING_TIMELOGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const salesOrdersSaga = [
    takeLatest(salesOrdersActions.START_DELETE_SALES_ORDER, deleteSalesOrder),
    takeLatest(salesOrdersActions.START_CREATE_SALES_ORDER, createSalesOrder),
    takeLatest(salesOrdersActions.START_FETCHING_SALES_ORDER, fetchSalesOrder),
    takeLatest(salesOrdersActions.START_UPDATE_SALES_ORDER, updateSingleSalesOrder),
    takeLatest(salesOrdersActions.START_SALES_ORDER_CHANGE_STATUS, changeStatusSalesOrder),
    takeLatest(salesOrdersActions.START_REFRESHING_TIMELOGS, refreshTimeLogs)
];

export default salesOrdersSaga;
