import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

//  Importing Reducers
// General Reducers
import { reducer as form } from 'redux-form';
import authManager from 'erpcore/utils/AuthManager/AuthManager.reducer';
import brandingManager from 'erpcore/utils/BrandingManager/BrandingManager.reducer';
import routerManager from 'erpcore/utils/RouterManager/RouterManager.reducer';
import layoutManager from 'erpcore/utils/LayoutManager/LayoutManager.reducer';
import notificationManager from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import budgetErrorManager from 'erpcore/utils/BudgetErrorManager/BudgetErrorManager.reducer';
import forgotPassword from 'erpcore/screens/ForgotPassword/ForgotPassword.reducer';
import imageManager from 'erpcore/components/ImageManager/ImageManager.reducer';
import myAccount from 'erpcore/screens/MyAccount/MyAccount.reducer';
import activityLog from 'erpcore/components/ActivityLog/ActivityLog.reducer';
import comments from 'erpcore/components/Comments/Comments.reducer';
import listing from 'erpcore/components/Listing/Listing.reducer';
import dashboards from 'erpcore/screens/Dashboard/Dashboard.reducer';
import dashboardWidgets from 'erpcore/screens/Dashboard/DashboardWidgets.reducer';
import dashboardsListing from 'erpcore/screens/Dashboards/DashboardsListing.reducer';
// Project Reducers - REMEMBER TO ORDER THEM ALPHABETICALLY //
import accounting from 'erpcore/screens/Accounting/Accounting.reducer';
import approvals from 'erpcore/screens/Approvals/Approvals.reducer';
import companies from 'erpcore/screens/Companies/Companies.reducer';
import companyContacts from 'erpcore/screens/Companies/screens/CompanyContacts/CompanyContacts.reducer';
import contacts from 'erpcore/screens/Contacts/Contacts.reducer';
import {
    departments,
    divisions,
    jobTitles,
    offices,
    options,
    organization,
    paymentMethods,
    paymentTerms,
    permissions,
    roles,
    scheduledCommands,
    services,
    tags,
    workTypes,
    propsSettings,
    timeOffTypes,
    bundleTemplates
} from 'erpcore/screens/Settings/Settings.reducer'; // Settings
import employmentRecords from 'erpcore/screens/Users/screens/UserEmploymentRecords/UserEmploymentRecords.reducer';
import projects from 'erpcore/screens/Projects/Projects.reducer';
import {
    employeeRecordsReport,
    timeLogsReport,
    userReports
} from 'erpcore/screens/Reports/Reports.reducer'; // Reports
import scheduling from 'erpcore/screens/Scheduling/Scheduling.reducers';
import projectApprovals from 'erpcore/screens/Projects/screens/ProjectApprovals/ProjectApprovals.reducer';
import projectContracts from 'erpcore/screens/Projects/screens/ProjectContracts/ProjectContracts.reducer';
import projectContractDashboard from 'erpcore/screens/Projects/screens/ProjectContracts/screens/ProjectContractDashboard/ProjectContractDashboard.reducer';
import projectContractTimeline from 'erpcore/screens/Projects/screens/ProjectManage/screens/ProjectContractTimeline/ProjectContractTimeline.reducer';
import projectDashboard from 'erpcore/screens/Projects/screens/ProjectDashboard/ProjectDashboard.reducer';
import projectMilestones from 'erpcore/screens/Projects/components/ProjectMilestones/ProjectMilestones.reducer';
import projectFeatures from 'erpcore/screens/Projects/components/ProjectFeatures/ProjectFeatures.reducer';
import propsWidget from 'erpcore/components/PropsWidget/PropsWidget.reducer';
import props from 'erpcore/screens/Props/Props.reducers';
import salesOrders from 'erpcore/screens/SalesOrders/SalesOrders.reducer';
import timeLogs from 'erpcore/screens/TimeLogs/TimeLogs.reducer';
import timeOff from 'erpcore/screens/TimeOff/TimeOff.reducer';
import timeOffCalendar from 'erpcore/screens/TimeOff/screens/TimeOffCalendar/TimeOffCalendar.reducer';
import timeTracking from 'erpcore/components/TimeTracking/TimeTracking.reducer';
import users from 'erpcore/screens/Users/Users.reducer';
import calendar from 'erpcore/components/Calendar/Calendar.reducer';
import pendingTimeLogs from 'erpcore/screens/Projects/screens/ProjectManage/components/ProjectContractPendingTimeLogsListing/ProjectContractPendingTimeLogsListing.reducer';
import freshbooks from 'erpcore/screens/Integrations/Freshbooks/Freshbooks.reducer';
import sage from 'erpcore/screens/Integrations/Sage/Sage.reducer';
import quickbooks from 'erpcore/screens/Integrations/QuickBooks/QuickBooks.reducer';
import timeOffBalance from 'erpcore/screens/TimeOffBalance/TimeOffBalance.reducer';
import profitabilityReports from 'erpcore/screens/Reports/ProfitabilityReports/ProfitabilityReports.reducer';
import efficiencyReports from 'erpcore/screens/Reports/EfficiencyReports/EfficiencyReports.reducer';
import jira from 'erpcore/screens/Integrations/Jira/Jira.reducer';
import projectContractSettingsJiraIssueListing from 'erpcore/screens/Projects/screens/ProjectManage/components/ProjectContractSettingsForm/ProjectContractSettingsJiraIssueListing.reducer';
import select from 'erpcore/components/Form/components/SelectNew/Select.reducer';
import projectContractStatusWidget from 'erpcore/screens/Dashboard/components/ProjectContractsStatusWidget/ProjectContractsStatusWidget.reducer';
import projectContractSettingsJiraBulkIssuesListing from 'erpcore/screens/Projects/screens/ProjectManage/components/ProjectContractSettingsForm/components/AddBulkIssues/AddBulkIssues.reducer';

//  Combining all existing reducers
const appReducer = combineReducers({
    authManager,
    brandingManager,
    routerManager,
    layoutManager,
    notificationManager,
    budgetErrorManager,
    activityLog,
    comments,
    listing,
    form,
    forgotPassword,
    imageManager,
    myAccount,
    dashboards,
    dashboardWidgets, // system reducers end
    dashboardsListing,
    accounting,
    approvals,
    companies,
    companyContacts,
    contacts,
    departments,
    divisions,
    employeeRecordsReport,
    employmentRecords,
    jobTitles,
    offices,
    options,
    organization,
    paymentMethods,
    paymentTerms,
    permissions,
    projects,
    projectApprovals,
    projectContracts,
    projectContractTimeline,
    projectContractDashboard,
    projectDashboard,
    projectMilestones,
    projectFeatures,
    propsSettings,
    timeOffTypes,
    propsWidget,
    props,
    roles,
    scheduling,
    salesOrders,
    scheduledCommands,
    services,
    timeLogs,
    timeLogsReport,
    timeOff,
    timeOffCalendar,
    timeTracking,
    tags,
    users,
    userReports,
    workTypes,
    calendar,
    pendingTimeLogs,
    bundleTemplates,
    freshbooks,
    timeOffBalance,
    efficiencyReports,
    profitabilityReports,
    jira,
    select,
    quickbooks,
    projectContractStatusWidget,
    sage,
    projectContractSettingsJiraIssueListing,
    projectContractSettingsJiraBulkIssuesListing
});

const rootReducer = (state, action) => {
    if (action.type === 'SIGN_OUT_SUCCESSFUL') {
        const stateCopy = state;
        storage.removeItem('persist:root');
        state = { brandingManager: stateCopy?.brandingManager };
    }

    return appReducer(state, action);
};

export default rootReducer;
