import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import base64 from 'base-64';
import { clone } from 'lodash';
import qs from 'qs';
import PropTypes from 'prop-types';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import ElementLoader from 'erpcore/components/ElementLoader';
import { getBulkActionsIris } from 'erpcore/components/Listing/Listing.selectors';
import { getSavedFiltersForListing } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import WithListingWrapper from './WithListingWrapper';
import { BulkActionsDropdown } from './components/BulkActions';
import Table from './components/Table';
import Pagination from './components/Pagination';
import Filter from './components/Filter';
import FilterTags from './components/FilterTags';
import Search from './components/Search';
import ShowingResults from './components/ShowingResults';
import CustomListingColumns from './components/CustomListingColumns';
import './Listing.scss';

const listOfUrlFilterKeys = ['filter', 'order_by', 'q', 'page', 'limit'];

/**
 * Listing
 */
const Listing = ({
    table,
    meta,
    loading,
    children,
    title,
    creationButton,
    name,
    reducerName,
    hideHeader,
    hideFilters,
    hideSearch,
    hideBody,
    hideFooter,
    showCustomListingColumns,
    asideSpaceBetween,
    onListingConfigUpdate,
    initialFetch,
    fetchTrigger,
    className,
    enableSavedFilters
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [queryParams, setQueryParams] = useState({});
    const bulkActionsIris = useSelector((state) => getBulkActionsIris(state, reducerName)) || [];
    const savedFiltersForCurrentListing =
        useSelector((state) => getSavedFiltersForListing(state, name)) || {};
    const [tableFiltersFetching, setTableFiltersFetching] = useState(false);
    const [areFiltersFetched, setAreFiltersFetched] = useState(false);
    const areInitialFiltersSet = useRef(false);
    const isListingLoading = loading || tableFiltersFetching;

    const injectDefaultOrderByToAllParams = (params) => {
        //  If filters are not in URL bar create listing key and assign defaultSort
        if (table.defaultSort && !params[name]) {
            params[name] = {};
            params[name].order_by = { [table.defaultSort.sortable]: table.defaultSort.order };
        }
        return params;
    };

    const injectDefaultOrderByToListingParams = (params) => {
        //  Inject directly to listing params with name
        if (table.defaultSort && !params.order_by) {
            params.order_by = { [table?.defaultSort?.sortable]: table?.defaultSort?.order };
        }
        return params;
    };

    //  Get URL Parameters of all listings
    const getAllUrlParameters = (injectDefaultFilters = true) => {
        //  Substr beacuse of '?' in the begining of location search
        const urlParams = qs.parse(location.search.substr(1));
        const allDecodedParams = {};
        (Object.keys(urlParams) || []).forEach((listingName) => {
            const listingParams = clone(urlParams[listingName]);
            //  decode all base 64 filters to object
            if (listingParams?.filter) {
                listingParams.filter = qs.parse(base64.decode(listingParams.filter));
            }
            allDecodedParams[listingName] = clone(listingParams);
        });
        return injectDefaultFilters
            ? injectDefaultOrderByToAllParams(allDecodedParams)
            : allDecodedParams;
    };

    //  Get URL Parameters for this listing
    const getUrlParametersByName = (injectDefaultFilters = true) => {
        //  Substr beacuse of '?' in the begining of location search
        const urlParams = getAllUrlParameters(injectDefaultFilters);
        //  Pass only if params for this listing exists
        return urlParams[name] || {};
    };

    //  Update URL parameters
    const pushUrlParams = (params) => {
        //  Getting all existing
        const allExistingParams = clone(getAllUrlParameters());
        const newParams = clone(params);
        const allEncodedParams = {};
        //  Replace existing params with new ones
        allExistingParams[name] = newParams;
        //  encoding filter keys to hide them in url bar
        (Object.keys(allExistingParams) || []).forEach((listingName) => {
            const listingParams = clone(allExistingParams[listingName]);
            if (listingParams?.filter) {
                listingParams.filter = base64.encode(qs.stringify(listingParams.filter));
            }
            allEncodedParams[listingName] = clone(listingParams);
        });
        //  Pushing updated query params to url bar
        navigate(
            `${location.pathname}?${qs.stringify(allEncodedParams, { encodeValuesOnly: true })}`,
            {
                changedByListing: true
            }
        );
    };

    //  get falsy-free query params
    const getClearQueryParams = (params) => {
        const clearedParams = {};
        (Object.keys(params) || []).forEach((key) => {
            if (
                (params[key] || params[key] === false) &&
                //  if param is empty object (beacuse {} === true but in this case is not)
                !(typeof params[key] === 'object' && Object.keys(params[key]).length === 0)
            ) {
                clearedParams[key] = params[key];
            }
        });
        return clearedParams;
    };

    //  get merged existing query params with new ones
    const getCombinedListingParams = (params, mergeFilter = true) => {
        let combinedParams = {};
        const newParams = clone(params);
        const existingParams = getUrlParametersByName();
        //  merging filters
        if (existingParams?.filter && newParams?.filter && mergeFilter) {
            (Object.keys(existingParams.filter) || []).forEach((key) => {
                if (!newParams.filter[key]) newParams.filter[key] = [];
                Array.prototype.push.apply(newParams.filter[key], existingParams.filter[key]);
            });
        }
        combinedParams = Object.assign(existingParams, newParams);
        return combinedParams;
    };

    const fetchTableFilters = () => {
        setTableFiltersFetching(true);
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                name,
                type: authActions.START_FETCHING_TABLE_FILTERS
            });
        })
            .then(() => {
                setTableFiltersFetching(false);
            })
            .catch((error) => {
                setTableFiltersFetching(false);
                return error;
            });
    };

    const getParams = () => {
        let output = {};

        if (!areInitialFiltersSet.current) {
            // url filters
            const params = getClearQueryParams(getUrlParametersByName(false)); // params without sort defaults
            if (listOfUrlFilterKeys.some((item) => params?.[item])) {
                output = getClearQueryParams(getUrlParametersByName(true)); // params wit sort defaults
            }

            // saved filters
            else if (
                enableSavedFilters &&
                savedFiltersForCurrentListing?.filters &&
                Object.keys(savedFiltersForCurrentListing?.filters || {}).length
            ) {
                const orderBy = savedFiltersForCurrentListing?.order_by;
                output = {
                    ...(orderBy ? { order_by: orderBy } : null),
                    filter: savedFiltersForCurrentListing.filters
                };
            }

            // initial/default filters
            else {
                const hasDefaultFilters =
                    table.defaultFilters && Object.keys(table.defaultFilters || {}).length;
                const hasDefaultSort =
                    table.defaultSort && Object.keys(table.defaultSort || {}).length;
                if (hasDefaultFilters || hasDefaultSort) {
                    output = {
                        ...getClearQueryParams(getUrlParametersByName(true)), // sort/order
                        ...(hasDefaultFilters ? { filter: table.defaultFilters } : null) // filters
                    };
                }
            }
        } else {
            const params = getClearQueryParams(getUrlParametersByName(true)); // params with sort defaults
            if (listOfUrlFilterKeys.some((item) => params?.[item])) {
                output = params;
            }
        }

        areInitialFiltersSet.current = true;

        return output;
    };

    const onChange = (params, mergeFilters = true) => {
        const derivatedParams = injectDefaultOrderByToListingParams(
            getClearQueryParams(getCombinedListingParams(params, mergeFilters))
        );
        setQueryParams(derivatedParams);
        onListingConfigUpdate(derivatedParams);
        pushUrlParams(derivatedParams);

        if (enableSavedFilters) {
            if (
                (derivatedParams?.filter &&
                    Object.keys(derivatedParams?.filter).every(
                        (key) => !derivatedParams?.filter[key].length
                    )) ||
                !derivatedParams?.filter
            ) {
                dispatch({
                    type: authActions.REMOVE_TABLE_FILTER,
                    id: savedFiltersForCurrentListing?.id
                });
            } else if (savedFiltersForCurrentListing?.id) {
                dispatch({
                    type: authActions.START_UPDATE_TABLE_FILTERS,
                    id: savedFiltersForCurrentListing?.id,
                    response: {
                        ...(derivatedParams?.order_by
                            ? { order_by: derivatedParams?.order_by }
                            : null),
                        filters: derivatedParams?.filter
                    }
                });
            } else {
                dispatch({
                    type: authActions.START_CREATE_TABLE_FILTERS,
                    response: {
                        listing: name,
                        ...(derivatedParams?.order_by
                            ? { order_by: derivatedParams?.order_by }
                            : null),
                        filters: derivatedParams?.filter
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (enableSavedFilters) {
            fetchTableFilters()
                .then(() => {
                    setAreFiltersFetched(true);
                })
                .catch(() => {
                    setAreFiltersFetched(true);
                });
        } else {
            setAreFiltersFetched(true);
        }
    }, []);

    // initial filters and fetch
    useEffect(() => {
        if (areFiltersFetched) {
            const isThisInitialFilterSetup = !areInitialFiltersSet.current;
            const params = getParams();
            setQueryParams(params);
            if (
                isThisInitialFilterSetup &&
                params?.filter &&
                Object.keys(params?.filter || {})?.length
            ) {
                pushUrlParams(params);
            }
            if (initialFetch) {
                onListingConfigUpdate(params);
            }
        }
    }, [fetchTrigger, areFiltersFetched]);

    return (
        <div className={`listing${className ? ` ${className}` : ''}`}>
            {isListingLoading && <ElementLoader overlay />}
            {hideHeader === false && (
                <div className="listing__header">
                    {(!hideFilters || !hideSearch || title) && (
                        <div className="listing__header-col listing__header-col--main">
                            {title && (
                                <div className="listing__header-col listing__header-col--title">
                                    {title}
                                </div>
                            )}
                            {creationButton && (
                                <div className="listing__header-col listing__header-col--button">
                                    {creationButton}
                                </div>
                            )}
                            {hideFilters === false && table.filters && (
                                <div className="listing__header-col listing__header-col--filter">
                                    <Filter
                                        form={`Filter${name}`}
                                        onSubmit={(filterData) => {
                                            //  Removing unneccesary filterData
                                            delete filterData.filterBy;
                                            //  Restarting pagination to 1
                                            filterData.page = 1;
                                            onChange(filterData);
                                        }}
                                        filterSchema={table.filters}
                                    />
                                </div>
                            )}
                            {hideSearch === false && (
                                <div className="listing__header-col listing__header-col--search">
                                    <Search queryParams={queryParams} onChangeSearch={onChange} />
                                </div>
                            )}
                        </div>
                    )}
                    {(bulkActionsIris.length > 1 || children || showCustomListingColumns) && (
                        <div
                            className={`listing__header-col listing__header-col--aside ${
                                asideSpaceBetween ? 'listing__header-col--space-between' : ''
                            }`}
                        >
                            <BulkActionsDropdown
                                reducerName={reducerName}
                                bulkActionsData={table.bulkActions}
                            />
                            {children}
                            {showCustomListingColumns && <CustomListingColumns data={table} />}
                        </div>
                    )}
                </div>
            )}
            <FilterTags
                filterSchema={table.filters}
                onChangeFilterTag={(filters) => onChange(filters, false)}
                queryParams={queryParams}
            />
            {!hideBody && (
                <div className="listing__body">
                    <Table
                        name={name}
                        reducerName={reducerName}
                        data={table}
                        onSortTable={onChange}
                        queryParams={queryParams}
                        loading={isListingLoading}
                    />
                </div>
            )}
            {hideFooter === false && meta?.totalItems >= 10 && (
                <div className="listing__footer">
                    <ShowingResults meta={meta} onChangeResultsPerPage={onChange} />
                    <Pagination meta={meta} onChangePagination={onChange} />
                </div>
            )}
        </div>
    );
};

Listing.defaultProps = {
    title: null,
    creationButton: null,
    hideHeader: false,
    hideSearch: false,
    hideFilters: false,
    hideBody: false,
    hideFooter: false,
    table: {
        data: [],
        schema: [],
        filters: []
    },
    meta: {},
    onListingConfigUpdate: () => {},
    initialFetch: true,
    loading: false,
    children: null,
    asideSpaceBetween: false,
    className: null,
    showCustomListingColumns: false,
    fetchTrigger: 'trigger',
    enableSavedFilters: false
};

Listing.propTypes = {
    name: PropTypes.string.isRequired,
    reducerName: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.string]),
    creationButton: PropTypes.node,
    hideHeader: PropTypes.bool,
    hideSearch: PropTypes.bool,
    hideFilters: PropTypes.bool,
    hideBody: PropTypes.bool,
    hideFooter: PropTypes.bool,
    table: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    onListingConfigUpdate: PropTypes.func,
    initialFetch: PropTypes.bool,
    loading: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
    asideSpaceBetween: PropTypes.bool,
    className: PropTypes.string,
    showCustomListingColumns: PropTypes.bool,
    fetchTrigger: PropTypes.string,
    enableSavedFilters: PropTypes.bool
};

export default WithListingWrapper(Listing);
