export const actions = {
    START_FETCHING_PROJECT_MILESTONES: 'START_FETCHING_PROJECT_MILESTONES',
    FETCHING_SUCCESSFUL_PROJECT_MILESTONES: 'FETCHING_SUCCESSFUL_PROJECT_MILESTONES',
    FETCHING_FAILED_PROJECT_MILESTONES: 'FETCHING_FAILED_PROJECT_MILESTONES',
    REQUEST_SORT_PROJECT_MILESTONES: 'REQUEST_SORT_PROJECT_MILESTONES',
    START_SORT_PROJECT_MILESTONES: 'START_SORT_PROJECT_MILESTONES',
    SORT_SUCCESSFUL_PROJECT_MILESTONES: 'SORT_SUCCESSFUL_PROJECT_MILESTONES',
    SORT_FAILED_PROJECT_MILESTONES: 'SORT_FAILED_PROJECT_MILESTONES',
    START_CREATE_PROJECT_MILESTONE: 'START_CREATE_PROJECT_MILESTONE',
    CREATE_PROJECT_MILESTONE_SUCCESSFUL: 'CREATE_PROJECT_MILESTONE_SUCCESSFUL',
    CREATE_PROJECT_MILESTONE_FAILED: 'CREATE_PROJECT_MILESTONE_FAILED',
    START_FETCHING_PROJECT_MILESTONE: 'START_FETCHING_PROJECT_MILESTONE',
    FETCH_PROJECT_MILESTONE_SUCCESSFUL: 'FETCH_PROJECT_MILESTONE_SUCCESSFUL',
    FETCH_PROJECT_MILESTONE_FAILED: 'FETCH_PROJECT_MILESTONE_FAILED',
    REQUEST_UPDATE_PROJECT_MILESTONE: 'REQUEST_UPDATE_PROJECT_MILESTONE',
    START_UPDATE_PROJECT_MILESTONE: 'START_UPDATE_PROJECT_MILESTONE',
    UPDATE_PROJECT_MILESTONE_SUCCESSFUL: 'UPDATE_PROJECT_MILESTONE_SUCCESSFUL',
    UPDATE_PROJECT_MILESTONE_FAILED: 'UPDATE_PROJECT_MILESTONE_FAILED',
    START_DELETE_PROJECT_MILESTONE: 'START_DELETE_PROJECT_MILESTONE',
    DELETE_PROJECT_MILESTONE_SUCCESSFUL: 'DELETE_PROJECT_MILESTONE_SUCCESSFUL',
    DELETE_PROJECT_MILESTONE_FAILED: 'DELETE_PROJECT_MILESTONE_FAILED',
    STORE_PROJECT_MILESTONE_DATA: 'STORE_PROJECT_MILESTONE_DATA',

    START_FETCHING_MILESTONE_TIMELOGS_DATA: 'START_FETCHING_MILESTONE_TIMELOGS_DATA',
    FETCHING_SUCCESSFUL_MILESTONE_TIMELOGS_DATA: 'FETCHING_SUCCESSFUL_MILESTONE_TIMELOGS_DATA',
    FETCHING_FAILED_MILESTONE_TIMELOGS_DATA: 'FETCHING_FAILED_MILESTONE_TIMELOGS_DATA'
};

export const initialState = {
    listingFetching: false,
    listingSorting: false,
    listingData: [],
    listingMeta: {},
    listingError: [],
    singleFetching: false,
    singleCreating: false,
    singleDeleting: false,
    singleUpdating: false,
    projectMilestones: [],
    submittingBulkAction: false,
    fetchingTimelogsData: false
};

export default (state = initialState, { type, milestoneIri, response }) => {
    switch (type) {
        case actions.START_FETCHING_MILESTONE_TIMELOGS_DATA: {
            return {
                ...state,
                fetchingTimelogsData: true
            };
        }
        case actions.START_FETCHING_PROJECT_MILESTONES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROJECT_MILESTONES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_SUCCESSFUL_MILESTONE_TIMELOGS_DATA: {
            return {
                ...state,
                fetchingTimelogsData: false,
                [milestoneIri]: response
            };
        }
        case actions.FETCHING_FAILED_PROJECT_MILESTONES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.FETCHING_FAILED_MILESTONE_TIMELOGS_DATA: {
            return {
                ...state,
                fetchingTimelogsData: false
            };
        }
        case actions.START_SORT_PROJECT_MILESTONES: {
            return {
                ...state,
                listingSorting: true
            };
        }
        case actions.SORT_SUCCESSFUL_PROJECT_MILESTONES: {
            return {
                ...state,
                listingSorting: false
                // listingResponse: response
            };
        }
        case actions.SORT_FAILED_PROJECT_MILESTONES: {
            return {
                ...state,
                listingSorting: false
                // listingError: response
            };
        }
        case actions.START_CREATE_PROJECT_MILESTONE: {
            return {
                ...state,
                singleCreating: true
            };
        }
        case actions.CREATE_PROJECT_MILESTONE_SUCCESSFUL: {
            return {
                ...state,
                singleCreating: false
            };
        }
        case actions.CREATE_PROJECT_MILESTONE_FAILED: {
            return {
                ...state,
                singleCreating: false
            };
        }
        case actions.START_DELETE_PROJECT_MILESTONE: {
            return {
                ...state,
                singleDeleting: true
            };
        }
        case actions.DELETE_PROJECT_MILESTONE_SUCCESSFUL: {
            return {
                ...state,
                singleDeleting: false
            };
        }
        case actions.DELETE_PROJECT_MILESTONE_FAILED: {
            return {
                ...state,
                singleDeleting: false
            };
        }
        case actions.START_FETCHING_PROJECT_MILESTONE: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_PROJECT_MILESTONE_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_PROJECT_MILESTONE_FAILED: {
            return {
                ...state,
                singleFetching: false,
                singleError: response
            };
        }

        case actions.START_UPDATE_PROJECT_MILESTONE: {
            return {
                ...state,
                singleUpdating: true
            };
        }
        case actions.UPDATE_PROJECT_MILESTONE_SUCCESSFUL: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.UPDATE_PROJECT_MILESTONE_FAILED: {
            return {
                ...state,
                singleUpdating: false,
                singleError: response
            };
        }
        case actions.STORE_PROJECT_MILESTONE_DATA: {
            return Object.assign({}, state, {
                [milestoneIri]: response.data
            });
        }
        default:
            return state;
    }
};
