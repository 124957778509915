import { all } from 'redux-saga/effects';
import { authManagerSaga } from 'erpcore/utils/AuthManager/AuthManager.saga';
import { brandingManagerSaga } from 'erpcore/utils/BrandingManager/BrandingManager.saga';
import { forgotPasswordSaga } from 'erpcore/screens/ForgotPassword/ForgotPassword.saga';
import imageManagerSaga from 'erpcore/components/ImageManager/ImageManager.saga';
import { myAccountSaga } from 'erpcore/screens/MyAccount/MyAccount.saga';
import activityLogSaga from 'erpcore/components/ActivityLog/ActivityLog.saga';
import { listingSaga } from 'erpcore/components/Listing/Listing.saga';
import { commentsSaga } from 'erpcore/components/Comments/Comments.saga';
import dashboardsSaga from 'erpcore/screens/Dashboard/Dashboard.saga';
import dashboardsListingSaga from 'erpcore/screens/Dashboards/DashboardsListing.saga';
import {
    teamActivityWidgetSaga,
    timeOffWidget
} from 'erpcore/screens/Dashboard/DashboardWidgets.saga';
import {
    accountingCategoriesSaga,
    payrollReportsSaga,
    accountingInvoicesSaga,
    accountingExpensesSaga,
    accountingRevenuesSaga
} from 'erpcore/screens/Accounting/Accounting.saga';
import { approvalsSaga } from 'erpcore/screens/Approvals/Approvals.saga';
import { companiesSaga } from 'erpcore/screens/Companies/Companies.saga';
import { companyContactsSaga } from 'erpcore/screens/Companies/screens/CompanyContacts/CompanyContacts.saga';
import { contactsSaga } from 'erpcore/screens/Contacts/Contacts.saga';
import {
    departmentsSaga,
    divisionsSaga,
    jobTitlesSaga,
    officesSaga,
    optionsSaga,
    organizationSaga,
    paymentMethodsSaga,
    paymentTermsSaga,
    permissionsSaga,
    rolesSaga,
    scheduledCommands,
    servicesSaga,
    workTypesSaga,
    tagsSaga,
    propsSettingsSaga,
    timeOffTypesSaga,
    bundleTemplatesSaga
} from 'erpcore/screens/Settings/Settings.saga';
import { employmentRecordsSaga } from 'erpcore/screens/Users/screens/UserEmploymentRecords/UserEmploymentRecords.saga';
import harvestIntegrationSaga from 'erpcore/screens/Integrations/Harvest/Harvest.saga';
import toggltIntegrationSaga from 'erpcore/screens/Integrations/Toggl/Toggl.saga';
import projectsSaga from 'erpcore/screens/Projects/Projects.saga';
import {
    employeeRecordsReportSaga,
    timeLogsReportSaga,
    userReportsSaga,
    profitabilityReportSaga,
    efficiencyReportSaga
} from 'erpcore/screens/Reports/Reports.saga';
import projectContractsSaga from 'erpcore/screens/Projects/screens/ProjectContracts/ProjectContracts.saga';
import projectContractDashboardSaga from 'erpcore/screens/Projects/screens/ProjectContracts/screens/ProjectContractDashboard/ProjectContractDashboard.saga';
import projectDashboardSaga from 'erpcore/screens/Projects/screens/ProjectDashboard/ProjectDashboard.saga';
import projectMilestonesSaga from 'erpcore/screens/Projects/components/ProjectMilestones/ProjectMilestones.saga';
import projectsFeaturesSaga from 'erpcore/screens/Projects/components/ProjectFeatures/ProjectFeatures.saga';
import projectApprovalsSaga from 'erpcore/screens/Projects/screens/ProjectApprovals/ProjectApprovals.saga';
import propsWidgetSaga from 'erpcore/components/PropsWidget/PropsWidget.saga';
import propsSaga from 'erpcore/screens/Props/Props.saga';
import schedulingAllocationsSaga from 'erpcore/screens/Scheduling/Scheduling.saga';
import salesOrdersSaga from 'erpcore/screens/SalesOrders/SalesOrders.saga';
import timeLogsSaga from 'erpcore/screens/TimeLogs/Timelogs.saga';
import timeOffSaga from 'erpcore/screens/TimeOff/TimeOff.saga';
import { timeTrackingSaga } from 'erpcore/components/TimeTracking/TimeTracking.saga';
import { usersSaga } from 'erpcore/screens/Users/Users.saga';
import calendarSaga from 'erpcore/components/Calendar/Calendar.saga';
import selectSaga from 'erpcore/components/Form/components/SelectNew/Select.saga';

export default function* rootSaga() {
    yield all([...authManagerSaga]);
    yield all([...brandingManagerSaga]);
    yield all([...forgotPasswordSaga]);
    yield all([...imageManagerSaga]);
    yield all([...myAccountSaga]);
    yield all([...activityLogSaga]);
    yield all([...listingSaga]);
    yield all([...commentsSaga]);
    yield all([...dashboardsSaga]);
    yield all([...dashboardsListingSaga]);
    yield all([...accountingCategoriesSaga]);
    yield all([...approvalsSaga]);
    yield all([...accountingInvoicesSaga]);
    yield all([...accountingExpensesSaga]);
    yield all([...accountingRevenuesSaga]);
    yield all([...companiesSaga]);
    yield all([...companyContactsSaga]);
    yield all([...contactsSaga]);
    yield all([...departmentsSaga]);
    yield all([...divisionsSaga]);
    yield all([...employeeRecordsReportSaga]);
    yield all([...employmentRecordsSaga]);
    yield all([...harvestIntegrationSaga]);
    yield all([...toggltIntegrationSaga]);
    yield all([...jobTitlesSaga]);
    yield all([...officesSaga]);
    yield all([...optionsSaga]);
    yield all([...organizationSaga]);
    yield all([...paymentMethodsSaga]);
    yield all([...paymentTermsSaga]);
    yield all([...payrollReportsSaga]);
    yield all([...permissionsSaga]);
    yield all([...projectsSaga]);
    yield all([...projectContractsSaga]);
    yield all([...projectContractDashboardSaga]);
    yield all([...projectDashboardSaga]);
    yield all([...projectMilestonesSaga]);
    yield all([...projectsFeaturesSaga]);
    yield all([...projectApprovalsSaga]);
    yield all([...propsWidgetSaga]);
    yield all([...propsSettingsSaga]);
    yield all([...timeOffTypesSaga]);
    yield all([...propsSaga]);
    yield all([...rolesSaga]);
    yield all([...schedulingAllocationsSaga]);
    yield all([...salesOrdersSaga]);
    yield all([...scheduledCommands]);
    yield all([...servicesSaga]);
    yield all([...timeLogsSaga]);
    yield all([...timeLogsReportSaga]);
    yield all([...timeTrackingSaga]);
    yield all([...timeOffSaga]);
    yield all([...usersSaga]);
    yield all([...userReportsSaga]);
    yield all([...profitabilityReportSaga]);
    yield all([...efficiencyReportSaga]);
    yield all([...tagsSaga]);
    yield all([...workTypesSaga]);
    yield all([...calendarSaga]);
    // Dashboard widgets
    yield all([...teamActivityWidgetSaga]);
    yield all([...timeOffWidget]);
    yield all([...bundleTemplatesSaga]);
    yield all([...selectSaga]);
}
