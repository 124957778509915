import { takeLatest, put, call } from 'redux-saga/effects';
import dto from 'erpcore/utils/dto';
import restClient from 'erpcore/api/restClient';
import { actions as timeTrackingActions } from 'erpcore/components/TimeTracking/TimeTracking.reducer';
import { actions as timeLogsActions } from 'erpcore/screens/TimeLogs/TimeLogs.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

/**
 * Update single time log entry
 * @param promise
 * @param formData
 * @param iri
 * @param reloadListing
 * @returns {Generator<<"PUT", PutEffectDescriptor<{response: *, type: string}>>|<"CALL", CallEffectDescriptor>|AxiosPromise<any>|<"PUT", PutEffectDescriptor<{type: string}>>, void, ?>}
 */
export function* updateTimeLogEntry({ promise, formData, iri }) {
    try {
        const updateTimeLogEntryAPI = yield restClient.put(
            `${iri}?include=projectContract,workType`,
            formData
        );
        const updateTimeLogEntryAPIDTO = dto(updateTimeLogEntryAPI?.data)?.data;
        yield put({
            type: timeLogsActions.UPDATE_TIME_LOG_ENTRY_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateTimeLogEntryAPI?.data
        });
        yield put({
            type: timeTrackingActions.UPDATE_SUCCESSFUL_TIMELOG,
            response: updateTimeLogEntryAPIDTO
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: timeLogsActions.UPDATE_TIME_LOG_ENTRY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 *  Fetch time log entry
 * @param promise
 * @param iri
 * @returns {Generator<<"PUT", PutEffectDescriptor<{response: *, type: string}>>|<"CALL", CallEffectDescriptor>|AxiosPromise<any>|<"PUT", PutEffectDescriptor<{type: string}>>, void, ?>}
 */
export function* fetchTimeLogEntry({ promise, iri }) {
    try {
        const fetchTimeLogEntryAPI = yield restClient.get(iri);
        yield put({
            type: timeLogsActions.SINGLE_TIME_LOG_ENTRY_FETCHING_SUCCESS
        });
        yield put({
            type: timeLogsActions.STORE_TIME_LOG_ENTRY_DATA,
            iri,
            response: dto(fetchTimeLogEntryAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: timeLogsActions.SINGLE_TIME_LOG_ENTRY_FETCHING_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete time log entry
 * @param promise
 * @param iri
 * @returns {Generator<<"CALL", CallEffectDescriptor>|<"PUT", PutEffectDescriptor<{type: string}>>|<"PUT", PutEffectDescriptor<{response: *, type: string}>>|<"PUT", PutEffectDescriptor<{endpoint: string, name: string, type: string, params: *, entity: string}>>|AxiosPromise<any>|<"SELECT", SelectEffectDescriptor>, void, ?>}
 */
export function* deleteTimeLogEntry({ promise, iri }) {
    try {
        const deleteTimeLogAPI = yield restClient.delete(iri);
        yield put({
            type: timeLogsActions.DELETE_TIME_LOG_ENTRY_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteTimeLogAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: timeLogsActions.DELETE_TIME_LOG_ENTRY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch additional project data for timelogs
 * @param promise
 * @param iri
 * @returns {Generator<<"CALL", CallEffectDescriptor>|<"PUT", PutEffectDescriptor<{response: *, type: string}>>|<"PUT", PutEffectDescriptor<{iri: *, response: (null|Object), type: string}>>|AxiosPromise<any>|<"PUT", PutEffectDescriptor<{type: string}>>, void, ?>}
 */
export function* fetchTimeLogsProjectAdditional({ promise, projectContractIri }) {
    try {
        let getProjectAdditionalAPI = {};
        if (projectContractIri) {
            const projectContractAPI = yield restClient.get(
                `${projectContractIri}?include=project`
            );

            const projectContractData = dto(projectContractAPI?.data) || {};
            const projectIri = projectContractData.data?.project?.iri;

            if (projectIri) {
                getProjectAdditionalAPI = yield restClient.get(
                    `${projectIri}?include=milestones,features`
                );
                yield put({
                    type: timeLogsActions.FETCHING_SUCCESSFUL_TIMELOGS_PROJECT_ADDITIONAL
                });
            }
            yield put({
                type: timeLogsActions.STORE_TIMELOGS_PROJECT_DATA,
                iri: projectIri,
                response: dto(getProjectAdditionalAPI?.data)
            });
        }
        yield call(promise.resolve, getProjectAdditionalAPI?.data);
    } catch (error) {
        yield put({
            type: timeLogsActions.FETCHING_FAILED_TIMELOGS_PROJECT_ADDITIONAL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const timeLogsSaga = [
    takeLatest(
        timeLogsActions.START_FETCHING_TIMELOGS_PROJECT_ADDITIONAL,
        fetchTimeLogsProjectAdditional
    ),
    takeLatest(timeLogsActions.START_UPDATE_TIME_LOG_ENTRY, updateTimeLogEntry),
    takeLatest(timeLogsActions.START_DELETE_TIME_LOG_ENTRY, deleteTimeLogEntry),
    takeLatest(timeLogsActions.START_FETCHING_SINGLE_TIME_LOG_ENTRY, fetchTimeLogEntry)
];

export default timeLogsSaga;
