import { takeEvery, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions } from 'erpcore/screens/Reports/EfficiencyReports/EfficiencyReports.reducer';

/**
 * @param  {Object} promise
 * @return {string}
 */
export function* fetchUtilizationOverTimeData({ promise, params }) {
    try {
        const endpoint = '/api/generic-reports/time-logs';

        const apiResponse = yield restClient.get(endpoint, {
            params,
            headers: { Accept: 'application/json' }
        });
        yield put({
            type: actions.FETCHING_SUCCESSFUL_UTILIZATION_OVER_TIME_DATA
        });

        yield put({
            type: actions.STORE_UTILIZATION_OVER_TIME_DATA,
            data: dto(apiResponse?.data, {}, true)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: actions.FETCHING_FAILED_UTILIZATION_OVER_TIME_DATA
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * @param  {Object} promise
 * @return {string}
 */
export function* fetchLabourCostAndValueData({ promise, params }) {
    try {
        const endpoint = '/api/generic-reports/time-logs';

        const apiResponse = yield restClient.get(endpoint, {
            params,
            headers: { Accept: 'application/json' }
        });
        yield put({
            type: actions.FETCHING_SUCCESSFUL_LABOUR_COST_AND_VALUE_DATA
        });

        yield put({
            type: actions.STORE_LABOUR_COST_AND_VALUE_DATA,
            data: dto(apiResponse?.data, {}, true)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: actions.FETCHING_FAILED_LABOUR_COST_AND_VALUE_DATA
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * @param  {Object} promise
 * @return {string}
 */
export function* fetchStaffCostAndValueData({ promise, params }) {
    try {
        const endpoint = '/api/generic-reports/time-logs';

        const apiResponse = yield restClient.get(endpoint, {
            params,
            headers: { Accept: 'application/json' }
        });
        yield put({
            type: actions.FETCHING_SUCCESSFUL_STAFF_COST_AND_VALUE_DATA
        });

        yield put({
            type: actions.STORE_STAFF_COST_AND_VALUE_DATA,
            data: dto(apiResponse?.data, {}, true)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: actions.FETCHING_FAILED_STAFF_COST_AND_VALUE_DATA
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * @param  {Object} promise
 * @return {string}
 */
export function* fetchWorkTypeData({ promise, params }) {
    try {
        const endpoint = '/api/generic-reports/time-logs';

        const apiResponse = yield restClient.get(endpoint, {
            params,
            headers: { Accept: 'application/json' }
        });
        yield put({
            type: actions.FETCHING_SUCCESSFUL_WORK_TYPE_DATA
        });

        yield put({
            type: actions.STORE_WORK_TYPE_DATA,
            data: dto(apiResponse?.data, {}, true)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: actions.FETCHING_FAILED_WORK_TYPE_DATA
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * @param  {Object} promise
 * @return {string}
 */
export function* fetchClientData({ promise, params }) {
    try {
        const endpoint = '/api/generic-reports/time-logs';

        const apiResponse = yield restClient.get(endpoint, {
            params,
            headers: { Accept: 'application/json' }
        });
        yield put({
            type: actions.FETCHING_SUCCESSFUL_CLIENT_DATA
        });

        yield put({
            type: actions.STORE_CLIENT_DATA,
            data: dto(apiResponse?.data, {}, true)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: actions.FETCHING_FAILED_CLIENT_DATA
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeEvery(actions.START_FETCHING_UTILIZATION_OVER_TIME_DATA, fetchUtilizationOverTimeData),
    takeEvery(actions.START_FETCHING_LABOUR_COST_AND_VALUE_DATA, fetchLabourCostAndValueData),
    takeEvery(actions.START_FETCHING_STAFF_COST_AND_VALUE_DATA, fetchStaffCostAndValueData),
    takeEvery(actions.START_FETCHING_WORK_TYPE_DATA, fetchWorkTypeData),
    takeEvery(actions.START_FETCHING_CLIENT_DATA, fetchClientData)
];
