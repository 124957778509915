const hasPermission = (permission, meData) => {
    const { userPermissions } = { ...meData };

    const permissionElement =
        userPermissions?.length && userPermissions.find(element => element === permission);

    if (permissionElement) {
        return true;
    }

    return false;
};

const hasRole = (role, meData) => {
    const { userRoles } = { ...meData };

    const roleElement = userRoles?.length && userRoles.find(element => element === role);

    if (roleElement) {
        return true;
    }

    return false;
};

const hasUserType = (role, meData) => {
    const { userUserTypes } = { ...meData };

    const userTypeElement =
        userUserTypes?.length && userUserTypes.find(element => element === role);

    if (userTypeElement) {
        return true;
    }

    return false;
};

const getPermissionsAccessForEntities = meData => {
    const entitiesPermissions = {
        accounting: false,
        companies: false,
        contacts: false,
        dashboard: false,
        hr: false,
        integrations: false,
        projects: false,
        reports: false,
        salesOrders: false,
        settings: false,
        timeLogs: false,
        timeOff: false,
        timeOffBalance: false,
        timeOffHR: false,
        timeOffRead: false,
        users: false,
        props: false
    };
    // Accounting
    if (
        hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_CATEGORY', meData) ||
        hasPermission('CAN_MANAGE_PAYROLL_REPORT_PAYROLLREPORT', meData) ||
        hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_REVENUE', meData) ||
        hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_EXPENSE', meData) ||
        hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_INVOICE', meData)
    ) {
        entitiesPermissions.accounting = true;
    }

    // Companies
    if (hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData)) {
        entitiesPermissions.companies = true;
    }

    // Contacts
    if (hasPermission('CAN_MANAGE_CONTACT_BUNDLE_CONTACT', meData)) {
        entitiesPermissions.contacts = true;
    }

    // Dashboard
    entitiesPermissions.dashboard = true;

    // HR - Human Resources
    if (hasPermission('CAN_MANAGE_COMMON_TIMEOFF', meData)) {
        entitiesPermissions.hr = true;
    }

    // Integrations
    if (hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData)) {
        entitiesPermissions.integrations = true;
    }

    // Projects
    if (hasPermission('CAN_MANAGE_COMMON_PROJECT', meData)) {
        entitiesPermissions.projects = true;
    }

    // Props
    if (
        hasPermission('CAN_MANAGE_COMMON_PROP', meData) ||
        hasPermission('CAN_MANAGE_COMMON_PROP_TYPE', meData)
    ) {
        entitiesPermissions.props = true;
    }

    // Reports
    if (
        hasPermission('CAN_MANAGE_COMMON_EMPLOYMENTRECORD', meData) ||
        hasPermission('CAN_MANAGE_COMMON_TIMELOG', meData) ||
        hasPermission('CAN_UPDATE_COMMON_TIMELOG', meData)
    ) {
        entitiesPermissions.reports = true;
    }

    // Sales Orders
    if (
        hasPermission('CAN_MANAGE_SALES_ORDERS_SALESORDER', meData) ||
        hasPermission('CAN_UPDATE_SALES_ORDERS_SALESORDER', meData) ||
        hasPermission('CAN_READ_SALES_ORDERS_SALESORDER', meData)
    ) {
        entitiesPermissions.salesOrders = true;
    }

    // Settings
    if (
        hasPermission('CAN_MANAGE_COMMON_ORGANIZATION', meData) ||
        hasPermission('CAN_MANAGE_COMMON_DIVISION', meData) ||
        hasPermission('CAN_MANAGE_COMMON_DEPARTMENT', meData) ||
        hasPermission('CAN_MANAGE_COMMON_OFFICE', meData) ||
        hasPermission('CAN_MANAGE_COMMON_JOBTITLE', meData) ||
        hasPermission('CAN_MANAGE_COMMON_WORKTYPE', meData) ||
        hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) ||
        hasPermission('CAN_MANAGE_SECURITY_BUNDLE_PERMISSION', meData) ||
        hasPermission('CAN_MANAGE_COMMON_PAYMENTMETHOD', meData) ||
        hasPermission('CAN_MANAGE_COMMON_PAYMENTTERM', meData) ||
        hasPermission('CAN_MANAGE_COMMON_ORGANIZATION_SERVICE', meData) ||
        hasPermission('CAN_MANAGE_COMMON_PROP_TYPE', meData)
    ) {
        entitiesPermissions.settings = true;
    }

    // Time logs
    if (
        hasPermission('CAN_MANAGE_COMMON_TIMELOG', meData) ||
        hasPermission('CAN_UPDATE_COMMON_TIMELOG', meData)
    ) {
        entitiesPermissions.timeLogs = true;
    }

    // Time Off
    if (
        !hasPermission('CAN_MANAGE_COMMON_TIMEOFF', meData) &&
        hasPermission('CAN_UPDATE_COMMON_TIMEOFF', meData)
    ) {
        entitiesPermissions.timeOff = true;
    }
    if (hasPermission('CAN_MANAGE_COMMON_TIMEOFF', meData)) {
        entitiesPermissions.timeOffHR = true;
    }
    if (hasPermission('CAN_CUSTOM_READ_TIME_OFF', meData)) {
        entitiesPermissions.timeOffRead = true;
    }
    if (hasPermission('CAN_MANAGE_COMMON_TIMEOFF_BALANCE', meData)) {
        entitiesPermissions.timeOffBalance = true;
    }

    // Users
    if (
        hasPermission('CAN_MANAGE_COMMON_USER', meData) ||
        hasPermission('CAN_MANAGE_COMMON_EMPLOYMENTRECORD', meData)
    ) {
        entitiesPermissions.users = true;
    }

    return entitiesPermissions;
};

export { hasPermission, hasRole, hasUserType, getPermissionsAccessForEntities };
